import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'

import { app, glo } from './globo'
import { objectViewed, timePast, dir2oriExtend, mylink, noris2soris, oridecorate, dow, bgInfo } from './libra'

const ObjType = 't'
const mskMain = 0b00000001
const mskLand = 0b00000010
const mskAvail = 0b00000100
const mskVots = 0b00001000
const mskVotU = 0b00010000
const mskWshs = 0b00100000
const mskWshU = 0b01000000

// takeoff.id,
// date_part('epoch'::text, takeoff.ts)::integer AS ts,
// takeoff.name AS n,
// pgc_lat(takeoff.poz) AS a,
// pgc_lgt(takeoff.poz) AS g,
// pgc_alt(takeoff.poz) AS t,
// takeoff.kind AS k,
// takeoff.okor AS oo,
// takeoff.avor AS ao,
// takeoff.noor AS no,
// takeoff.main AS m,
// takeoff.landing AS l,
// takeoff.avail AS av,
// takeoff.site AS si,
// takeoff.comm AS c,
// takeoff.fed AS f,
// takeoff.fid AS nf,
// takeoff.pge AS p,

// local
const tofImgCache = {}
let tofPts = []
let localFactor = -1

const tofKinds = {}
tofKinds[0] = { m: 1, l: 'Confidentiel', i: '/img/polygon.png' }
tofKinds[1] = { m: 2, l: 'Randonnée', i: '/img/sk_rando_64.png' }
tofKinds[2] = { m: 4, l: 'Soaring', i: '/img/sk_soar_64.png' }
tofKinds[3] = { m: 8, l: 'Thermique', i: '/img/sk_therm_64.png' }
tofKinds[4] = { m: 16, l: 'Cross', i: '/img/sk_cross_64.png' }
tofKinds[5] = { m: 32, l: 'Plaine', i: '/img/polygon.png' }
tofKinds[6] = { m: 64, l: 'Delta', i: '/img/sk_delta_64.png' }
tofKinds[7] = { m: 128, l: 'Acrobatie', i: '/img/polygon.png' }

function getTKindImgs (mask) {
  const matches = []
  Object.keys(tofKinds).forEach((kind) => {
    if (mask & kind.m) { matches.push({ l: kind.l, i: kind.i }) }
  })
  return (matches)
}

function _tofOneLiner (oid) {
  let tof
  if (typeof oid === 'number') {
    tof = app.getObj(ObjType, oid)
    if (!tof) { getTof(oid); return '' }
  } else { tof = oid }
  return 'D&eacute;co ' + tof.n + ' (#' + tof.id + ')'
}

function _tofDisplay (oid) {
  let tof
  if (typeof oid === 'number') {
    tof = app.getObj(ObjType, oid)
    if (!tof) { getTof(oid); return '' }
  } else { tof = oid }
  app.selectObj(ObjType, tof.id)
  let txt = '<fieldset><legend><span id="b_back"></span> &nbsp; <span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;D&eacute;collage '
  if (!tof.avail()) { txt += ' <span title="interdit">' + glo.symb.interdit.c + ' </span> ' }
  txt += '<span style="font-weight:bold">' + tof.n + '</span> (#' + tof.id + ')</span></legend><table>'
  let sns; let snf; const sit = app.getObj('s', tof.si)
  if (sit) {
    sns = sit.n
    snf = sit.fn
  } else {
    sns = snf = '#' + tof.si
  }
  txt += '<tr><td>du site</td><td><u><span title="' + snf + '" id="go_s_' + tof.si + '">' + sns + '</span></u></td></tr>'
  txt += '<tr><td>Orientations</td><td>' + noris2soris(tof.oo) + '</td></tr>' +
        '<tr><td>possibles</td><td><i>' + noris2soris(tof.ao) + '</i></td></tr>' +
        '<tr><td><b>Interdites</b></td><td>' + noris2soris(tof.no) + '</td></tr>'
  if (tof.m === true) {
    txt += '<tr><td colspan="2">D&eacute;collage principal</td></tr>'
  }
  if (tof.l === true) {
    txt += '<tr><td colspan="2">Repose possible</td></tr>'
  }
  if (tof.fl && tof.fl !== 0 && tof._i && tof._i.length > 0) {
    let order = 0
    txt += '<tr><td colspan="2">'
    tof._i.sort(function (a, b) { return a < b })
    for (const n of tof._i) {
      order++
      const inf = app.getObj('i', n)
      const guy = app.getObj('g', inf.gi)
      let na = '...'; let titna = na
      if (!guy) { app.getLater('g', inf.gi) } else {
        na = guy.sn
        titna = guy.fn + ' ' + guy.ln
        if (!na || na === '') { na = titna }
      }
      const now = new Date()
      const sentd = new Date(inf.ts * 1000)
      const difmil = now.getTime() - sentd.getTime()
      const sentod = dow(sentd.getDay()) + ' ' + sentd.getDate() + '/' + (sentd.getMonth() + 1) + ' ' + sentd.getHours() + ':' + sentd.getMinutes()
      txt += '<fieldset style="color:black;background-color:#' + bgInfo(inf.fl) + ';"><legend><span id="oc_t_i' + order + '_left" style="font-size:large">'
      txt += (order > 1) ? glo.symb.deplig.c : glo.symb.plig.c
      txt += '</span>&nbsp;&nbsp;<span style="font-weight:bold">' + ((inf.fl < 1 || inf.fl > 3) ? 'NON' : '') + ' Volable</span></legend>'
      txt += '<div id="d_t_i' + order + '" style="display:' + ((order > 1) ? 'none' : 'block') + ';visibility:' + ((order > 1) ? 'hidden' : 'visible') + '">'
      txt += '<table><tr><td>Pilote</td><td><span id="go_g_' + guy.id + '" title="' + titna + '"><u>' + na + '</u></span></td></tr>'
      txt += '<tr><td>Orient.(Dir) '
      if (inf.lo && inf.lo >= 0) { txt += 'Min/' }
      txt += 'Moy/Raf</td><td><span style="font-weight:bold">' + dir2oriExtend(inf.di) + '</span> (' + inf.di + '&deg;) '
      if (inf.lo && inf.lo >= 0) { txt += inf.lo + ' /' }
      txt += ' <span style="font-weight:bold">' + inf.sp + '</span> / '
      if (inf.gu && inf.gu !== null && inf.gu !== -1) {
        txt += inf.gu
      } else { txt += '-' }
      txt += ' km/h</td></tr>'
      if (inf.ne && inf.ne !== null && inf.ne >= 0 && inf.ne <= 8) {
        txt += '<tr><td>N&eacute;bulosit&eacute;</td><td>' + inf.ne + ' Octa</td></tr>'
      }
      if (inf.te && inf.te !== null) {
        txt += '<tr><td>Temp&eacute;rature</td><td>' + inf.te + ' &deg;C</td></tr>'
      }
      if (inf.pr && inf.pr !== null && inf.pr > 900) {
        txt += '<tr><td>Pression</td><td>' + inf.pr + ' hPa</td></tr>'
      }
      if (inf.v && inf.v > 0) {
        txt += '<tr><td>Validit&eacute;</td><td>' + inf.v + ' h</td></tr>'
      }
      txt += '<tr><td>Quand</td><td>' + sentod + ' (<span style="font-weight:bold">' + timePast(difmil) + '</span>)</td></tr>'

      if (inf.m !== null && inf.m !== 'null' && inf.m.length > 0) { txt += '<tr><td colspan="2">' + inf.m + '</td></tr>' }
      txt += '</table></div></fieldset>'
    }
    txt += '</td></tr>'
  }
  if (tof._v && tof._v.length > 0) { // c8f2c6
    txt += '<tr><td colspan="2"><fieldset style="color:black;background-color:#FFF;"><legend align="right">'
    txt += '<span style="background-color:#CDFACA;font-weight:bold;font-size:130%;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' + tof._v.length + ' Vote' + ((tof._v.length > 1) ? 's' : '') + ' &nbsp; &nbsp; </span>'
    txt += '<span id="oc_t_v" style="background-color:#CDFACA;font-weight:bold;font-size:large"> ' + glo.symb.plid.c + ' </span>'
    txt += '</legend><div id="d_t_v" style="display:block;visibility:visible"><table>'
    for (const n of tof._v) {
      const vote = app.getObj('v', n)
      if (vote) {
        const guy = app.getObj('g', vote.gi)
        let na = '...'; let titna = na
        if (!guy) { app.getLater('g', vote.gi) } else {
          na = guy.sn
          titna = guy.fn + ' ' + guy.ln
          if (!na || na === '') { na = titna }
        }
        const bc = vote.seen() ? '' : ' style="background-color:#CDFACA"'
        const vd = new Date(vote.d * 1000)
        const vod = dow(vd.getDay()) + ' ' + vd.getDate() + '/' + (vd.getMonth() + 1)
        const ns = parseInt(vote.s)
        txt += '<tr' + bc + '><td>'
        if (glo.me.id && (glo.me.id === vote.gi || glo.me.id === 1)) {
          txt += '<span id="ed_v_' + vote.id + '" style="font-size:large" title="modifier"> ' + glo.symb.mod.c + ' </span> '
        }
        txt += '<span id="us_v_' + vote.id + '" style="font-size:large" title="non-vu"> ' + glo.symb.nonvu.c + ' </span>'
        for (let n = ns; n > 0; n--) { txt += '&nbsp;' + glo.symb.star.c }
        txt += ' par <span id="go_g_' + guy.id + '" title="' + titna + '"><u>' + na + '</u></span></td><td> pour le ' + vod + '</td></tr>'
        if (vote.c !== null && typeof vote.c !== 'undefined' && vote.c.length > 0) { txt += '<tr' + bc + '><td colspan="2">' + mylink(vote.c).replace(/\n|\r/g, '<br/>') + '</td></tr>' }
        objectViewed('v', n, true)
      }
    }
    txt += '</table></fieldset></td></tr>'
  }
  if (tof._w && tof._w.length > 0) { // bff
    txt += '<tr><td colspan="2"><fieldset style="color:black;background-color:#FFF;"><legend align="right">'
    txt += '<span style="background-color:#7AFFFF;font-weight:bold;font-size:130%;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' + tof._w.length + ' Sortie' + ((tof._w.length > 1) ? 's' : '') + ' &nbsp; &nbsp; </span>'
    txt += '<span id="oc_t_w" style="background-color:#7AFFFF;font-weight:bold;font-size:large"> ' + glo.symb.plid.c + ' </span>'
    txt += '</legend><div id="d_t_w" style="display:block;visibility:visible"><table>'
    for (const n of tof._w) {
      const wish = app.getObj('w', n)
      if (wish) {
        const guy = app.getObj('g', wish.gi)
        let na = '...'; let titna = na
        if (!guy) { app.getLater('g', wish.gi) } else {
          na = guy.sn
          titna = guy.fn + ' ' + guy.ln
          if (!na || na === '') { na = titna }
        }
        const bc = wish.seen() ? '' : ' style="background-color:#7AFFFF"'
        const wd = new Date(wish.d * 1000)
        const wod = dow(wd.getDay()) + ' ' + wd.getDate() + '/' + (wd.getMonth() + 1)
        txt += '<tr' + bc + '><td>'
        if (glo.me.id && (glo.me.id === wish.gi || glo.me.id === 1)) {
          txt += '<span id="ed_w_' + wish.id + '" style="font-size:large" title="modifier"> ' + glo.symb.mod.c + ' </span> '
        }
        txt += '<span id="us_w_' + wish.id + '" style="font-size:large" title="non-vu"> ' + glo.symb.nonvu.c + ' </span>'
        txt += 'de <span id="go_g_' + guy.id + '" title="' + titna + '"><u>' + na + '</u></span></td><td>pour le <span title="#' + wish.id + '">' + wod + '</td></tr>'
        if (wish.c !== null && typeof wish.c !== 'undefined' && wish.c.length > 0) { txt += '<tr' + bc + '><td colspan="2">' + mylink(wish.c).replace(/\n|\r/g, '<br/>') + '</td></tr>' }
        objectViewed('w', n, true)
      }
    }
    txt += '</table></fieldset></td></tr>'
  }
  if (tof.c !== null && typeof tof.c !== 'undefined' && tof.c.length > 0) {
    txt += '<tr><td colspan="2"><fieldset>' + mylink(tof.c).replace(/\n|\r/g, '<br/>') + '</fieldset></td></tr>'
  }
  const ks = getTKindImgs(tof.k)
  if (ks && ks.length > 0) {
    txt += '<tr><td>Types de vol</td><td>'
    for (const k of ks) {
      txt += '<img title="' + k.l + '" src="' + k.i + '" width="25" /> &nbsp; '
    }
    txt += '</td></tr>'
  }
  txt += '<tr><td><span id="mc_' + ObjType + '_' + tof.id + '" style="font-size:large"> ' + glo.symb.navi.c + ' </span> Position</td><td>' +
    tof.a.toFixed(5) + ' ' + tof.g.toFixed(5) + '&nbsp; Alt:' + tof.t.toFixed(0) + ' m'
  const curpos = app.getv('_pos')
  if (curpos) {
    txt += ' <a target="_blank" href="https://www.google.com/maps/preview/dir/' + curpos[1].toFixed(6) + ',' + curpos[0].toFixed(6) + '/' + tof.a + ',' + tof.g + '/">&lt;=Nav</a>'
  }
  txt += '</td></tr>'
  if (tof.nf) {
    txt += '<tr><td>Voir le d&eacute;co sur</td><td><a href="https://intranet.ffvl.fr/sites_pratique/voir/' + tof.nf + '" target="_blank">F.F.V.L.' + (tof.nf ? ' (' + tof.nf + ')' : '') + '</a></td></tr>'
  } else
    if (tof.f) {
      txt += '<tr><td>Voir le d&eacute;co sur</td><td><a href="http://federation.ffvl.fr/structure/1/sites/' + tof.f + '" target="_blank">F.F.V.L.' + (tof.f ? ' (' + tof.f + ')' : '') + '</a></td></tr>'
    }
  if (tof.p) {
    txt += '<tr><td>Voir le d&eacute;co sur</td><td><a href="http://www.paraglidingearth.com/pgearth/?site=' + tof.p + '" target="_blank">ParaGlidingEarth</a></td></tr>'
  }

  // if( tof.av /*&& glo.me.id*/ ) {
  txt += '<tr><td colspan="2" align="center"><hr/>'
  if (glo.me.id === 1) {
    if (tof.av) {
      txt += '<span id="oa_t_n_' + tof.id + '" style="font-size:140%;color:#FFFFFF;background:#FF0000">&gt;N</span> &nbsp; '
    } else {
      txt += '<span id="oa_t_y_' + tof.id + '" style="font-size:140%;color:#FF0000;background:#FFFFFF">&gt;Y</span> &nbsp; '
    }
  }
  txt += '<span id="cn_i_' + tof.id + '" style="font-size:140%;background:#00FF00">' + glo.symb.add.c + ' Info de vol</span> &nbsp; '
  txt += '<span id="cn_w_' + tof.id + '" style="font-size:140%;background:#7AFFFF">' + glo.symb.add.c + ' Sortie</span> &nbsp; '
  txt += '<span id="cn_v_' + tof.id + '" style="font-size:140%;background:#CDFACA">' + glo.symb.add.c + ' Vote</span>'
  txt += '</td></tr>'
  // }
  txt += '</table></fieldset>'
  return (txt)
}

function tofLst (list) {
  if (list) list.forEach((ojs) => tofUpd(ojs))
}

function getTof (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { tofUpd(co); return }
  try {
    app.send('go', { ot: ObjType, id: oid },
      function (err, r) {
        if (err) { return (null) } else { tofIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetTof err: ' + err) }
}

function tofIns (ojs) {
  const obj = new Takeoff(ojs)
  const id = obj.id
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  obj.draw()
  if (!app.getObj('s', obj.si)) { app.getLater('s', obj.si) }
}

function tofUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Takeoff(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  const sit = app.getObj('s', obj.si)
  if (!sit) { app.getLater('s', obj.si) } else {
    sit.refresh()
  }
}

function tofDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function _tofFlDecorate (img, cote, fl, pct) {
  const cnv = document.createElement('canvas')
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.canvas.width = cote
  ctx.canvas.height = cote
  ctx.drawImage(img, 0, 0)
  // const w = cote / 3.4
  // const cen = cote / 2.0
  let colo
  switch (fl) {
    case 1: colo = 'rgba(0,0,255,' + pct + ')'; break
    case 2: colo = 'rgba(0,255,0,' + pct + ')'; break
    case 3: colo = 'rgba(255,170,0,' + pct + ')'; break
    case 4: colo = 'rgba(255,0,0,' + pct + ')'; break
    default:colo = 'rgba(0,0,0,0.)'; break
  }
  ctx.beginPath()
  ctx.moveTo(cote * 0.5, cote * 0.1)
  ctx.lineTo(cote * 0.9, cote * 0.9)
  ctx.lineTo(cote * 0.5, cote * 0.65)
  ctx.lineTo(cote * 0.1, cote * 0.9)
  ctx.lineTo(cote * 0.5, cote * 0.1)
  ctx.closePath()

  ctx.fillStyle = colo
  ctx.fill()
  const newimg = new Image()
  newimg.width = cote
  newimg.height = cote
  newimg.src = cnv.toDataURL()
  return newimg
}

const tofStyle = function (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const tof = app.getObj(ObjType, f.getId())
  if (!tof || typeof tof === 'undefined') return []
  const key = tof.ik
  let img = tofImgCache[key]
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const cote = Math.round(baseSq * factor)
  if (!img || img === null || typeof img === 'undefined') {
    prepareTofImg(key)
    img = tofImgCache[key]
  }

  if (app.getv('dec') === true) {
    img = oridecorate(img, cote, tof.oo, tof.ao, tof.no)
  }

  if (tof.fl && tof.fl > 0) {
    const itmillis = app.getv('it') * 3600000
    const inf = app.getObj('i', tof._i[0])
    if (inf) {
      const now = new Date()
      const tch = new Date(inf.ts * 1000)
      const agemillis = now.getTime() - tch.getTime()
      img = _tofFlDecorate(img, cote, tof.fl, 1.03 - (agemillis / itmillis))
    }
  }

  const sty = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction', // pixels
      imgSize: [cote, cote],
      img
    })
  })
  return [sty]
}

function prepareTofImg (k) {
  if (!tofImgCache[k] || typeof tofImgCache[k] === 'undefined' || tofImgCache[k] === null) {
    tofImgCache[k] = getTofImg(k)
  }
}

function _getTofKey (tof) {
  let mask = 0
  if (tof.m === true) { mask |= mskMain }
  if (tof.l === true) { mask |= mskLand }
  if (tof._v && tof._v.length > 0 && app.votMatchTime(tof._v) > 0) {
    mask |= mskVots
    if (app.votUnseenNumber(tof._v) > 0) mask |= mskVotU
  }
  if (tof._w && tof._w.length > 0 && app.wshMatchTime(tof._w) > 0) {
    mask |= mskWshs
    if (app.wshUnseenNumber(tof._w) > 0) mask |= mskWshU
  }
  if (tof.av) { mask |= mskAvail }
  return ObjType + mask
}
function setTofPts (bas, fac) {
  const c = Math.round(bas * fac)
  tofPts = [
    [c * 0.2, 0.0],
    [c * 0.25, c * 0.12],
    [c * 0.4, c * 0.12],
    [c * 0.3, c * 0.21],
    [c * 0.35, c * 0.4],
    [c * 0.2, c * 0.3],
    [c * 0.05, c * 0.4],
    [c * 0.1, c * 0.21],
    [0.0, c * 0.12],
    [c * 0.15, c * 0.12]
  ]
  localFactor = fac
}
function getTofImg (key) {
  const k = parseInt(key.substr(1))
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const cote = Math.round(baseSq * factor)
  const epa = cote / 30
  if (tofPts.length < 1 || localFactor !== factor) { setTofPts(baseSq, factor) }
  const bmain = (k & mskMain) === mskMain // main tof
  const bland = (k & mskLand) === mskLand // landing too
  const bvots = (k & mskVots) === mskVots // votes
  const bwshs = (k & mskWshs) === mskWshs // wishes
  const bvotU = (k & mskVotU) === mskVotU // votes unseen
  const bwshU = (k & mskWshU) === mskWshU // wishes unseen
  const bavai = (k & mskAvail) === mskAvail // avail (or red-cross)

  const cnv = document.createElement('canvas')
  cnv.style.width = cote
  cnv.style.height = cote
  cnv.setAttribute('width', cote)
  cnv.setAttribute('height', cote)
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.canvas.width = cote
  ctx.canvas.height = cote

  ctx.beginPath()
  ctx.moveTo(cote * 0.5, cote * 0.1)
  ctx.lineTo(cote * 0.9, cote * 0.9)
  ctx.lineTo(cote * 0.5, cote * 0.65)
  ctx.lineTo(cote * 0.1, cote * 0.9)
  ctx.lineTo(cote * 0.5, cote * 0.1)
  ctx.closePath()
  ctx.fillStyle = 'rgba(255,255,255,0.4)'
  ctx.fill()

  ctx.lineWidth = epa
  ctx.strokeStyle = '#000'
  ctx.stroke()

  if (bmain) {
    ctx.beginPath()
    ctx.moveTo(cote * 0.5, cote * 0.1)
    ctx.lineTo(cote * 0.5, cote * 0.45)
    ctx.lineTo(cote * 0.9, cote * 0.9)
    ctx.lineTo(cote * 0.5, cote * 0.45)
    ctx.lineTo(cote * 0.1, cote * 0.9)
    ctx.closePath()
    ctx.strokeStyle = '#000'
    ctx.lineWidth = 1
    ctx.stroke()
  }

  if (bland) {
    ctx.beginPath()
    ctx.moveTo(cote * 0.75, cote * 0.75)
    ctx.lineTo(cote * 0.85, cote * 0.80)
    ctx.lineTo(cote * 0.95, cote * 0.75)
    ctx.lineTo(cote * 0.85, cote * 0.95)
    ctx.lineTo(cote * 0.75, cote * 0.75)
    ctx.closePath()
    ctx.fillStyle = '#000'
    ctx.fill()
  }

  if (bvots) {
    ctx.beginPath()
    ctx.moveTo(tofPts[0][0], tofPts[0][1])
    for (let i = 1, len = tofPts.length; i < len; i++) {
      ctx.lineTo(tofPts[i][0], tofPts[i][1])
    }
    ctx.closePath()
    ctx.fillStyle = (bvotU) ? '#0f0' : '#fff'
    ctx.fill()

    ctx.strokeStyle = '#000'
    ctx.lineWidth = 1
    ctx.stroke()
  }

  if (bwshs) {
    const w = cote / 3.0
    const x = cote / 6.0
    ctx.beginPath()
    ctx.moveTo(cote - w, w)
    ctx.lineTo(cote - x, 0)
    ctx.lineTo(cote, 0)
    ctx.lineTo(cote, x)
    ctx.lineTo(cote - w, w)
    ctx.closePath()
    ctx.fillStyle = (bwshU) ? '#0ff' : '#fff'
    ctx.fill()

    ctx.strokeStyle = '#000'
    ctx.lineWidth = 1
    ctx.stroke()
  }

  if (!bavai) {
    const t = 8
    const c = cote - t
    ctx.beginPath()
    ctx.moveTo(c, t)
    ctx.lineTo(t, c)
    ctx.moveTo(c, c)
    ctx.lineTo(t, t)
    ctx.closePath()
    ctx.strokeStyle = '#F00'
    ctx.lineWidth = 4
    ctx.setLineDash([5, 5])
    ctx.stroke()
  }

  const img = new Image()
  img.width = cote
  img.height = cote
  img.src = cnv.toDataURL()
  return img
}

class Takeoff extends BaseObject {
  constructor (ojs) {
    super()
    this.ot = ObjType
    for (const key in ojs) { this[key] = ojs[key] }
    if (typeof this.av === 'undefined') this.av = true
    this.ik = _getTofKey(ojs)
    this.draw()
  }

  update (ojs) {
    if (!ojs) {
      const nik = _getTofKey(this)
      if (nik !== this.ik) {
        this.ik = nik
        this.refresh()
      }
    } else {
      let changed = false
      for (const key in ojs) {
        if (this[key] !== ojs[key]) {
          changed = true
          this[key] = ojs[key]
        }
      }
      if (typeof this.av === 'undefined') this.av = true
      { const nik = _getTofKey(this)
        if (nik !== this.ik) {
          this.ik = nik
          changed = true
        }
      }
      if (changed) {
        this.refresh()
      }
    }
  }

  draw () {
    prepareTofImg(this.ik)
    const fea = new Feature({
      geometry: new Point(app.tr2map([this.g, this.a, this.t]), 'XYZ'),
      ot: ObjType
    })
    fea.setId(this.id)
    const sSt = app.getSrcs(ObjType)
    const sStw = app.getSrcs(ObjType + 'w')
    if ((this.fl && this.fl > 0) ||
         (this._w && this._w.length > 0) ||
         (this._v && this._v.length > 0)) {
      sSt.addFeature(fea)
    } else {
      sStw.addFeature(fea)
    }
    fea.changed()
  }

  undraw () {
    const sSt = app.getSrcs(ObjType)
    const sStw = app.getSrcs(ObjType + 'w')
    let fea = sSt.getFeatureById(this.id)
    if (fea) {
      sSt.removeFeature(fea)
    } else {
      fea = sStw.getFeatureById(this.id)
      if (fea) sStw.removeFeature(fea)
    }
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
    const sit = app.getObj('s', this.si)
    if (sit) sit.refresh()
  }

  linked (ot, oid) {
    switch (ot) {
      case 'i': return (this._i && this._i.indexOf(oid) > -1)
      case 'v': return (this._v && this._v.indexOf(oid) > -1)
      case 'w': return (this._w && this._w.indexOf(oid) > -1)
    }
    return false
  }

  link (ot, oid) {
    switch (ot) {
      case 'i': {
        if (this._i && this._i.indexOf(oid) < 0) { this._i.push(oid) }
        const info = app.getObj('i', oid)
        if (info) { this.fl = info.fl }
        this.update()
      } break
      case 'v': if (this._v && this._v.indexOf(oid) < 0) { this._v.push(oid) }
        this.update()
        break
      case 'w': if (this._w && this._w.indexOf(oid) < 0) { this._w.push(oid) }
        this.update()
        break
    }
    const sit = app.getObj('s', this.si)
    if (sit) { sit.link(ot, oid) }
  }

  unlink (ot, oid) {
    let ix
    switch (ot) {
      case 'i': if (this._i && (ix = this._i.indexOf(oid)) > -1) { this._i.splice(ix, 1) }
        this.update()
        break
      case 'v': if (this._v && (ix = this._v.indexOf(oid)) > -1) { this._v.splice(ix, 1) }
        this.update()
        break
      case 'w': if (this._w && (ix = this._w.indexOf(oid)) > -1) { this._w.splice(ix, 1) }
        this.update()
        break
    }
    const sit = app.getObj('s', this.si)
    if (sit) { sit.unlink(ot, oid) }
  }

  remove () {
    const id = this.id
    this.undraw()
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
  }

  avail () { return (typeof this.av !== 'undefined' && this.av === true) }
  main () { return (typeof this.m !== 'undefined' && this.m === true) }
  landing () { return (typeof this.l !== 'undefined' && this.l === true) }
  check () {
    const sit = app.getObj('s', this.si)
    if (sit) {
      if (!sit.linked(ObjType, this.id)) { sit.link(ObjType, this.id) }
    } else {
      app.getLater('s', this.si)
    }
    for (const id of this._i) { if (!app.getObj('i', id)) { app.getLater('i', id) } }
    for (const id of this._v) { if (!app.getObj('v', id)) { app.getLater('v', id) } }
    for (const id of this._w) { if (!app.getObj('w', id)) { app.getLater('w', id) } }
  }

  getDisplay () { return (_tofDisplay(this.id)) }
  getOneLiner () { return (_tofOneLiner(this.id)) }
}

export { Takeoff, tofStyle, getTof, tofLst, tofIns, tofUpd, tofDel, getTKindImgs }
