import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'
import Circle from 'ol/style/Circle'

import { glo, app } from './globo'
import { timePast, mylink } from './libra'
import { geojformat } from './carte'

const ObjType = 'r'

// run.id,
// run.name AS n,
// date_part('epoch'::text, run.ts)::integer AS ts,
// date_part('epoch'::text, run.sta)::integer AS sa,
// date_part('epoch'::text, run.sto)::integer AS so,
// st_asgeojson(st_asewkt(run.pnts)) AS j,
// st_asewkt(run.pnts) AS e,
// run.guy AS gi,
// run.takeoff AS ti,
// run.pub AS p,
// run.comm AS c,
// run.wing AS wn,

// local

function runOneLiner (oid) {
  let run
  if (typeof oid === 'number') {
    run = app.getObj(ObjType, oid)
    if (!run) { getRun(oid); return '' }
  } else { run = oid }
  const d = new Date(run.sa * 1000)
  const ad = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear() + ' ' + d.getHours() + ':' + d.getMinutes()
  const cnt = 'Vol ' + run.n + ' du ' + ad
  return cnt
}

function runDisplay (oid) {
  let run
  if (typeof oid === 'number') {
    run = app.getObj(ObjType, oid)
    if (!run) { getRun(oid); return '' }
  } else { run = app.getObj(ObjType, oid.id) }
  app.selectObj(ObjType, run.id)
  const guy = app.getObj('g', run.gi)
  if (!guy) { app.getLater('g', run.gi) }
  let d = new Date(run.sa * 1000)
  const ad = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear() + ' ' + d.getHours() + ':' + d.getMinutes()
  d = new Date(run.so * 1000)
  const od = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear() + ' ' + d.getHours() + ':' + d.getMinutes()
  d = new Date(run.ts * 1000)
  const md = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear() + ' ' + d.getHours() + ':' + d.getMinutes()
  const elapsed = timePast(Math.round(Date.now() - d))
  let na = guy.sn
  const titna = guy.fn + ' ' + guy.ln
  if (!na || na === '') { na = titna }

  let txt = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Vol: ' + run.n + (run.p ? ' (public)' : '') + ' (#' + run.id + ')</span></legend><table>' +
    '<tr><td>Pilote</td><td><span title="' + titna + '">' + na + '</span></td></tr>' +
    '<tr><td>De</td><td>' + ad + '</td></tr>' +
    '<tr><td>&Agrave;</td><td>' + od + '</td></tr>'

  if (run.ti) {
    const tof = app.getObj('t', run.ti)
    if (tof) {
      txt += '<tr><td>D&eacute;collage</td><td>' + tof.n + '</td></tr>'
      const sit = app.getObj('s', tof.si)
      if (sit) {
        txt += '<tr><td>Site</td><td>' + sit.n + '</td></tr>'
      }
    }
  }

  if (run.wn && run.wn.length > 0) {
    txt += '<tr><td>Aile</td><td>' + run.wn + '</td></tr>'
  }
  if (run.c && run.c.length > 0) {
    txt += '<tr><td colspan="2">' + mylink(run.c).replace(/\n|\r/g, '<br/>') + '</td></tr>'
  }
  txt += '<tr><td>M&agrave;J</td><td>' + md + ' (' + elapsed + ')</td></tr>'

  if (glo.me.id && (run.gi === glo.me.id)) {
    txt += '<tr><td colspan="2" align="center"><span onclick="runMod(' + run.id + ');">' + glo.symb.mod.c + ' Modifier</span></td></tr>'
  }
  txt += '</table></fieldset>'
  return (txt)
}

/*
function runMod (n) {
  const fea = app.getSrcs(ObjType).getFeatureById(n)
  if (fea !== null) {
    mapModFea(fea)
  }
}
*/

// function runDrawAll() { $.each(app.getObjs(ObjType), function(run, obj) { obj.draw(); }); }

function runLst (list) {
  if (list) list.forEach((ojs) => runUpd(ojs))
}

function getRun (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { runUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { runIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetRun err: ' + err) }
}

function runIns (ojs) {
  const obj = new Run(ojs)
  const id = obj.id
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  if (obj.gi && !app.getObj('g', obj.gi)) { app.getLater('g', obj.gi) }
  obj.draw()
}

function runUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Run(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  };
  if (obj.gi && !app.getObj('g', obj.gi)) { app.getLater('g', obj.gi) }
  obj.draw()
}

function runDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

const _defStyles = {
  Point: new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(255,255,0,0.5)'
      }),
      radius: 5,
      stroke: new Stroke({
        color: '#ff0',
        width: 1
      })
    })
  }),
  LineString: new Style({
    stroke: new Stroke({
      color: '#f00',
      width: 2
    })
  }),
  Polygon: new Style({
    fill: new Fill({
      color: 'rgba(0,200,200,0.4)'
    }),
    stroke: new Stroke({
      color: '#000',
      width: 1
    })
  }),
  MultiPoint: new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(255,0,255,0.5)'
      }),
      radius: 5,
      stroke: new Stroke({
        color: '#f0f',
        width: 1
      })
    })
  }),
  MultiLineString: new Style({
    stroke: new Stroke({
      color: '#0f0',
      width: 2
    })
  }),
  MultiPolygon: new Style({
    fill: new Fill({
      color: 'rgba(0,0,255,0.5)'
    }),
    stroke: new Stroke({
      color: '#00f',
      width: 1
    })
  })
}

function runStyle (f, reso) {
  // const run = app.getObj(ObjType, f.getId())
  const featureStyleFunction = f.getStyleFunction()
  if (featureStyleFunction) {
    return featureStyleFunction.call(f, reso)
  } else {
    return [_defStyles[f.getGeometry().getType()]]
  }
}

class Run extends BaseObject {
  constructor (ojs) {
    super()
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
  }

  update (ojs) {
    for (const key in ojs) { this[key] = ojs[key] }
    this.refresh()
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  draw () {
    const geom = geojformat.readGeometry(this.j)
    geom.applyTransform(app.tr2map)
    const fea = new Feature({
      geometry: geom,
      ot: ObjType
    })
    fea.setId(this.id)
    app.getSrcs(ObjType).addFeature(fea)
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) { app.getSrcs(ObjType).removeFeature(fea) }
  }

  check () {
    if (this.ti && !app.getObj('t', this.ti)) app.getLater('t', this.ti)
    if (!app.getObj('g', this.gi)) app.getLater('g', this.gi)
  }

  remove () {
    this.undraw()
    app.getObjs(ObjType)[this.id] = null
    delete app.getObjs(ObjType)[this.id]
  }

  getDisplay () { return (runDisplay(this)) }
  getOneLiner () { return (runOneLiner(this)) }
}

export { Run, runStyle, getRun, runLst, runIns, runUpd, runDel }
