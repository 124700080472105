import BaseObject from 'ol/Object'

import { app, glo } from './globo'
import { fd } from './libra'

const ObjType = 'p'

// p.id,
// p.guy AS gi,
// p.site AS si,
// date_part('epoch'::text, p.touch)::integer AS ts,
// date_part('epoch'::text, p.sta)::integer AS sa,
// date_part('epoch'::text, p.sto)::integer AS so,
// p.txt AS tx,
// pgc_lat(s.poz) AS a,
// pgc_lgt(s.poz) AS g,
// pgc_alt(s.poz) AS t,
// pgc_isseen(,,) AS _s

// local
function _pmgEdit (pmg) {
  const sit = app.getObj('s', pmg.si)
  if (!sit) { app.getLater('s', pmg.si); return '' }
  const dsa = new Date(pmg.sa * 1000)
  const dso = new Date(pmg.so * 1000)
  const sdsa = fd(dsa)
  const sdso = fd(dso)
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Modifier Post\'It (#' + pmg.id + ')</span></legend><table>'
  txt += '<input type="hidden" id="ih_id" value="' + pmg.id + '"/>'
  txt += '<tr><td>Site</td><td><u><span id="go_s_' + sit.id + '">' + sit.n + '</span></u></td></tr>'
  txt += '<tr><td>Du</td><td><input id="iD_sa_' + (pmg.sa * 1000) + '" value="' + sdsa + '" type="date" class="date"></td></tr>'
  txt += '<tr><td>Au</td><td><input id="iD_so_' + (pmg.so * 1000) + '" value="' + sdso + '" type="date" class="date"></td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_t" cols="30" rows="3" value="' + pmg.tx + '" placeholder="Texte...">' + pmg.tx + '</textarea></td></tr>'
  txt += '<tr><td><span id="de_' + ObjType + '_' + pmg.id + '">' + glo.symb.trash.c + ' Supprimer</span></td><td align="right"><span id="b_ok" title="OK">Envoi&nbsp;' + glo.symb.valid.c + '</span></td></tr>'

  //    txt += '<tr><td><span id="de_'+ObjType+'_'+pmg.id+'"><img src="/img/cancel_16.png"/> Supprimer</span></td>';   // &#x2BBF;
  //    txt += '<td align="right"><span id="ed_'+ObjType+'_'+pmg.id+'">Modifier <span style="font-weight:bold;font-size:150%">'+glo.symb.mod.c+'</span></span></td></tr>';

  txt += '</table></fieldset></form>'
  return txt
}

function pmgNew (sitid) {
  const sit = app.getObj('s', sitid)
  if (!sit) { app.getLater('s', sitid); return '' }
  // let dsa = new Date(Date.now() + 86400000);
  const dsa = new Date()
  if (dsa.getHours() > glo.tomlimit) dsa.setDate(dsa.getDate() + 1)
  const sdsa = fd(dsa)
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Nouveau Post\'It</span></legend><table>'
  txt += '<input type="hidden" id="ih_sit" value="' + sitid + '"/>'
  txt += '<tr><td>Site</td><td><u><span id="go_s_' + sit.id + '">' + sit.n + '</span></u></td></tr>'
  txt += '<tr><td>Le</td><td><input id="iD_ao_1" type="date" class="date" value="' + sdsa + '"></td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_t" cols="30" rows="3" value="" placeholder="Texte..."></textarea></td></tr>'
  txt += '<tr><td></td><td align="right"><span id="b_ok" title="OK">Cr&eacute;er&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function pmgLst (list) {
  if (list) list.forEach((ojs) => pmgUpd(ojs))
}

function getPmg (oi) {
  const co = app.getObj(ObjType, oi)
  if (co) { pmgUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { ot: ObjType, id: oi }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { pmgIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetPmg err: ' + err) }
}

function pmgIns (ojs) {
  const obj = new Pmg(ojs)
  const id = obj.id
  const si = obj.si
  // const gi = obj.gi
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  obj.check()
  //    if( gi && ! app.getObj('g',gi) ) { getGuy(gi); }
  const sit = app.getLater('s', si)
  if (sit) {
    sit.link(ObjType, id)
  }
}

function pmgUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Pmg(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  obj.check()
}

function pmgDel (obj) { // id,ot,pid (parent)
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function pmgMatch (ojs, i, f) {
  const now = new Date()
  now.setHours(0)
  now.setMinutes(0)
  now.setSeconds(0)
  const pasLimit = new Date(now.getTime() - (i * 3600000))
  const futLimit = new Date(now.getTime() + (f * 86400000))
  futLimit.setHours(23)
  futLimit.setMinutes(59)
  futLimit.setSeconds(59)
  const start = ojs.sa * 1000
  const stop = ojs.so * 1000
  return (stop > pasLimit.getTime() && start < futLimit.getTime())
}

class Pmg extends BaseObject {
  constructor (ojs) {
    super()
    this.ot = ObjType
    for (const key in ojs) { this[key] = ojs[key] }
    if (typeof this._s === 'undefined') this._s = false
    const sit = app.getObj('s', this.si)
    if (sit) {
      if (!sit.linked(ObjType, this.id)) { sit.link(ObjType, this.id) }
      sit.refresh()
    }
  }

  see (yon) {
    if (typeof this._s === 'undefined' || this._s !== yon) {
      this._s = yon
      this.refresh()
    }
  }

  seen () { return (!!((this._s && this._s === true))) }

  update (ojs) {
    let changed = false
    for (const key in ojs) {
      if (this[key] !== ojs[key]) {
        changed = true
        this[key] = ojs[key]
      }
    }
    if (typeof this._s === 'undefined') this._s = false
    if (changed) {
      const sit = app.getObj('s', this.si)
      if (sit) {
        if (!sit.linked(ObjType, this.id)) { sit.link(ObjType, this.id) }
      }
      this.refresh()
    }
  }

  match (i, f) {
    return pmgMatch(this, i, f)
  }

  refresh () {
    const sit = app.getObj('s', this.si)
    if (sit) sit.refresh()
  }

  check () {
    if (!app.getObj('g', this.gi)) { app.getLater('g', this.gi) }
    const sit = app.getObj('s', this.si)
    if (sit) { sit.link(ObjType, this.id) } else { app.getLater('s', this.si) }
  }

  remove () {
    const id = this.id
    const sit = app.getObj('s', this.si)
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
    if (sit && sit.linked(ObjType, id)) { sit.unlink(ObjType, id) }
  }

  getEdit () { return (_pmgEdit(this)) }
}

export { Pmg, getPmg, pmgLst, pmgIns, pmgUpd, pmgDel, pmgNew, pmgMatch }
