import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'

import { app, glo } from './globo'
import { objectViewed, mylink, fd, fdf } from './libra'

const ObjType = 'a'

// ann.id,
// date_part('epoch'::text, ann.ts)::integer AS ts,
// date_part('epoch'::text, ann.sto)::integer AS so,
// ann.guy AS gi,
// pgc_lat(ann.poz) AS a,
// pgc_lgt(ann.poz) AS g,
// pgc_alt(ann.poz) AS t,
// ann.txt AS tx,
// ann.sell AS s,
// ann.cont AS c,
// pgc_isseen(,,) AS _s

// local
function annEdit (ann) {
  const dts = new Date(ann.ts * 1000)
  const sdts = fd(dts)
  const dso = new Date(ann.so * 1000)
  const sdso = fd(dso)
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl">' + glo.symb.close.c + '&nbsp;Petite annonce n&deg;' + ann.id + '</span></legend><table>'
  txt += '<input type="hidden" id="ih_id" value="' + ann.id + '"/>'
  txt += '<tr><td>' + ((ann.s === true) ? 'de Vente' : 'd&apos;Achat') + '</td><td>d&eacute;pos&eacute;e le ' + sdts + '</td></tr>'
  txt += '<tr><td>expire le</td><td><input id="iD_so_' + (ann.ts * 1000) + '" class="date" type="date" value="' + sdso + '"></td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_txt" cols="30" rows="3" placeholder="Texte de l\'annonce" />' + ann.tx + '</textarea></td></tr>'
  if (app.getv('_ori')) {
    const newPos = app.getv('_ori')
    txt += '<input type="hidden" id="ih_a" value="' + newPos[0] + '"/>'
    txt += '<input type="hidden" id="ih_g" value="' + newPos[1] + '"/>'
    txt += '<tr><td>D&eacute;placer</td><td><input id="ic_o" name="ic_o" type="checkbox" value="ori"/>vers Origine</td></tr>'
  }
  txt += '<tr><td><span id="de_' + ObjType + '_' + ann.id + '">' + glo.symb.trash.c + ' Supprimer</span></td><td align="right"><span id="b_ok" title="OK">Envoi&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function annNew (lat, lgt) {
  const dso = new Date(new Date().getTime() + (31 * 86400000))
  const sdso = fd(dso)
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl">' + glo.symb.close.c + '&nbsp;Nouvelle Annonce</span></legend><table>'
  txt += '<input type="hidden" id="ih_lat" value="' + lat + '"/>'
  txt += '<input type="hidden" id="ih_lgt" value="' + lgt + '"/>'
  txt += '<tr><td><label for="ir_s_v">Vente</label><input id="ir_s_v" type="radio" name="ir_sell" value="v" checked/></td>'
  txt += '<td><label for="ir_s_a">Achat</label><input id="ir_s_a" type="radio" name="ir_sell" value="a"/></td></tr>'
  txt += '<tr><td>Expire le</td><td><input id="iD_so" class="date" type="date" required value="' + sdso + '"></td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_txt" cols="30" rows="3" placeholder="Texte de l\'annonce" /></textarea></td></tr>'
  txt += '<tr><td></td><td align="right"><span id="b_ok" title="OK">Cr&eacute;er&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function annOneLiner (oid) {
  let ann
  if (typeof oid === 'number') {
    ann = app.getObj(ObjType, oid)
    if (!ann) { getAnn(oid); return '' }
  } else { ann = oid }
  let na = '...'
  const guy = app.getObj('g', ann.gi)
  if (guy) {
    na = guy.sn
    if (!na || na === '') { na = guy.fn + ' ' + guy.ln }
  }
  const cnt = 'Annonce ' + ((ann.s === true) ? 'de Vente' : 'd&apos;Achat') + ' de ' + na
  return cnt
}

function annDisplay (oid) {
  let ann
  if (typeof oid === 'number') {
    ann = app.getObj(ObjType, oid)
    if (!ann) { getAnn(oid); return '' }
  } else { ann = app.getObj(ObjType, oid.id) }
  app.selectObj(ObjType, ann.id)
  const dso = new Date(ann.so * 1000)
  const dts = new Date(ann.ts * 1000)
  const sdts = fdf(dts)
  const sdso = fdf(dso)
  const guy = app.getObj('g', ann.gi)
  let na = '...'; let titna = na
  if (!guy) { app.getLater('g', ann.gi) } else {
    na = guy.sn
    titna = guy.fn + ' ' + guy.ln
    if (!na || na === '') { na = titna }
  }
  let txt = '<fieldset><legend><span id="b_cl">' + glo.symb.close.c + '&nbsp;Annonce n&deg;' + ann.id + '</span></legend><table>' +
    '<tr><td>' + ((ann.s === true) ? 'de Vente' : 'd&apos;Achat') + ' pilote</td><td><span title="' + titna + '">' + na + '</span>'
  if (guy) {
    if (guy.e) {
      txt += ' &nbsp; <a style="font-style:larger" href="mailto:' + guy.e + '?subject=From OPS" target="_blank"><img src="/img/sendmail_16.png"/></a>'
    }
    if (guy.p) {
      txt += ' &nbsp; <a style="font-style:larger" href="tel://' + guy.p + '" target="_blank">' + glo.symb.tel.c + '</a>'
      txt += ' &nbsp; <a style="font-style:larger" href="sms://' + guy.p + '" target="_blank">' + glo.symb.sms.c + '</a>'
    }
  }
  txt += '</td></tr><tr><td>d&eacute;pos&eacute;e le</td><td>' + sdts + '</td></tr>' +
    '<tr><td>expire le</td><td>' + sdso + ' &nbsp; <span id="us_a_' + ann.id + '" style="font-size:large" title="non-vue"> ' + glo.symb.nonvu.c + ' </span></td></tr>' +
    '<tr><td><span id="mc_' + ObjType + '_' + ann.id + '" style="font-size:large">' + glo.symb.navi.c + '</span> Position</td><td>' + ann.a.toFixed(5) + ' ' + ann.g.toFixed(5)
  const curpos = app.getv('_pos')
  if (curpos) {
    txt += ' <a target="_blank" href="https://www.google.com/maps/preview/dir/' + curpos[1].toFixed(6) + ',' + curpos[0].toFixed(6) + '/' + ann.a + ',' + ann.g + '/">&lt;=Nav</a>'
  }
  if (ann.tx && ann.tx.length > 0) {
    txt += '<tr><td colspan="2"><fieldset><p>' + mylink(ann.tx).replace(/\n|\r/g, '<br/>') + '</p></fieldset></td></tr>'
  }
  if (glo.me.id) {
    if (glo.me.id === ann.gi || glo.me.id === 1) {
      txt += '<tr><td><span id="de_' + ObjType + '_' + ann.id + '">' + glo.symb.trash.c + ' Supprimer</span></td>' // &#x2BBF;
      txt += '<td align="right"><span id="ed_' + ObjType + '_' + ann.id + '">Modifier <span style="font-weight:bold;font-size:150%">' + glo.symb.mod.c + '</span></span></td></tr>'
    }
  }
  txt += '</table></fieldset>'
  objectViewed(ObjType, ann.id, true)
  ann.update()
  return (txt)
}

function annLst (list) {
  if (list) list.forEach((ojs) => annUpd(ojs))
}

function annIns (ojs) {
  const obj = new Annonce(ojs)
  const id = obj.id
  if (app.getObjs(ObjType)[id]) app.getObjs(ObjType)[id].remove()
  app.getObjs(ObjType)[id] = obj
  obj.check()
}

function annUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Annonce(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  obj.check()
}

function annDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function getAnn (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { annUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { annIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetAnn err: ' + err) }
}

function _getAnnImgUrl (k) {
  /*
  switch (k) {
    case ObjType + '1': return '/img/annseen_48.png'; break
    default: return '/img/annunseen_48.png'; break
  }
  */
  if (k === 'a1') return '/img/annseen_48.png'
  else return '/img/annunseen_48.png'
}

function annStyle (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const ann = app.getObj(ObjType, f.getId())
  if (!ann || typeof ann === 'undefined') return []
  const key = ann.ik
  const url = _getAnnImgUrl(key)
  const sty = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction', // || pixels
      opacity: 1.0,
      rotation: 0.0,
      src: url
    })
  })
  return [sty]
}

function _getAnnKey (ann) {
  if (!ann) { return ObjType + '0' }
  let mask = 0
  if (typeof ann._s !== 'undefined' && ann._s === true) mask |= 1
  return ObjType + mask
}

class Annonce extends BaseObject {
  constructor (ojs) {
    super()
    this.ot = ObjType
    for (const key in ojs) { this[key] = ojs[key] }
    if (typeof this._s === 'undefined') this._s = false
    this.ik = _getAnnKey(ojs)
    this.draw()
  }

  see (yon) {
    if (typeof this._s === 'undefined' || this._s !== yon) {
      this._s = yon
      this.refresh()
    }
  }

  seen () { return (!!((this._s && this._s === true))) }

  update (ojs) {
    let changed = false
    if (!ojs) {
      const nik = _getAnnKey(this)
      if (nik !== this.ik) {
        this.ik = nik
        changed = true
      }
    } else {
      for (const key in ojs) {
        if (typeof this[key] === 'undefined' || this[key] !== ojs[key]) {
          changed = true
          this[key] = ojs[key]
        }
      }
      const nik = _getAnnKey(this)
      if (nik !== this.ik) {
        this.ik = nik
        changed = true
      }
    }
    if (changed) this.refresh()
  }

  draw () {
    const fea = new Feature({
      geometry: new Point(app.tr2map([this.g, this.a])),
      ot: ObjType
    })
    fea.setId(this.id)
    fea.set('ot', ObjType)
    app.getSrcs(ObjType).addFeature(fea)
    fea.changed()
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) app.getSrcs(ObjType).removeFeature(fea)
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  check () {
    if (!app.getObj('g', this.gi)) app.getLater('g', this.gi)
  }

  remove () {
    const id = this.id
    this.undraw()
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
  }

  getEdit () { return (annEdit(this)) }
  getDisplay () { return (annDisplay(this)) }
  getOneLiner () { return (annOneLiner(this)) }
}

export { Annonce, annStyle, annLst, annIns, annUpd, annDel, annNew, getAnn }
