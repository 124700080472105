import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'

import { glo, app } from './globo'
import { timePast, mylink, fdf } from './libra'
import { geojformat } from './carte'

const ObjType = 'x'

// path.id,
// path.name AS n,
// date_part('epoch'::text, path.ts)::integer AS ts,
// st_asgeojson(st_asewkt(path.pnts)) AS j,
// st_asewkt(path.pnts) AS e,
// path.guy AS gi,
// path.pub AS p,
// path.use AS u,
// path.comm AS c,
// path.descs AS de,

// local
const pathUses = {}
pathUses[0] = { l: 'Inconnu', c: '#fff', s: [4, 2], w: 3 }
pathUses[1] = { l: 'Pédestre', c: '#fa0', s: [5, 5], w: 4 }
pathUses[2] = { l: 'Véhicule', c: '#00f', s: [8, 3], w: 3 }
pathUses[3] = { l: 'Conditions', c: '#f00', s: [8, 4], w: 4 }
pathUses[4] = { l: 'Navette', c: '#0f0', s: null, w: 4 }
function getXUseLabel (un) { return pathUses[un].l }
function getXUseColor (un) { return pathUses[un].c }
function getXUseStroke (un) { return pathUses[un].s }
function getXUseWidth (un) { return pathUses[un].w }

function patNew (lat, lgt) {
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Nouveau Chemin</span></legend><table>'
  txt += '<input type="hidden" id="ih_lat" value="' + lat + '"/>'
  txt += '<input type="hidden" id="ih_lgt" value="' + lgt + '"/>'
  txt += '<tr><td>Public</td><td><input id="ic_p" name="ic_p" type="checkbox" value="pub" checked /></td></tr>'
  txt += '<tr><td>Usage</td><td><select id="is_u" name="is_u">'
  for (const un in pathUses) {
    txt += '<option value="' + un + '">' + pathUses[un].l + '</option>'
  }
  txt += '</select></td></tr>'
  txt += '<tr><td colspan="2"><input id="it_c" name="it_c" type="text" value="" placeholder="Commentaire" /></td></tr>'
  txt += '<tr><td></td><td align="right"><span id="b_ok" title="OK">Cr&eacute;er&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function patOneLiner (pat) {
  let cnt = 'Chemin '
  const kt = getXUseLabel(pat.u)
  cnt += kt + ' ' + (pat.p === true ? 'public' : '<i>priv&eacute;</i>') + ' ' + pat.n + ' ' + ((pat.c !== null) ? pat.c : '')
  return cnt
}

function patDisplay (pat) {
  const d = new Date(pat.ts * 1000)
  const od = fdf(d)
  const elapsed = timePast(Math.round(Date.now() - d))
  const guy = app.getObj('g', pat.gi)
  let na = guy.sn
  const titna = guy.fn + ' ' + guy.ln
  if (!na || na === '') { na = titna }
  app.selectObj(ObjType, pat.id)
  const kt = getXUseLabel(pat.u)
  let txt = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Chemin: ' + kt + ' ' + (pat.p === true ? 'public' : '<i>priv&eacute</i>') + ' (#' + pat.id + ')</span></legend><table>' +
    '<tr><td>Pilote</td><td><span title="' + titna + '">' + na + '</span></td></tr>'
  txt += '<tr><td colspan="2">' + pat.n + '</td></tr>'
  if (pat.c && pat.c.length > 0) {
    txt += '<tr><td colspan="2">' + mylink(pat.c).replace(/\n|\r/g, '<br/>') + '</td></tr>'
  }
  txt += '</td></tr><tr><td>M&agrave;J</td><td>' + od + ' (' + elapsed + ')</td></tr>'

  if (glo.me.id && (pat.gi === glo.me.id)) {
    //        txt += '<tr><td colspan="2" align="center"><span onclick="patMod('+n+');">'+glo.symb.mod.c+' Modifier</span></td></tr>';
    txt += '<tr><td><span id="de_' + ObjType + '_' + pat.id + '">' + glo.symb.trash.c + ' Supprimer</span></td>' // &#x2BBF;
    txt += '<td align="right"><span id="ed_' + ObjType + '_' + pat.id + '">Modifier <span style="font-weight:bold;font-size:150%">' + glo.symb.mod.c + '</span></span></td></tr>'
  }
  // if( glo.me!=null && glo.me.id === 1 ) { txt += '<tr><td colspan="2">'+__dump(pat)+'</td></tr>' }
  txt += '</table></fieldset>'
  return (txt)
}

/*
function patMod (n) {
  const fea = app.getSrcs(ObjType).getFeatureById(n)
  if (fea !== null) {
    mapModFea(fea)
  }
}
*/

// function patDrawAll() { $.each(app.getObjs(ObjType), function(pat, obj) { obj.draw(); }); }

function patLst (list) {
  if (list) list.forEach((ojs) => patUpd(ojs))
}

function getPat (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { patUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { patIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetPat err: ' + err) }
}

function patIns (ojs) {
  const obj = new Path(ojs)
  const id = obj.id
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  if (obj.gi && !app.getObj('g', obj.gi)) { app.getLater('g', obj.gi) }
  obj.draw()
}

function patUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Path(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  };
  if (obj.gi && !app.getObj('g', obj.gi)) { app.getLater('g', obj.gi) }
  obj.draw()
}

function patDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

// function patReset() {
//    app.getSrcs(ObjType).clear();
/// /    app.getObjs(ObjType) = {};
// }

function patStyle (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const pat = app.getObj(ObjType, f.getId())
  if (!pat || typeof pat === 'undefined') return []
  // let raw = f.get('raw');
  const use = pat.u
  const colo = getXUseColor(use)
  const widt = getXUseWidth(use)
  let dash, strok
  if ((dash = getXUseStroke(use)) !== null) {
    strok = new Stroke({ color: colo, lineDash: dash, width: widt, opacity: 1 })
  } else {
    strok = new Stroke({ color: colo, width: widt, opacity: 1 })
  }
  const sty = new Style({ stroke: strok })
  return [sty]
}

class Path extends BaseObject {
  constructor (ojs) {
    super()
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
  }

  update (ojs) {
    for (const key in ojs) { this[key] = ojs[key] }
    this.refresh()
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  draw () {
    const geom = geojformat.readGeometry(this.j)
    geom.applyTransform(app.tr2map)
    const fea = new Feature({
      geometry: geom,
      //        'raw': this,
      ot: ObjType
    })
    fea.setId(this.id)
    app.getSrcs(ObjType).addFeature(fea)
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) app.getSrcs(ObjType).removeFeature(fea)
  }

  check () {
    if (!app.getObj('g', this.gi)) app.getLater('g', this.gi)
  }

  remove () {
    this.undraw()
    app.getObjs(ObjType)[this.id] = null
    delete app.getObjs(ObjType)[this.id]
  }

  getDisplay () { return (patDisplay(this)) }
  getOneLiner () { return (patOneLiner(this)) }
}

export { Path, patStyle, getPat, patLst, patIns, patUpd, patDel, patNew }
