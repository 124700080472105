import BaseObject from 'ol/Object'

import { app, glo } from './globo'
import { getSit } from './sit'

const ObjType = 'i'

// i.id,
// date_part('epoch'::text, i.sent) AS ts,
// i.tof AS ti,
// i.guy AS gi,
// pgc_lat(i.poz) AS a,
// pgc_lgt(i.poz) AS g,
// pgc_alt(i.poz) AS t,
// i.fl,
// i.dir AS di,
// i.speed AS sp,
// i.gusts AS gu,
// i.low AS lo,
// i.neb AS ne,
// i.pres AS pr,
// i.temp AS te,
// i.valid AS v,
// i.msg AS m

const oris = ['???', 'Nord', 'NNE', 'NE', 'ENE', 'Est', 'ESE', 'SE', 'SSE', 'Sud', 'SSO', 'SO', 'OSO', 'Ouest', 'ONO', 'NO', 'NNO', 'Variable']

// local
function infNew (tofid) {
  const tof = app.getObj('t', tofid)
  if (!tof) { app.getLater('t', tofid); return '' }
  //    if( ! tof.av ) { return ''; }
  const sit = app.getObj('s', tof.si)
  if (!sit) { getSit(tof.si) }
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Nouvelle Info de vol</span></legend><table>'
  const curpos = app.getv('_pos')
  if (curpos !== null) {
    txt += '<input type="hidden" id="ih_lat" value="' + curpos[1] + '"/>'
    txt += '<input type="hidden" id="ih_lgt" value="' + curpos[0] + '"/>'
  }
  txt += '<input type="hidden" id="ih_tof" value="' + tofid + '"/>'
  txt += '<tr><td>D&eacute;collage</td><td><u><span id="go_t_' + tofid + '">' + tof.n + '</span></u>'
  if (sit) {
    txt += '&nbsp;de <u><span id="go_s_' + sit.id + '">' + sit.n + '</span></u>'
  }
  txt += '</td></tr>'

  txt += '<tr><td>* <span style="font-weight:bold">Niveau<br/>de vol</span></td><td><table><tr>'
  txt += '<td align="center"><label style="font-weight:bold;background:blue;color:white" for="ir_fl_1"> Faible </label><br/>'
  txt += '<input type="radio" id="ir_fl_1" name="ir_l" value="1"/></td>'
  txt += '<td align="center"><label style="font-weight:bold;background:green;color:white" for="ir_fl_2"> B&acirc;che </label><br/>'
  txt += '<input type="radio" id="ir_fl_2" name="ir_l" value="2"/></td>'
  txt += '<td align="center"><label style="font-weight:bold;background:orange;color:black" for="ir_fl_3"> Mini </label><br/>'
  txt += '<input type="radio" id="ir_fl_3" name="ir_l" value="3"/></td>'
  txt += '<td align="center"><label style="font-weight:bold;background:red;color:black" for="ir_fl_4"> Impossible </label><br/>'
  txt += '<input type="radio" id="ir_fl_4" name="ir_l" value="4"/></td>'
  txt += '</tr></table>'

  txt += '</td></tr><tr><td>* <span style="font-weight:bold">Orientation</span></td><td><select id="is_or">'
  for (const o in oris) {
    txt += '<option value="' + o + '"'
    if (o === 17) { txt += ' selected' }
    txt += '>' + oris[o] + '</option>'
  }
  txt += '</select></td></tr>'
  txt += '<tr><td>Vent Mini</td><td><input class="wnd" type="number" id="in_lo" min="0" max="150" value="" placeholder="0 &agrave; 150"> km / h</td></tr>'
  txt += '<tr><td>Vent * <span style="font-weight:bold">Moyen</span></td><td><input class="wnd" type="number" id="in_sp" min="0" max="150" value="" placeholder="0 &agrave; 150"> km / h</td></tr>'
  txt += '<tr><td>Rafales</td><td><input class="wnd" type="number" id="in_gu" min="0" max="150" value="" placeholder="0 &agrave; 150"> km / h</td></tr>'
  txt += '<tr><td>N&eacute;bulosit&eacute;</td><td><input class="neb" type="number" id="in_ne"  min="0" max="8" placeholder="0 &agrave; 8"> Octat</td></tr>'
  txt += '<tr><td>Temp&eacute;rature</td><td><input class="temp" type="number" id="in_te" min="-30" max="50" placeholder="-30 &agrave; 50"> °C</td></tr>'
  txt += '<tr><td>Pression</td><td><input class="pres" type="number" id="in_pr" min="900" max="1040" value=" placeholder=""> hPa<br/>'
  txt += '<span style="font-style:italic;font-style:smaller;">900 &agrave; 1040</span></td></tr>'
  txt += '<tr><td>Dur&eacute;e de<br/>validit&eacute;</td><td><input class="val" type="number" id="in_va"  min="0" max="8" value="" placeholder="0 &agrave; 8"> heures</td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_tx" cols="30" rows="3" value="" placeholder="Commentaire libre ..."></textarea></td></tr>'
  txt += '<tr><td></td><td align="right"><span id="b_ok" title="OK">Envoyer&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function infLst (list) {
  if (list) list.forEach((ojs) => infUpd(ojs))
}

function getInf (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { infUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) return (null); else infIns(r.body[0])
      }
    )
  } catch (err) { console.error('GetInf err: ' + err) }
}

function infIns (ojs) {
  const obj = new Info(ojs)
  const id = obj.id
  const ti = obj.ti
  const gi = obj.gi
  if (app.getObjs(ObjType)[id]) app.getObjs(ObjType)[id].remove()
  app.getObjs(ObjType)[id] = obj
  if (gi && !app.getObj('g', gi)) app.getLater('g', gi)
  const tof = app.getObj('t', ti)
  if (tof) tof.link(ObjType, id)
}

function infUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Info(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  const gi = obj.gi
  if (gi && !app.getObj('g', gi)) app.getLater('g', gi)
  const tof = app.getObj('t', obj.ti)
  if (!tof) app.getLater('t', obj.ti); else {
    if (!app.getObj('s', tof.si)) app.getLater('s', tof.si)
    if (!tof.linked(ObjType, obj.id)) tof.link(ObjType, obj.id)
  }
}

function infDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

class Info extends BaseObject {
  constructor (ojs) {
    super()
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
    const tof = app.getObj('t', this.ti)
    if (tof) tof.link(ObjType, this.id)
  }

  update (ojs) {
    // const changed = false
    for (const key in ojs) {
      if (this[key] !== ojs[key]) {
        // changed = true
        this[key] = ojs[key]
      }
    }
    const tof = app.getObj('t', this.ti)
    if (tof && !tof.linked(ObjType, this.id)) tof.link(ObjType, this.id)
  }

  check () {
    if (!app.getObj('g', this.gi)) app.getLater('g', this.gi)
    const tof = app.getObj('t', this.ti)
    if (tof) tof.link(ObjType, this.id); else app.getLater('t', this.ti)
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  remove () {
    const id = this.id
    const tof = app.getObj('t', this.ti)
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
    if (tof && tof.linked(ObjType, id)) tof.unlink(ObjType, id)
  }
}

export { Info, getInf, infLst, infIns, infUpd, infDel, infNew }
