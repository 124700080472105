import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'

import { app, glo } from './globo'
import { timePast, isMyfriend } from './libra'

const ObjType = '0'

// cnx.sid AS id,
// date_part('epoch'::text, cnx.ts)::integer AS ts,
// date_part('epoch'::text, cnx.pts)::integer AS ps,
// pgc_lat(cnx.poz) AS a,
// pgc_lgt(cnx.poz) AS g,
// pgc_alt(cnx.poz) AS t,
// cnx.gid AS gi,
// cnx.poz AS pz,
// cnx.data -> 'pac'::text AS pac,
// cnx.data -> 'aac'::text AS aac,
// cnx.data -> 'dir'::text AS dir,
// cnx.data -> 'spd'::text AS spd,
// cnx.data -> 'trk'::text AS trk

// local

function cnxOneLiner (oid) {
  let cnx
  if (typeof oid === 'number') {
    cnx = app.getObj(ObjType, oid)
    if (!cnx) { getCnx(oid); return '' }
  } else cnx = app.getObj(ObjType, oid.id)
  let guy = null
  if (cnx.gi) {
    guy = app.getObj('g', cnx.gi)
    if (!guy) app.getLater('g', cnx.gi)
  }
  const dcts = new Date(Math.max(cnx.ps, cnx.ts) * 1000)
  const agemillis = Date.now() - dcts.getTime()
  const tp = timePast(agemillis)
  let na = 'Anonyme'
  let titna = na
  if (guy) {
    na = guy.sn
    titna = guy.fn + ' ' + guy.ln
  }
  if (!na || na === '') na = titna
  const cnt = 'Connexion de ' + na + ' ' + tp
  return cnt
}

function cnxDisplay (oid) {
  let cnx
  if (typeof oid === 'number') {
    cnx = app.getObj(ObjType, oid)
    if (!cnx) { getCnx(oid); return '' }
  } else cnx = app.getObj(ObjType, oid.id)
  app.selectObj(ObjType, cnx.id)
  let guy = null
  if (cnx.gi) {
    guy = app.getObj('g', cnx.gi)
    if (!guy) app.getLater('g', cnx.gi)
  }
  const dcts = new Date(Math.max(cnx.ps, cnx.ts) * 1000)
  const agemillis = Date.now() - dcts.getTime()
  const tp = timePast(agemillis)
  const od = (dcts.getDate() < 10 ? '0' : '') + dcts.getDate() +
    '/' + (dcts.getMonth() < 9 ? '0' : '') + (dcts.getMonth() + 1) +
    ' ' + (dcts.getHours() < 10 ? '0' : '') + dcts.getHours() +
    ':' + (dcts.getMinutes() < 10 ? '0' : '') + dcts.getMinutes() +
    ':' + (dcts.getSeconds() < 10 ? '0' : '') + dcts.getSeconds()
  let na = 'Anonyme'
  let titna = na
  if (guy) {
    na = guy.sn
    titna = guy.fn + ' ' + guy.ln
  }
  if (!na || na === '') na = titna
  let cette = 'C'
  if (cnx.id === app.getv('sid')) cette = 'Cette c'
  let txt = '<fieldset><legend><span id="b_back"></span> &nbsp; <span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;'
  if (glo.me.id && glo.me.id === 1) { txt += '<span title="' + cnx.id + '">' + cette + 'onnexion</span>' } else { txt += cette + 'onnexion' }
  txt += '</span></legend><table><tr><td>Pilote</td><td>'
  if (guy) {
    txt += '<span id="go_g_' + guy.id + '" title="' + titna + '"><u>' + na + '</u></span>'
    if (guy.e) { txt += ' &nbsp; <a style="font-style:larger" href="mailto:' + guy.e + '?subject=From OPS" target="_blank"><img src="/img/sendmail_16.png" valign="baseline"/></a>' }
    if (guy.p) {
      txt += ' &nbsp; <a style="font-style:larger" href="tel://' + guy.p + '" target="_blank">' + glo.symb.tel.c + '</a>'
      txt += ' &nbsp; <a style="font-style:larger" href="sms://' + guy.p + '" target="_blank">' + glo.symb.sms.c + '</a>'
    }
    if (glo.me.id && glo.me.id !== guy.id) {
      if (isMyfriend(guy.id)) { txt += ' &nbsp;-> <span id="uf_' + guy.id + '">' + glo.symb.unfrd.c + '</span>' } else { txt += ' &nbsp;-> <span id="sf_' + guy.id + '">' + glo.symb.frd.c + '</span>' }
    }
  } else {
    txt += na
  }
  txt += '</td></tr><tr><td>Date</td><td>' + od + ' (' + tp + ') &nbsp;&nbsp; <img src="' + _getCnxImgUrl(cnx.ik) + '" width="25" height="25"/></td></tr>' +
        '<tr><td><span id="mc_' + ObjType + '_' + cnx.id + '" style="font-size:large">' + glo.symb.navi.c + '</span> Position</td><td>' + cnx.a.toFixed(5) + ' ' + cnx.g.toFixed(5)
  if (cnx.t && cnx.t > 0) txt += ' ' + cnx.t + 'm'
  const curpos = app.getv('_pos')
  if (curpos) { txt += ' <a target="_blank" href="https://www.google.com/maps/preview/dir/' + curpos[1].toFixed(6) + ',' + curpos[0].toFixed(6) + '/' + cnx.a + ',' + cnx.g + '/">&lt;=Nav</a>' }
  if (glo.me.id && glo.me.id >= 0) {
    txt += '<tr><td colspan="2"><input id="sm_cnx" type="hidden" value="' + cnx.id + '"/>'
    txt += '<textarea style="font-size:larger;font_weight:bolder" id="sm_txt" cols="30" rows="2" maxlength="64" wrap="hard" placeholder="... faire un coucou ..."></textarea>'
    txt += '</td></tr>'
    txt += '<tr><td colspan="2" align="right"><span id="b_sm">Envoi&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  }
  txt += '</table></fieldset>'
  cnx.update()
  return (txt)
}

function cnxLst (list) {
  if (list) list.forEach((ojs) => cnxUpd(ojs))
}

function cnxIns (ojs) {
  const obj = new Connex(ojs)
  const id = obj.id
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  obj.check()
}
function cnxUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Connex(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  obj.check()
}

function cnxDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function getCnx (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { cnxUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { cnxIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetCnx err: ' + err) }
}

function _getCnxImgUrl (key) {
  switch (key) {
    case ObjType + '1': return '/img/bugyel.png'
    case ObjType + '2': return '/img/bugblu.png'
    case ObjType + '4': return '/img/buggre.png'
    case ObjType + '8': return '/img/bugwhi.png'
    default: return '/img/bugbla.png'
  }
}

function cnxStyle (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const cnx = app.getObj(ObjType, f.getId())
  if (!cnx || typeof cnx === 'undefined') return []
  const key = cnx.ik
  const url = _getCnxImgUrl(key)

  const itmillis = app.getv('it') * 3600000
  const dcts = new Date(Math.max(cnx.ps, cnx.ts) * 1000)
  const agemillis = Date.now() - dcts.getTime()
  if (agemillis > itmillis) { return null }
  cnx.pct = 1.03 - (agemillis / itmillis)
  const rota = (cnx.dir) ? cnx.dir * 0.017453292519943 : 0.0

  const sty = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction', // || pixels
      opacity: cnx.pct,
      rotation: rota,
      src: url
    })
  })
  return [sty]
}

function _getCnxKey (cnx) {
  if (!cnx) { return (ObjType + 'N') }
  if (cnx.id === app.getv('sid')) { return (ObjType + '1') }
  if (glo.me.id && glo.me.id >= 0) {
    if (cnx.gi) {
      if (cnx.gi === glo.me.id) {
        return (ObjType + '2')
      } else {
        const guy = app.getObj('g', cnx.gi)
        if (guy && guy.friend()) { return (ObjType + '4') } else { return (ObjType + '8') }
      }
    }
  } else {
    if (cnx.gi && cnx.gi >= 0) return (ObjType + '8')
  }
  return (ObjType + 'N')
}

class Connex extends BaseObject {
  constructor (ojs) {
    super()
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
    this.ik = _getCnxKey(ojs)
    this.draw()
  }

  update (ojs) {
    let changed = false
    if (!ojs) {
      const nik = _getCnxKey(this)
      if (nik !== this.ik) {
        this.ik = nik
        changed = true
      }
    } else {
      for (const key in ojs) {
        if (typeof this[key] === 'undefined' || this[key] !== ojs[key]) {
          changed = true
          this[key] = ojs[key]
        }
      }
      const nik = _getCnxKey(this)
      if (nik !== this.ik) {
        this.ik = nik
        changed = true
      }
    }
    if (changed) this.refresh()
  }

  draw () {
    const fea = new Feature({
      geometry: new Point(app.tr2map([this.g, this.a, this.t]), 'XYZ'),
      ot: ObjType
    })
    fea.setId(this.id)
    fea.set('ot', ObjType)
    app.getSrcs(ObjType).addFeature(fea)
    fea.changed()
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) app.getSrcs(ObjType).removeFeature(fea)
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  check () {
    if (this.gi && !app.getObj('g', this.gi)) app.getLater('g', this.gi)
  }

  remove () {
    const id = this.id
    this.undraw()
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
  }

  getDisplay () { return (cnxDisplay(this)) }
  getOneLiner () { return (cnxOneLiner(this)) }
}

export { Connex, getCnx, cnxStyle, cnxLst, cnxIns, cnxUpd, cnxDel }
