import EventBus from '@vertx/eventbus-bridge-client.js'
import { Map, View } from 'ol'
import { OLCS_ION_TOKEN } from './cesium_token_comm.js'
import OLCesium from 'olcs'
import Geolocation from 'ol/Geolocation'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Circle from 'ol/style/Circle'
import ScaleLine from 'ol/control/ScaleLine'
import FullScreen from 'ol/control/FullScreen'
import MousePosition from 'ol/control/MousePosition'
import Zoom from 'ol/control/Zoom'
import { fromLonLat, getTransform } from 'ol/proj'
import { click, pointerMove } from 'ol/events/condition'
import Select from 'ol/interaction/Select'

import { glo, app } from './globo'
import { viewChanged, mapClicked, posChanged, initDnD, setDnD, initMS, getMapSrcByCode } from './carte'
import { mousePosFormat } from './libra'
import { topMenu, dspLogin, dspDiv, featureClicked, featureOver, displayError, displayMsgDirect, displayBonjour, updRecBtn, objectDisplay } from './decor'

import { balStyle, balLst, balIns, balUpd, balDel, getBal } from './bal'
import { sitStyle, sitLst, sitIns, sitUpd, sitDel, getSit } from './sit'
import { detLst, detIns, detUpd, detDel, getDet } from './det'
import { evtLst, evtIns, evtUpd, evtDel, getEvt } from './evt'
import { guyLst, getGuy } from './guy'
import { pmgLst, pmgIns, pmgUpd, pmgDel, getPmg } from './pmg'
import { lanStyle, lanLst, lanIns, lanUpd, lanDel, getLan } from './lan'
import { tofStyle, tofLst, tofIns, tofUpd, tofDel, getTof } from './tof'
import { infLst, infIns, infUpd, infDel, getInf } from './inf'
import { votLst, votIns, votUpd, votDel, getVot } from './vot'
import { wshStyle, wshLst, wshIns, wshUpd, wshDel, getWsh } from './wsh'
import { annStyle, annLst, annIns, annUpd, annDel, getAnn } from './ann'
import { cnxStyle, cnxLst, cnxIns, cnxUpd, cnxDel, getCnx } from './cnx'
import { chaStyle, chaLst, chaIns, chaUpd, chaDel, getCha } from './cha'
import { hmgStyle, hmgLst, hmgIns, hmgUpd, hmgDel, getHmg } from './hmg'
import { patStyle, patLst, patIns, patUpd, patDel, getPat } from './pat'
import { runStyle, runLst, runIns, runUpd, runDel, getRun } from './run'
import { thgStyle, thgLst, thgIns, thgUpd, thgDel, getThg } from './thg'
import { thmStyle, thmLst, thmIns, thmUpd, thmDel, getThm } from './thm'
import { volStyle, volLst, volIns, volUpd, volDel, getVol } from './vol'

const defUprj = 'EPSG:4326'
const defVprj = 'EPSG:3857'
const defRefItv = 15000 // refresh interval millis

const Pgc = function () {
  const _v = {
    _pos: null, // position
    _ptr: null, // pointer
    _ori: null, // origin
    _pac: null, // position accuracy
    _alt: null, // altitude
    _aac: null, // altitude accuracy
    _dir: null, // direction
    _spd: null, // speed
    sid: localStorage.getItem('sid') || -1, // session ID
    lgc: localStorage.getItem('lgc') ? parseFloat(localStorage.getItem('lgc')) : 6.2, // longitude center
    lac: localStorage.getItem('lac') ? parseFloat(localStorage.getItem('lac')) : 45.3, // latitude center
    zl: localStorage.getItem('zl') ? parseFloat(localStorage.getItem('zl')) : 10, // zoom level
    it: localStorage.getItem('it') ? parseInt(localStorage.getItem('it')) : 2, // infotime
    fut: localStorage.getItem('fut') ? parseInt(localStorage.getItem('fut')) : 4, // future
    bz: localStorage.getItem('bz') ? parseInt(localStorage.getItem('bz')) : 12, // big zoom
    trk: localStorage.getItem('trk') ? parseInt(localStorage.getItem('trk')) : 1, // tracking level
    tag: localStorage.getItem('tag') ? localStorage.getItem('tag') : null, // tracking tag
    dec: localStorage.getItem('dec') ? JSON.parse(localStorage.getItem('dec')) : false, // decoration
    wt: localStorage.getItem('wt') ? JSON.parse(localStorage.getItem('wt')) : false, // windtype
    df: localStorage.getItem('df') ? parseFloat(localStorage.getItem('df')) : 1.0, // display factor
    bSq: localStorage.getItem('bSq') ? parseFloat(localStorage.getItem('bSq')) : 50.0, // base square
    cms: localStorage.getItem('cms') ? parseInt(localStorage.getItem('cms')) : 0, // current map source id
    asl: localStorage.getItem('asl') ? parseInt(localStorage.getItem('asl')) : 0, // current airspace lower
    asu: localStorage.getItem('asu') ? parseInt(localStorage.getItem('asu')) : 0, // current airspace upper

    us: localStorage.getItem('us') || 'kh', // unit speed; ms, nd
    ud: localStorage.getItem('ud') || 'km', // unit distance; mi
    lg: localStorage.getItem('lg') || 'fr_FR', // language
    ze: parseInt(localStorage.getItem('ze')) || 10 // zone extension
  }

  // _trking = {
  //  's': L=local(web), A=trksvc(apk)
  //  'c': "cnxId"
  //  't': "tagId"
  //  'l': 0=can(loc/apk), 1=lowAcc, 2=highAcc, 3=track
  // }
  let _trking = window.AndTrkSvc ? JSON.parse(window.AndTrkSvc.getStatus()) : { X: 'L', c: _v.sid, t: _v.tag, l: _v.trk }
  // let locRuns = [];

  // yes layers defaults
  let _yes = {
    _i: true, // isolateds
    _d: false, // drawing
    _m: true, // map
    _p: true, // position
    _t: false, // trajet
    0: false, // cnx
    a: false,
    b: true,
    c: false,
    h: true,
    k: false,
    l: false,
    o: false,
    r: false,
    s: true,
    sw: true,
    t: true,
    tw: true,
    w: true,
    x: false,
    y: false
  }
  // order and control of layers
  const _layOrder = [
    { k: '_m', u: false, v: true, l: 'Carte' },
    { k: 'o', u: true, v: true, l: 'Volumes' },
    { k: 'x', u: true, v: true, l: 'Chemins' },
    { k: 'sw', u: true, v: true, l: 'Sites nus' },
    { k: 'tw', u: true, v: true, l: 'D&eacute;cos nus' },
    { k: 'k', u: true, v: true, l: 'P.I.s' },
    { k: 'c', u: true, v: true, l: 'Thermiques' },
    { k: 'b', u: true, v: true, l: 'Balises' },
    { k: '0', u: true, v: true, l: 'Connexions' },
    { k: 'l', u: true, v: true, l: 'Atterros' },
    { k: '_p', u: true, v: true, l: 'Position' },
    { k: '_r', u: true, v: true, l: 'Rep&egrave;res' },
    { k: 'a', u: true, v: true, l: 'Annonces' },
    { k: 'w', u: true, v: true, l: 'Sorties' },
    { k: '_t', u: true, v: true, l: 'Trajet' },
    { k: 'y', u: true, v: true, l: 'Chats' },
    { k: 's', u: true, v: true, l: 'Sites' },
    { k: 't', u: true, v: true, l: 'D&eacute;cos' },
    { k: 'r', u: true, v: true, l: 'Vols' },
    { k: 'h', u: true, v: true, l: 'Aides' },
    { k: '_d', u: true, v: true, l: 'Dessin' },
    { k: '_i', u: true, v: true, l: 'Isolated' }
  ]

  let objs = null // database objects
  let srcs = null // VectorSources to store features
  let lays = null // VectorLayers
  let map = null // THE map
  let view = null // THE view
  let m3map = null // the map 3D
  let m3lay = null // the layer 3D
  let m3vie = null // the view 3D
  let m3olc = null // ol-cesium
  let m3sce = null // ol-cesium scene
  let m3cam = null // ol-cesium camera
  const uprj = defUprj // user projection
  const vprj = defVprj // view projection
  let _geolocation = null
  let _refreshItv = null
  let _cameraItv = null
  let _laterItv = null
  const _later = {
    g: []
  }
  let tobereloaded = false
  const _sels = {
    t: []
  }

  // contents of prox {
  // "upd": <timeInMillis>,
  // "t": [ {"id":1, "dst":2726}, {"id":5, "dst":2833} ]
  // "s": [ {"id":1, "dst":2650} ]
  // "l": [ {"id":2, "dst":2609} ]
  // };
  let _proxies = { upd: 0 }

  const _defStyles = {
    Point: new Style({
      image: new Circle({
        fill: new Fill({
          color: 'rgba(255,0,0,0.75)'
        }),
        radius: 3,
        stroke: new Stroke({
          color: '#000',
          width: 1
        })
      })
    }),
    IsolSyr: new Style({
      image: new Circle({
        fill: new Fill({
          color: 'rgba(255,0,0,0.75)'
        }),
        radius: 4,
        stroke: new Stroke({
          color: '#000',
          width: 2
        })
      })
    }),
    IsolCfd: new Style({
      image: new Circle({
        fill: new Fill({
          color: 'rgba(0,0,255,0.75)'
        }),
        radius: 4,
        stroke: new Stroke({
          color: '#000',
          width: 2
        })
      })
    }),
    IsolUnk: new Style({
      image: new Circle({
        fill: new Fill({
          color: 'rgba(255,255,0,0.75)'
        }),
        radius: 4,
        stroke: new Stroke({
          color: '#000',
          width: 2
        })
      })
    }),
    LineString: new Style({
      stroke: new Stroke({
        color: '#f00',
        width: 2
      })
    }),
    Polygon: new Style({
      fill: new Fill({
        color: 'rgba(0,200,200,0.4)'
      }),
      stroke: new Stroke({
        color: '#000',
        width: 1
      })
    }),
    MultiPoint: new Style({
      image: new Circle({
        fill: new Fill({
          color: 'rgba(255,0,255,0.5)'
        }),
        radius: 5,
        stroke: new Stroke({
          color: '#f0f',
          width: 1
        })
      })
    }),
    MultiLineString: new Style({
      stroke: new Stroke({
        color: '#0f0',
        width: 2
      })
    }),
    MultiPolygon: new Style({
      fill: new Fill({
        color: 'rgba(0,0,255,0.5)'
      }),
      stroke: new Stroke({
        color: '#00f',
        width: 1
      })
    })
  }

  const _drwStyle = function (feature, resolution) {
    const featureStyleFunction = feature.getStyleFunction()
    if (featureStyleFunction) {
      // console.debug('_drwStyle GOT STYLE for ' + feature.getGeometry().getType())
      return featureStyleFunction.call(feature, resolution)
    } else {
      // console.debug('_drwStyle DEFO STYLE for ' + feature.getGeometry().getType())
      return [_defStyles[feature.getGeometry().getType()]]
    }
  }

  const _isoStyle = function (f, reso) {
    // console.debug('isoStyle');
    switch (f.get('s')) {
      case 's' : return [_defStyles.IsolSyr, _defStyles.LineString]
      case 'f' : return [_defStyles.IsolCfd, _defStyles.LineString]
      default : return [_defStyles.IsolUnk, _defStyles.LineString]
    }
    //    return [ _defStyles['Isol'] ];
    //    let sty = new Style({
    //        image: new Icon({
    //            anchor: [0.5, 0.5],
    //            anchorXUnits: 'fraction',
    //            anchorYUnits: 'fraction',       // || pixels
    //            opacity: 1.,
    //            rotation: 0.0,
    //            src: '/img/bugora.png'
    //        })
    //        });
    //        return([sty]);
  }

  const _tr2map = getTransform(uprj, vprj)
  const _tr2usr = getTransform(vprj, uprj)
  const _getv = function (k) { return _v[k] }
  const _setv = function (k, v) {
    if (k === 'gid') { // arrive du serveur
      glo.me.id = parseInt(v)
      return
    }
    switch (k) {
      case 'sn':
      case 'fn':
      case 'ln':
      case 'em':
      case 'ph':
      case 'ym': glo.me[k] = v; return
    }
    _v[k] = v
    if (k.charAt(0) !== '_') { localStorage.setItem(k, v) }
    if (k === 'sid') { _trking.c = v }
    if (k === 'trk') { _trking.l = v }
    if (k === 'tag') { _trking.t = v }
  }
  const _keepYes = function () {
    localStorage.setItem('yes', JSON.stringify(_yes))

    if (_eb) {
      try {
        _send('ss', {
          yk: _yes.k,
          yo: _yes.o,
          yr: _yes.r,
          yx: _yes.x,
          ya: _yes.a,
          yy: _yes.y,
          yc: _yes.c
        }, null)
      } catch (err) { console.error('KY BUS err: ' + err) }
    }
  }

  // objects
  const _initObjects = function () {
    if (objs) { return }
    objs = {}
    objs._p = {} // position
    objs._r = {} // repères
    objs['0'] = {} // connexion
    objs._t = {} // trajet
    objs['2'] = {} // phrases
    objs.a = {} // ann
    objs.b = {} // bal
    objs.c = {} // thermic
    objs.d = {} // detail
    objs.e = {} // event
    objs.g = {} // guy
    objs.h = {} // help
    objs.i = {} // info
    objs.k = {} // thing
    objs.l = {} // landing
    objs.o = {} // volume
    objs.p = {} // post-it
    objs.r = {} // run
    objs.s = {} // site
    objs.t = {} // takeoff
    objs.u = {} // user-msg
    objs.v = {} // vote
    objs.w = {} // wish
    objs.x = {} // path
    objs.y = {} // chat
    objs.z = {} // zore
  }
  const _getObjs = function (ot) {
    return (objs[ot])
  }
  const _getObj = function (ot, oid) {
    if (!objs[ot][oid]) {
      return null
    }
    return (objs[ot][oid])
  }
  const _retObj = function (ot, oid) {
    switch (ot) {
      case '0' : getCnx(oid); break
      case 'b' : getBal(oid); break
      case 's' : getSit(oid); break
      case 'd' : getDet(oid); break
      case 'e' : getEvt(oid); break
      case 'g' : getGuy(oid); break
      case 'p' : getPmg(oid); break
      case 'l' : getLan(oid); break
      case 't' : getTof(oid); break
      case 'i' : getInf(oid); break
      case 'v' : getVot(oid); break
      case 'w' : getWsh(oid); break
      case 'a' : getAnn(oid); break
      case 'y' : getCha(oid); break
      case 'h' : getHmg(oid); break
      case 'x' : getPat(oid); break
      case 'r' : getRun(oid); break
      case 'k' : getThg(oid); break
      case 'c' : getThm(oid); break
      case 'o' : getVol(oid); break
    }
  }

  // features
  const _initSources = function () {
    if (srcs) { return }
    // srcs = {}
    srcs = {}
    srcs._i = new VectorSource({ features: [] }) // isolated
    srcs._d = new VectorSource({ features: [] }) // drw
    srcs._p = new VectorSource({ features: [] }) // pos
    srcs._r = new VectorSource({ features: [] }) // repères
    srcs['0'] = new VectorSource({ features: [] }) // cnx
    srcs._t = new VectorSource({ features: [] }) // trj
    srcs.a = new VectorSource({ features: [] })
    srcs.b = new VectorSource({ features: [] })
    srcs.c = new VectorSource({ features: [] })
    srcs.h = new VectorSource({ features: [] })
    srcs.k = new VectorSource({ features: [] })
    srcs.l = new VectorSource({ features: [] })
    srcs.o = new VectorSource({ features: [] })
    srcs.r = new VectorSource({ features: [] })
    srcs.s = new VectorSource({ features: [] })
    srcs.sw = new VectorSource({ features: [] })
    srcs.t = new VectorSource({ features: [] })
    srcs.tw = new VectorSource({ features: [] })
    srcs.w = new VectorSource({ features: [] })
    srcs.x = new VectorSource({ features: [] })
    srcs.y = new VectorSource({ features: [] })
  }
  const _getSrcs = function (w) {
    return (srcs[w])
  }

  const _getCurrentMapSourceId = function () {
    return _v.cms
  }

  /*
    var _manageSeen = function ( sot, ol ) {
    let ot = sot.slice(0,-1);
        for( let id of ol ) {
        let obj = _getObj(ot, id);
            if( obj ) {
                obj.see(true);
//console.log("ManageSeen:"+ot+"#"+id+" seen");
//            } else {
//                _getLater(ot, id);
            }
        }
    };
*/
  // layers
  const _initLayers = function () {
    if (lays) { return }
    lays = {}
    lays._m = new TileLayer({ // always here
      source: getMapSrcByCode(_v.cms).getSource(),
      preload: Infinity,
      isBaseLayer: true
    })
    lays._i = new VectorLayer({ source: srcs._i, style: _isoStyle, visible: true })
    lays._d = new VectorLayer({ source: srcs._d, style: _drwStyle, visible: _yes._d })
    lays._p = new VectorLayer({ source: srcs._p, visible: _yes._p })
    lays._r = new VectorLayer({ source: srcs._r, visible: true })
    lays['0'] = new VectorLayer({ source: srcs['0'], style: cnxStyle, visible: _yes['0'] })
    lays._t = new VectorLayer({ source: srcs._t, visible: _yes._t })
    lays.a = new VectorLayer({ source: srcs.a, style: annStyle, visible: _yes.a })
    lays.b = new VectorLayer({ source: srcs.b, style: balStyle, visible: _yes.b })
    lays.c = new VectorLayer({ source: srcs.c, style: thmStyle, visible: _yes.c })
    lays.h = new VectorLayer({ source: srcs.h, style: hmgStyle, visible: true })
    lays.k = new VectorLayer({ source: srcs.k, style: thgStyle, visible: _yes.k })
    lays.l = new VectorLayer({ source: srcs.l, style: lanStyle, visible: _yes.l && (_v.zl >= _v.bz) })
    lays.o = new VectorLayer({ source: srcs.o, style: volStyle, visible: _yes.o })
    lays.r = new VectorLayer({ source: srcs.r, style: runStyle, visible: _yes.r })
    lays.s = new VectorLayer({ source: srcs.s, style: sitStyle, visible: _yes.s })
    lays.sw = new VectorLayer({ source: srcs.sw, style: sitStyle, visible: _yes.sw && (_v.zl < _v.bz) && (_v.zl > 8) })
    lays.t = new VectorLayer({ source: srcs.t, style: tofStyle, visible: _yes.t && (_v.zl >= _v.bz) })
    lays.tw = new VectorLayer({ source: srcs.tw, style: tofStyle, visible: _yes.t && (_v.zl >= _v.bz) && _yes.sw })
    lays.w = new VectorLayer({ source: srcs.w, style: wshStyle, visible: _yes.w })
    lays.x = new VectorLayer({ source: srcs.x, style: patStyle, visible: _yes.x })
    lays.y = new VectorLayer({ source: srcs.y, style: chaStyle, visible: _yes.y })
    for (const layCode of ['_d', 'r', '0', 'b', 'c', 'h', 'k', 'l', 's', 'sw', 't', 'tw', 'x', 'o']) {
      // lays[layCode].set('altitudeMode', (layCode !== '_d' && layCode !== 'o') ? 'clampToGround' : 'relativeToGround')
      lays[layCode].set('altitudeMode', (layCode !== '_d') ? 'clampToGround' : 'relativeToGround')
      // lays[ layCode ].set('altitudeMode', 'relativeToGround');
    }
  }

  const _updLayers = function () {
    lays._i.setVisible(true)
    lays._m.setVisible(true)
    lays._p.setVisible(_yes._p)
    lays._r.setVisible(true)
    lays._d.setVisible(_yes._d)
    lays._t.setVisible(_yes._t)
    lays['0'].setVisible(_yes['0'])
    lays.a.setVisible(_yes.a)
    lays.b.setVisible(_yes.b)
    lays.c.setVisible(_yes.c)
    lays.h.setVisible(true)
    lays.k.setVisible(_yes.k)
    lays.l.setVisible(_yes.l && (_v.zl >= _v.bz))
    lays.o.setVisible(_yes.o)
    lays.r.setVisible(_yes.r)
    lays.s.setVisible(_yes.s)
    lays.sw.setVisible(_yes.sw && (_v.zl < _v.bz) && (_v.zl > 8))
    lays.t.setVisible(_yes.t && (_v.zl >= _v.bz))
    lays.tw.setVisible(_yes.t && (_v.zl >= _v.bz) && _yes.sw)
    lays.w.setVisible(_yes.w)
    lays.x.setVisible(_yes.x)
    lays.y.setVisible(_yes.y)
  }

  const _layerOn = function (layN) {
    return lays[layN] && lays[layN].getVisible()
  }

  const _setLayer = function (layN, sta) {
    if (lays[layN]) {
      lays[layN].setVisible(_yes[layN] = sta)
      _keepYes()
    }
  }

  const _getLayer = function (layN) {
    return (lays[layN])
  }

  const _selectObj = function (ot, oid) {
    if (!_sels[ot]) { _sels[ot] = [] }
    let found = false
    _sels[ot].forEach(function (seloid) {
      if (seloid === oid) found = true
    })
    if (found === true || (_sels[ot][0] && (_sels[ot][0] === oid))) { return }
    _sels[ot].unshift(oid)
  }

  const _getSelObjsType = function (ot) {
    if (!_sels[ot]) { return [] }
    return _sels[ot]
  }

  const _setOption = function (opt, sta) {
    switch (opt) {
      case 'wt' :
        _setv(opt, sta)
        srcs.b.refresh()
        break
      case 'dec' :
        _setv(opt, sta)
        srcs.s.refresh()
        srcs.t.refresh()
        srcs.l.refresh()
        break
    }
  }

  const _initView = function () {
    const cent = [_getv('lgc'), _getv('lac')]
    if (!view) {
      view = new View({
        projection: vprj,
        center: fromLonLat(cent),
        zoom: _getv('zl'),
        minZoom: 7,
        maxZoom: 20,
        enableRotation: false
      })
      _setTrack(_trking.l)
    }
  }

  const _cnxGuyRefresh = function (gid) {
    objs['0'].forEach(function (c) {
      if (c.gid === gid) c.refresh()
    })
  }

  const _busIn = function (body) {
    // console.log('_busIn:'+JSON.stringify(body));
    // console.log('_busIn: '+body.typ);
    if (!glo.me.id) { return }
    if (_laterItv) { clearTimeout(_laterItv) }
    _laterItv = setTimeout(_retrieveLaters, 2000)
    //        if( body.typ.endsWith('s') ) {
    //            _manageSeen(body.typ, body.lst);
    //            return;
    //        }
    switch (body.typ) {
      case 'reload': tobereloaded = true; break
      case 'seen': {
        // console.info('seen:'+JSON.stringify(body.obj));
        const theobj = _getObj(body.obj.ot, body.obj.oi)
        if (theobj) theobj.see(body.obj.ac === 'y')
      } break
      case 'direct': displayMsgDirect(body.obj); break

      case 'hi': hmgIns(body.obj); break
      case 'hu': hmgUpd(body.obj); break
      case '0i': cnxIns(body.obj); break
      case '0u': cnxUpd(body.obj); break
      case 'bu': balUpd(body.obj); break
      case 'ii': infIns(body.obj); break
      case 'iu': infUpd(body.obj); break
      case 'pi': pmgIns(body.obj); break
      case 'pu': pmgUpd(body.obj); break
      case 'ei': evtIns(body.obj); break
      case 'eu': evtUpd(body.obj); break
      case 'vi': votIns(body.obj); break
      case 'vu': votUpd(body.obj); break
      case 'wi': wshIns(body.obj); break
      case 'wu': wshUpd(body.obj); break
      case 'yi': chaIns(body.obj); break
      case 'yu': chaUpd(body.obj); break

      case '0l': cnxLst(body.lst); break
      case '0d': cnxDel(body.obj); break

      case 'al': annLst(body.lst); break
      case 'ai': annIns(body.obj); break
      case 'au': annUpd(body.obj); break
      case 'ad': annDel(body.obj); break

      case 'bl': balLst(body.lst); break
      case 'bi': balIns(body.obj); break
      case 'bd': balDel(body.obj); break

      case 'cl': thmLst(body.lst); break
      case 'ci': thmIns(body.obj); break
      case 'cu': thmUpd(body.obj); break
      case 'cd': thmDel(body.obj); break

      case 'dl': detLst(body.lst); break
      case 'di': detIns(body.obj); break
      case 'du': detUpd(body.obj); break
      case 'dd': detDel(body.obj); break

      case 'el': evtLst(body.lst); break
      case 'ed': evtDel(body.obj); break

      case 'fi': { const guy = app.getObj('g', body.obj.pid)
        if (guy) { guy.setFriend(true); _cnxGuyRefresh(body.obj.pid) }
      } break
      case 'fd': { const guy = app.getObj('g', body.obj.pid)
        if (guy) { guy.setFriend(false); _cnxGuyRefresh(body.obj.pid) }
      } break

      case 'gl': guyLst(body.lst); break

      case 'hl': hmgLst(body.lst); break
      case 'hd': hmgDel(body.obj); break

      case 'il': infLst(body.lst); break
      case 'id': infDel(body.obj); break

      case 'kl': thgLst(body.lst); break
      case 'ki': thgIns(body.obj); break
      case 'ku': thgUpd(body.obj); break
      case 'kd': thgDel(body.obj); break

      case 'll': lanLst(body.lst); break
      case 'li': lanIns(body.obj); break
      case 'lu': lanUpd(body.obj); break
      case 'ld': lanDel(body.obj); break

      case 'ol': volLst(body.lst); break
      case 'oi': volIns(body.obj); break
      case 'ou': volUpd(body.obj); break
      case 'od': volDel(body.obj); break

      case 'pl': pmgLst(body.lst); break
      case 'pd': pmgDel(body.obj); break

      case 'rl': runLst(body.lst); break
      case 'ri': runIns(body.obj); break
      case 'ru': runUpd(body.obj); break
      case 'rd': runDel(body.obj); break

      case 'sl': sitLst(body.lst); break
      case 'si': sitIns(body.obj); break
      case 'su': sitUpd(body.obj); break
      case 'sd': sitDel(body.obj); break

      case 'tl': tofLst(body.lst); break
      case 'ti': tofIns(body.obj); break
      case 'tu': tofUpd(body.obj); break
      case 'td': tofDel(body.obj); break

        // case 'ui': umgIns(body.obj); break;
        // case 'uu': umgUpd(body.obj); break;
        // case 'ud': umgDel(body.obj); break;

      case 'vl': votLst(body.lst); break
      case 'vd': votDel(body.obj); break

      case 'wl': wshLst(body.lst); break
      case 'wd': wshDel(body.obj); break

      case 'xl': patLst(body.lst); break
      case 'xi': patIns(body.obj); break
      case 'xu': patUpd(body.obj); break
      case 'xd': patDel(body.obj); break

      case 'yl': chaLst(body.lst); break
      case 'yd': chaDel(body.obj); break

      case 'bj': displayBonjour(body.lst); break

      default :
        console.error('_busIn: unknown body.typ:' + body.typ + ' with body:' + JSON.stringify(body))
        break
    }
  }

  const _otSelStyle = function (fea, res) {
    if (typeof fea === 'undefined' || !fea) { return [] }
    if (typeof fea === 'number') { return [] }
    let ot
    try { ot = fea.get('ot') } catch (e) {
      console.error('Tfea:' + typeof fea)
      console.trace(e)
      console.error('FEA:' + JSON.stringify(fea))
    }
    if (!ot || typeof ot === 'undefined') {
      let raw
      try { raw = fea.get('raw') } catch (ee) { console.trace(ee) }
      if (typeof raw === 'undefined' || !raw) { return [] }
      ot = raw.ot
      if (!ot || typeof ot === 'undefined') { return [] }
    }
    switch (ot) {
      case '0' : return cnxStyle(fea, res)
      case 'a' : return annStyle(fea, res)
      case 'b' : return balStyle(fea, res)
      case 'c' : return thmStyle(fea, res)
      // case 'g' : return guyStyle(fea, res)
      case 'g' : return []
      case 'h' : return hmgStyle(fea, res)
      case 'k' : return thgStyle(fea, res)
      case 'l' : return lanStyle(fea, res)
      case 's' : return sitStyle(fea, res)
      case 't' : return tofStyle(fea, res)
      case 'w' : return wshStyle(fea, res)
      case 'x' : return patStyle(fea, res)
      case 'y' : return chaStyle(fea, res)
      case 'o' : return volStyle(fea, res)
      case 'r' : return runStyle(fea, res)
      case '_d' : return _drwStyle(fea, res)
      case '_i' : return _isoStyle(fea, res)
    }
  }

  // print camera info
  const _cameraPrint = function () {
    if (!glo.m3t) { _stopCamera(); return }
    let ih, e
    e = document.getElementById('m3alt')
    if (e) { ih = 'Alt:' + m3cam.getAltitude().toFixed(4); e.innerHTML = ih }
    e = document.getElementById('m3dst')
    if (e) { ih = 'Dst:' + m3cam.getDistance().toFixed(4); e.innerHTML = ih }
    e = document.getElementById('m3hea')
    if (e) { ih = 'Hea:' + m3cam.getHeading().toFixed(4); e.innerHTML = ih }
    e = document.getElementById('m3tlt')
    if (e) { ih = 'Tlt:' + m3cam.getTilt().toFixed(4); e.innerHTML = ih }
  }

  // en/disable map 3D
  const _set3d = function (onoff, div3id, lat, lgt) {
    if (!onoff) {
      _stopCamera()
      if (m3olc !== null) m3olc.setEnabled(false)
      glo.m3t = false
      return
    }
    if (typeof window.Cesium !== 'undefined') { window.Cesium.Ion.defaultAccessToken = OLCS_ION_TOKEN }
    if (m3olc !== null) { m3olc.setEnabled(true) }
    if (typeof lat === 'undefined') { lat = _getv('lac') }
    if (typeof lgt === 'undefined') { lgt = _getv('lgc') }
    const cent = [lgt, lat]
    if (m3lay === null) {
      m3lay = new TileLayer({
        preload: Infinity,
        isBaseLayer: true
      })
    }
    m3lay.setSource(null)
    m3lay.setSource(getMapSrcByCode(_v.cms).getSource())
    // m3lay.set('altitudeMode', 'clampToGround');
    m3lay.set('altitudeMode', 'none')

    /* KO
controls = ContDef({
rotate: true,
rotateOptions: {
tipLabel: "Reset rotation. \nUse Alt+Shift+Drag to rotate the map."
}
});
*/

    if (m3map === null) {
      m3map = new Map({
        target: div3id,
        projection: uprj,
        layers: [m3lay, lays.o, lays.s, lays.sw, lays.l, lays.t, lays.tw, lays.x, lays.k, lays.c, lays.b, lays['0'], lays._d, lays.h],
        controls: [
          /*
                    new ScaleLine({'className':'pc-scal','units':'metric','minWidth':64}),
                    // new FullScreen({'className':'pc-full','tipLabel':'Plein &Egrave;cran'}),
                    new MousePosition({
                        'projection': uprj,
                        'coordinateFormat': mousePosFormat,
                        'className': 'pc-mouse',
                        'target': document.getElementById('mouse-position')
                    }),
                    new Zoom({'className':'pc-zoom','zoomInTipLabel':'Zoomer','zoomOutTipLabel':'Agrandir'})
*/
        ]
      })
      const selInter = new Select({
        condition: click,
        style: _otSelStyle
      })
      selInter.on('select', function () {
        const fc = selInter.getFeatures()
        if (fc.getLength() < 1) {
          dspDiv('popup', false)
        }
        selInter.getFeatures().forEach(function (elem, idx, arr) {
          featureClicked(elem)
        })
      })
      m3map.addInteraction(selInter)
    } else {
      m3map.setLayers([m3lay, lays.o, lays.s, lays.sw, lays.l, lays.t, lays.tw, lays.x, lays.k, lays.c, lays.b, lays['0'], lays._d, lays.h])
    }
    for (const layer of [lays.o, lays.s, lays.sw, lays.l, lays.t, lays.tw, lays.x, lays.k, lays.c, lays.b, lays['0'], lays._d, lays.h]) {
      m3map.removeLayer(layer)
      layer.setVisible(true)
      m3map.addLayer(layer)
    }

    if (m3vie === null) {
      m3vie = new View({
        projection: vprj,
        zoom: 13,
        minZoom: 9,
        maxZoom: 20,
        enableRotation: false
      })
    }
    m3vie.setCenter(fromLonLat(cent))
    m3map.setView(m3vie)
    m3map.updateSize()
    if (m3olc === null) {
      m3olc = new OLCesium({ map: m3map })
      m3olc.enableAutoRenderLoop()
    }
    if (m3sce === null) {
      m3sce = m3olc.getCesiumScene()
      m3sce.globe.depthTestAgainstTerrain = true
      m3sce.fog.enabled = true
      m3sce.fog.density = 0.0001
      window.Cesium.createWorldTerrainAsync().then((tp) => { m3sce.terrainProvider = tp })
    }

    glo.m3t = onoff
    m3cam = m3olc.getCamera()
    /*
    m3cam.setHeading(0.3);
    m3cam.setTilt(0.8);
    m3cam.setAltitude(5000);
    m3cam.setDistance(100000);
    */
    m3olc.setEnabled(true)
    _startCamera(1000)
  }

  const _setMapSource = function (mscode) {
    const mapsrc = getMapSrcByCode(mscode)
    if (mapsrc) {
      _setv('cms', mscode)
      lays._m.setSource(null)
      lays._m.setSource(mapsrc.getSource())
      if (m3lay !== null) {
        m3lay.setSource(null)
        m3lay.setSource(mapsrc.getSource())
      }
    }
  }

  const _retCenter = function () {
    const oc = glo.oldcenter
    if (oc) {
      view.setCenter(oc)
    }
  }

  const _setCenter = function (a, g) {
    const c = this.tr2map([g, a])
    glo.oldcenter = view.getCenter()
    view.setCenter(c)
  }

  const _setZoom = function (zl) {
    view.setZoom(zl)
  }

  const _initMap = function () {
    if (!objs) { _initObjects() }
    if (!srcs) { _initSources() }
    if (!lays) { _initLayers() }
    if (!view) { _initView() }
    if (!map) {
      map = new Map({
        target: 'pgcmap',
        projection: uprj,
        layers: [],
        controls: [
          new ScaleLine({ className: 'pc-scal', units: 'metric', minWidth: 64 }),
          new FullScreen({ className: 'pc-full', tipLabel: 'Plein &Egrave;cran' }),
          new MousePosition({
            projection: uprj,
            coordinateFormat: mousePosFormat,
            className: 'pc-mouse',
            target: document.getElementById('mouse-position')
          }),
          new Zoom({ className: 'pc-zoom', zoomInTipLabel: 'Zoomer', zoomOutTipLabel: 'Agrandir' })
          //                    new Rotate(),
          //                    new ZoomSlider()
        ]
      })
    }

    for (const layer of _layOrder) {
      if (lays[layer.k] !== null) {
        try {
          map.removeLayer(lays[layer.k])
          map.addLayer(lays[layer.k])
        } catch (err) { console.error('Layer add err:' + layer.k) }
      } else {
        console.error('Layer ' + layer.k + ' inexistent')
      }
    }
    map.setView(view)
    map.on('moveend', viewChanged)
    map.on('click', mapClicked)

    {
      const el = document.getElementById('pgcmap')
      if (el && !glo.mob) {
        const hovInter = new Select({
          condition: pointerMove,
          style: _otSelStyle
        })
        hovInter.on('select', function (evt) {
          if (evt.selected.length > 0) {
            const fea = evt.selected[0]
            // const ot = fea.get('ot')
            // console.debug('hover select '+JSON.stringify(fea));
            // console.debug('hover '+evt.type);
            featureOver(fea)
          }
        })
        map.addInteraction(hovInter)
      }
    }

    {
      const el = document.getElementById('btmenu')
      if (el) {
        el.onclick = function (e) { topMenu() }
      }
    }

    const selInter = new Select({
      condition: click,
      style: _otSelStyle
    })
    selInter.on('select', function () {
      const fc = selInter.getFeatures()
      if (fc.getLength() < 1) {
        dspDiv('popup', false)
      }
      selInter.getFeatures().forEach(function (elem, idx, arr) {
        featureClicked(elem)
      })
    })
    map.addInteraction(selInter)
  }

  const _initGeolocation = function (level) {
    _geolocation = new Geolocation({
      //            tracking: ( _trking.l > 0 ),
      tracking: true,
      trackingOptions: {
        //                enableHighAccuracy: ( level > 1 ),  // enableHighAccuracy must be set to true to have the heading value.
        enableHighAccuracy: true,
        maximumAge: 60000,
        timeout: 40000
      },
      projection: view.getProjection()
    })

    //        _geolocation.setTracking( level > 0 );
    _geolocation.setTracking(true)
    { const p = _geolocation.getPosition()
      if (p) {
        _setv('_pos', _tr2usr(p))
        posChanged('p')
      }
    }

    _geolocation.on('change:position', function () {
      const cpos = _getv('_pos')
      const npos = _tr2usr(_geolocation.getPosition())
      if (!cpos || cpos[0] !== npos[0] || cpos[1] !== npos[1]) { _setv('_pos', npos); posChanged('p') }
    })
    _geolocation.on('change:altitude', function () {
      const calt = _getv('_alt')
      const nalt = _geolocation.getAltitude()
      if (nalt !== null && nalt !== calt) { _setv('_alt', nalt); posChanged('a') }
    })
    _geolocation.on('change:accuracy', function () { _setv('_pac', _geolocation.getAccuracy()) /* posChanged(); */ })
    _geolocation.on('change:altitudeAccuracy', function () { _setv('_aac', _geolocation.getAltitudeAccuracy()) /* posChanged(); */ })
    _geolocation.on('change:heading', function () { _setv('_dir', _geolocation.getHeading() / 0.017453292519943); posChanged('d') })
    _geolocation.on('change:speed', function () { _setv('_spd', _geolocation.getSpeed()); posChanged('s') })

    // handle geolocation error.
    // code 1 : PERMISSION_DENIED
    // code 2 : POSITION_UNAVAILABLE
    // code 3 : TIMEOUT
    _geolocation.on('error', function (error) {
      if (error) {
        switch (error.code) {
          case 1 :
            displayError('Redonne la permission "Localisation"\ndans le navigateur !')
            //                    _setTrack(0);  // _setv('trk', 0);
            break
          case 2 :
            displayError('Position indisponible')
            break
          case 3 :
            displayError('Position lente &agrave; arriver')
            break
          default :
            displayError('Erreur de localisation non reconnue')
            break
        }
      }
    })
  }

  const _setTrkSvcCnx = function () {
    if (_trking && _trking.X === 'A') { window.AndTrkSvc.setCnx(_trking.c) }
  }
  const _setTrkSvcTag = function (tag) {
    if (_trking && _trking.X === 'A') { window.AndTrkSvc.setTag('' + tag) }
  }
  const _setTrkSvcLevel = function (level) {
    if (_trking && _trking.X === 'A') { window.AndTrkSvc.setLevel(level) }
  }

  const _setTrackingLevel = function (level) {
    _setv('trk', level)
    _trking.l = level
    _setTrkSvcLevel(level)
    _send('ss', { trk: level })
  }
  const _setTrackingTag = function (tag) {
    _setv('tag', tag)
    _trking.t = tag
    _setTrkSvcTag(tag)
    _send('ss', { tag: '' + tag })
  }

  const _getTrking = function () {
    return _trking
  }
  const _getTrack = function () {
    return (_trking.l)
  }

  const _setTrack = function (level) {
    if (level < 1) {
      if (_geolocation) { _geolocation.setTracking(false) }
      _setTrackingLevel(0)
      return
    }

    if (!_geolocation) { _initGeolocation(level) }
    _geolocation.setTrackingOptions({
      enableHighAccuracy: (_trking.l > 1), // enableHighAccuracy must be set to true to have the heading value.
      maximumAge: 60000,
      timeout: 30000
    })
    _geolocation.setTracking(true)
    _send('ss', { trk: parseInt(level) },
      function (err, r) {
        if (!err) {
          _setTrkSvcCnx()
          _setTrackingTag(r.body.tag)
          _setTrackingLevel(level)
          updRecBtn(_trking)
        } else {
          console.error('SS err: ' + JSON.stringify(err))
        }
      })
  }

  const _detUnseenNumber = function (ids) {
    let n = 0
    const os = objs.d
    if (os) {
      for (const id of ids) {
        if (!os[id] || !os[id].seen()) { n++ }
      }
    }
    return n
  }
  const _evtUnseenNumber = function (ids) {
    let n = 0
    const os = objs.e
    if (os) {
      for (const id of ids) {
        if (!os[id] || !os[id].seen()) { n++ }
      }
    }
    return n
  }
  const _pmgUnseenNumber = function (ids) {
    let n = 0
    const os = objs.p
    if (os) {
      for (const id of ids) {
        if (!os[id] || !os[id].seen()) { n++ }
      }
    }
    return n
  }
  const _votUnseenNumber = function (ids) {
    let n = 0
    const os = objs.v
    if (os) {
      for (const id of ids) {
        if (!os[id] || !os[id].seen()) { n++ }
      }
    }
    return n
  }
  const _wshUnseenNumber = function (ids) {
    let n = 0
    const os = objs.w
    if (os) {
      for (const id of ids) {
        if (!os[id] || !os[id].seen()) { n++ }
      }
    }
    return n
  }
  const _evtMatchTime = function (ids) {
    let n = 0
    const os = objs.e
    if (os) {
      for (const id of ids) {
        if (os[id]) {
          if (os[id].match(_v.it, _v.fut)) { n++ }
        }
      }
    }
    return n
  }
  const _pmgMatchTime = function (ids) {
    let n = 0
    const os = objs.p
    if (os) {
      for (const id of ids) {
        if (os[id]) {
          if (os[id].match(_v.it, _v.fut)) { n++ }
        }
      }
    }
    return n
  }
  const _votMatchTime = function (ids) {
    let n = 0
    const os = objs.v
    if (os) {
      for (const id of ids) {
        if (os[id]) {
          if (os[id].match(_v.it, _v.fut)) { n++ }
        }
      }
    }
    return n
  }
  const _wshMatchTime = function (ids) {
    let n = 0
    const os = objs.w
    if (os) {
      for (const id of ids) {
        if (os[id]) {
          if (os[id].match(_v.it, _v.fut)) { n++ }
        }
      }
    }
    return n
  }
  const _volMatchAlti = function (ids) {
    let n = 0
    const os = objs.o
    if (os) {
      for (const id of ids) {
        if (os[id]) {
          if (os[id].matchAlti(_v.asu, _v.asl)) { n++ }
        }
      }
    }
    return n
  }

  const _regHandler = function (b) {
    console.info('REGISTERING:' + _getv('sid') + ' ReplySid:' + b.sid)
    for (const k in b) {
      // console.debug('got:' + k + ' ' + b[k])
      _setv(k, b[k])
    }
    if (_trking) {
      // console.debug('trackingService:' + JSON.stringify(_trking))
    }
    _eb.registerHandler('' + _getv('sid'), {},
      function (err, msg) {
        if (err === null) { _busIn(msg.body) }
      }
    )
    initMS()
    _initMap()
    initDnD()
    setDnD(true)
    if (!glo.me.id) { dspLogin() }
  }

  const _initEB = function () {
    if (!_eb) {
      console.info('PGC EB INIT')
      //            if( window.location.hostname === 'localhost' ) {
      //                _eb = new EventBus('https://ol5.larquere.net:443/eventbus');
      //            } else {
      _eb = new EventBus(window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/eventbus')
      //            }
      _eb.enableReconnect(false)
    }

    //    'bSq': parseFloat(localStorage.getItem('bSq')) || 50.0,
    _eb.onopen = function () {
      // const msg = {}
      const msg = {}
      msg.lgc = parseFloat(_getv('lgc'))
      msg.lac = parseFloat(_getv('lac'))
      msg.lgn = parseFloat(_getv('lgn'))
      msg.lan = parseFloat(_getv('lan'))
      msg.lgx = parseFloat(_getv('lgx'))
      msg.lax = parseFloat(_getv('lax'))
      msg.df = parseFloat(_getv('df'))
      msg.zl = parseFloat(_getv('zl'))
      msg.it = parseInt(_getv('it'))
      msg.fut = parseInt(_getv('fut'))
      msg.bz = parseInt(_getv('bz'))
      msg.trk = parseInt(_getv('trk'))
      msg.tag = parseInt(_getv('tag'))
      msg.ze = parseInt(_getv('ze'))
      msg.asl = parseInt(_getv('asl'))
      msg.asu = parseInt(_getv('asu'))
      msg.wt = _getv('wt')
      msg.dec = _getv('dec')
      msg.us = _getv('us')
      msg.ud = _getv('ud')
      msg.lg = _getv('lg')
      msg.yk = _yes.k
      msg.yo = _yes.o
      msg.yr = _yes.r
      msg.yx = _yes.x
      msg.ya = _yes.a
      msg.yy = _yes.y
      msg.yc = _yes.c
      let localSid = _getv('sid') || localStorage.getItem('sid')
      //            if( localSid === null ) { localSid = '/NoSID/'; }
      if (localSid === null) { localSid = -1 }
      try {
        _eb.send('sn', msg, { timeout: 30000, sid: '' + localSid },
          function (err, resp) {
            if (err === null) {
              console.info('SN RESP OK:' + JSON.stringify(resp))
              _regHandler(resp.body)
            } else {
              console.error('SN RESP ERR:' + JSON.stringify(err))
            }
          }
        )
      } catch (e) {
        console.error('Catch:' + e)
      }
      const localYes = localStorage.getItem('yes')
      if (localYes) {
        _yes = JSON.parse(localYes)
        if (!_yes['0']) { _yes['0'] = true }
      } else {
        _keepYes()
      }
    }

    _eb.onclose = function () {
      const sid = _getv('sid')
      _eb = null
      console.info('BUS CLOSED:' + sid)
      setInterval(_initEB, 5000)
    }

    return _eb
  }

  const _startRefresh = function (delMillis) {
    if (_refreshItv !== null) { _stopRefresh() }
    _refreshItv = setInterval(_refreshAll, delMillis || defRefItv)
  }

  const _stopRefresh = function () {
    if (_refreshItv !== null) { clearInterval(_refreshItv) }
    _refreshItv = null
  }

  const _startCamera = function (delMillis) {
    if (_cameraItv !== null) { _stopCamera() }
    _cameraItv = setInterval(_cameraPrint, delMillis || 333)
  }

  const _stopCamera = function () {
    if (_cameraItv !== null) { clearInterval(_cameraItv) }
    _cameraItv = null
  }

  const _checkall = function () {
    if (!objs) { return }
    for (const id in objs.s) { objs.s[id].check() }
    for (const id in objs.t) { objs.t[id].check() }
    for (const id in objs.a) { objs.a[id].check() }
    for (const id in objs.k) { objs.k[id].check() }
    for (const id in objs.y) { objs.y[id].check() }
    for (const id in objs.d) { objs.d[id].check() }
    for (const id in objs.e) { objs.e[id].check() }
    for (const id in objs.i) { objs.i[id].check() }
    for (const id in objs.x) { objs.x[id].check() }
    for (const id in objs.l) { objs.l[id].check() }
    for (const id in objs.p) { objs.p[id].check() }
    for (const id in objs.v) { objs.v[id].check() }
    for (const id in objs.w) { objs.w[id].check() }
  }

  // sensibles à infotime et future
  const _refreshTW = function () {
    if (!srcs) { return }
    // ['b','w','a','h','s','t','0'].forEach(function(code,idx){srcs[code].changed();});
    const sources_ = ['b', 'w', 'a', 'h', 's', 't', '0']
    sources_.forEach((code) => srcs[code].changed())
  }

  // sensibles à altitude (asu/asl)
  const _refreshALT = function () {
    if (!srcs) { return }
    // ['b','w','a','h','s','t','0'].forEach(function(code,idx){srcs[code].changed();});
    const sources_ = ['o']
    sources_.forEach((code) => srcs[code].changed())
  }

  const _refreshAll = function () {
    if (window.AndTrkSvc) { _trking = JSON.parse(window.AndTrkSvc.getStatus()) }
    if (tobereloaded) { location.reload(true) }
    _checkall()
    _retrieveLaters()
    if (!srcs) { return }
    const sources_ = ['sw', 's', 'tw', 't', 'w', 'l', 'a', 'y', 'c', '0', 'b', 'h']
    // sources_.forEach(function(code){srcs[code].changed();});
    sources_.forEach((code) => srcs[code].changed())
  }

  let _eb = null

  const _init = function () {
    if (!_eb) { _eb = _initEB() }
    _startRefresh()
  }

  const _getEB = function () {
    return (_eb)
  }

  const _getMap = function () {
    return (map)
  }

  const _getView = function () {
    return (view)
  }

  const _getGeoloc = function () {
    return (_geolocation)
  }

  const _send = function (adr, msg, cb) {
    // let callback, headers
    if (!_eb) { _eb = _initEB() }
    try { _eb.send(adr, msg, { sid: '' + _getv('sid'), timeout: 10000 }, cb) } catch (err) { console.error('BUS err: ' + err) }
  }

  const _getLater = function (ot, oid) {
    if (typeof oid === 'undefined' || oid === null || oid < 0) return
    if (!_later[ot]) { _later[ot] = [] }
    if (_later[ot].indexOf(oid) < 0) { _later[ot].push(oid) }
  }

  const _retrieveLaters = function () {
    for (const ot in _later) {
      if (_later[ot].length > 0) {
        try {
          // console.debug("GetLaters("+ot+") : "+_later[ot]);
          _eb.send('gl', { ot, id: _later[ot] }, { sid: '' + _getv('sid') },
            function (err, r) {
              if (!err) { _later[ot] = [] }
            }
          )
        } catch (err) { console.error('getList err: ' + err) }
      }
    }
  }

  // contents of prox {
  // "upd": <timeInMillis>,
  // "t": [ {"id":1, "dst":2726}, {"id":5, "dst":2833} ]
  // "s": [ {"id":1, "dst":2650} ]
  // "l": [ {"id":2, "dst":2609} ]
  // };
  const _setProx = function (px) {
    if (px) {
      _proxies = px
      for (const ot in _proxies) {
        for (const n in _proxies[ot]) {
          const oid = _proxies[ot][n].id
          if (!objs[ot][oid]) {
            switch (ot) {
              case 't': getTof(oid); break
//                        case 's': getSit(oid); break;
//                        case 'l': getLan(oid); break;
            }
          }
        }
      }
      _proxies.upd = new Date().getTime()
    }
  }

  const _getClosest = function (ot) {
    if (_proxies && _proxies[ot] && _proxies[ot].length > 0) { return _proxies[ot][0] } else return null
  }

  const _dump = function () {
    _v.forEach(function (element) { console.info(element) })
  }

  const _getPointElevation = function (lat, lgt, divn) {
    if (_eb) {
      _eb.send('ele', { a: lat, g: lgt }, { sid: '' + _getv('sid'), timeout: 10000 },
        function (err, resp) {
          if (err) { return }
          const r = resp.body
          const el = document.getElementById(divn)
          if (!el) { return }
          const ih = '<span title="(' + r.location.lat.toFixed(4) + ',' + r.location.lng.toFixed(4) + ') ' +
                r.resolution.toFixed(0) + 'm">' + r.elevation.toFixed(0) + 'm</span>'
          el.innerHTML = ih
        })
    }
  }

  const _getPointVolumes = function (lat, lgt, divn) {
    if (_eb) {
      _eb.send('vap', { lat: parseFloat(lat), lgt: parseFloat(lgt) }, { sid: '' + _getv('sid'), timeout: 10000 },
        function (err, resp) {
          if (err) { return }
          const el = document.getElementById(divn)
          if (!el) { return }
          let ih = '<table style="font-size:smaller">'
          for (const lvo of resp.body) { getVol(parseInt(lvo.id)) }
          for (const lvo of resp.body) {
            const vol = _getObj('o', parseInt(lvo.id))
            ih += '<tr style="font-size:smaller">'
            if (vol) {
              let len = vol.n.length
              let cut = ''
              if (len > 18) { cut = '...'; len = 15 }
              ih += '<td style="font-size:smaller">' + vol.c + '/' + vol.t + '</td>'
              ih += '<td><u><span id="mcb_o_' + vol.id + '">' + vol.n.substring(0, len) + cut + '</span></u></td>'
              ih += '<td>' + vol.ls + ' / ' + vol.us + '</td>'
            } else {
              ih += '<td>...</td><td><u><span id="mcb_o_' + lvo.id + '">' + lvo.id + '</span></u></td><td>...</td>'
            }
            ih += '</tr>'
          }
          ih += '</table>'
          el.style.display = 'block'
          el.style.visibility = 'visible'
          el.innerHTML = ih
          for (const lvo of resp.body) {
            const vce = document.getElementById('mcb_o_' + lvo.id)
            vce.onclick = function () { objectDisplay('o', lvo.id) }
          }
        })
    }
  }

  return {
    init: _init,
    send: _send,
    layerOn: _layerOn,
    setLayer: _setLayer,
    setOption: _setOption,
    updLayers: _updLayers,
    refreshTW: _refreshTW,
    refreshALT: _refreshALT,
    getEB: _getEB,
    dump: _dump,
    getv: _getv,
    setv: _setv,
    setProx: _setProx,
    getClosest: _getClosest,
    getMap: _getMap,
    getView: _getView,
    tr2map: _tr2map,
    tr2usr: _tr2usr,
    setCenterAG: _setCenter,
    set3d: _set3d,
    retCenter: _retCenter,
    getObjs: _getObjs,
    getObj: _getObj,
    retObj: _retObj,
    getSrcs: _getSrcs,
    getLayer: _getLayer,
    getLater: _getLater,
    getPointElevation: _getPointElevation,
    getPointVolumes: _getPointVolumes,
    detUnseenNumber: _detUnseenNumber,
    evtUnseenNumber: _evtUnseenNumber,
    pmgUnseenNumber: _pmgUnseenNumber,
    votUnseenNumber: _votUnseenNumber,
    wshUnseenNumber: _wshUnseenNumber,
    evtMatchTime: _evtMatchTime,
    pmgMatchTime: _pmgMatchTime,
    votMatchTime: _votMatchTime,
    wshMatchTime: _wshMatchTime,
    volMatchAlti: _volMatchAlti,
    getCurrentMapSourceId: _getCurrentMapSourceId,
    setMapSource: _setMapSource,
    selectObj: _selectObj,
    getSelObjsType: _getSelObjsType,
    getTrack: _getTrack,
    getTrking: _getTrking,
    setTrack: _setTrack,
    setZoom: _setZoom,
    getGeoloc: _getGeoloc
  }
}

export { Pgc }
