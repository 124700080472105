import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'

import { app, glo } from './globo'
import { mylink, noris2soris, oridecorate } from './libra'

const ObjType = 'l'

// landing.id,
// date_part('epoch'::text, landing.ts)::integer AS ts,
// landing.name AS n,
// landing.site AS si,
// pgc_lat(landing.poz) AS a,
// pgc_lgt(landing.poz) AS g,
// pgc_alt(landing.poz) AS t,
// landing.main AS m,
// landing.safety AS s,
// landing.okor AS oo,
// landing.noor AS no,
// landing.comm AS c,
// landing.fed AS f,
// landing.pge AS p,
// landing.fid AS nfi,

// local
const lanImgCache = {}

function lanOneLiner (oid) {
  let lan
  if (typeof oid === 'number') {
    lan = app.getObj(ObjType, oid)
    if (!lan) { getLan(oid); return '' }
  } else { lan = oid }
  return 'Atterro ' + lan.n + ' (#' + lan.id + ')'
}

function lanDisplay (oid) {
  let lan
  if (typeof oid === 'number') {
    lan = app.getObj(ObjType, oid)
    if (!lan) { getLan(oid); return '' }
  } else { lan = oid }
  app.selectObj(ObjType, lan.id)
  let sns; let snf; const sit = app.getObj('s', lan.si)
  if (sit) {
    sns = sit.n
    snf = sit.fn
  } else {
    sns = snf = '#' + lan.si
  }
  let txt = '<fieldset><legend><span id="b_back"></span> &nbsp; <span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Atterrissage ' + lan.n + ' (#' + lan.id + ')</span></legend><table>'
  txt += '<tr><td>du site</td><td><u><span title="' + snf + '" id="go_s_' + lan.si + '">' + sns + '</span></u></td></tr>'
  txt += '<tr><td>Orientations</td><td>' + noris2soris(lan.oo) + '</td></tr>' +
        '<tr><td>...Interdites</td><td>' + noris2soris(lan.no) + '</td></tr>' +
    '<tr><td><span id="mc_' + ObjType + '_' + lan.id + '" style="font-size:large">' + glo.symb.navi.c + '</span> Position</td><td>' + lan.a.toFixed(5) + ' ' + lan.g.toFixed(5) + '&nbsp; Alt:' + lan.t.toFixed(0) + ' m'
  const curpos = app.getv('_pos')
  if (curpos) {
    txt += ' <a target="_blank" href="https://www.google.com/maps/preview/dir/' + curpos[1].toFixed(6) + ',' + curpos[0].toFixed(6) + '/' + lan.a + ',' + lan.g + '/">&lt;=Nav</a>'
  }
  if (lan.m === true) {
    txt += '<tr><td colspan="2">Atterrissage principal</td></tr>'
  }
  if (lan.s === true) {
    txt += '<tr><td colspan="2">Atterrissage de secours</td></tr>'
  }
  if (lan.nfi) {
    txt += '<tr><td>Voir le site sur</td><td><a href="https://intranet.ffvl.fr/sites_pratique/voir/' + lan.nfi + '" target="_blank">F.F.V.L.' + (typeof lan.nfi !== 'undefined' ? ' (' + lan.nfi + ')' : '') + '</a></td></tr>'
  } else
    if (lan.f) {
      txt += '<tr><td>Voir le site sur</td><td><a href="http://federation.ffvl.fr/structure/1/sites/' + lan.f + '" target="_blank">F.F.V.L.' + (typeof lan.f !== 'undefined' ? ' (' + lan.f + ')' : '') + '</a></td></tr>'
    }
  if (lan.p) {
    txt += '<tr><td>Voir le site sur</td><td><a href="http://www.paraglidingearth.com/pgearth/?site=' + lan.p + '" target="_blank">ParaGlidingEarth</a></td></tr>'
  }
  if (lan.c !== null && typeof lan.c !== 'undefined' && lan.c.length > 0) {
    txt += '<tr><td colspan="2"><fieldset>' + mylink(lan.c).replace(/\n|\r/g, '<br/>') + '</fieldset></td></tr>'
  }
  txt += '</table></fieldset>'
  return (txt)
}

function lanLst (list) {
  if (list) list.forEach((ojs) => lanUpd(ojs))
}

function getLan (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { lanUpd(co); return }
  try {
    const eb = app.getEB()
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + app.getv('sid') },
      function (err, r) {
        if (err) { return (null) } else { lanIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetSit err: ' + err) }
}

function lanIns (ojs) {
  const obj = new Landing(ojs)
  const id = obj.id
  prepareLanImg(obj.ik)
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  obj.draw()
  if (!app.getObj('s', obj.si)) { app.getLater('s', obj.si) }
}

function lanUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Landing(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  const sit = app.getObj('s', obj.si)
  if (!sit) { app.getLater('s', obj.si) } else {
    sit.refresh()
  }
}

function lanDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

const lanStyle = function (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const lan = app.getObj(ObjType, f.getId())
  if (!lan || typeof lan === 'undefined') return []
  //    if( ! lan ) { getLan(oid); return ''; }
  // let raw = f.get('raw');
  const key = lan.ik
  let i = lanImgCache[key]
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const cote = Math.round(baseSq * factor)
  if (!i || i === null || typeof i === 'undefined') {
    prepareLanImg(key)
    i = lanImgCache[key]
  }

  if (app.getv('dec') === true) {
    i = oridecorate(i, cote, lan.oo, 0, lan.no)
  }

  const sty = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction', // pixels
      imgSize: [cote, cote],
      img: i
    })
  })
  return [sty]
}

function prepareLanImg (k) {
  if (!lanImgCache[k] || typeof lanImgCache[k] === 'undefined' || lanImgCache[k] === null) {
    lanImgCache[k] = getLanImg(k)
  }
}

// function clearLanImgCache () { lanImgCache = {} }
function getLanKey (lan) {
  let mask = 0
  if (lan.m === true) { mask |= 1 }
  if (lan.s === true) { mask |= 2 }
  lan.ik = ObjType + mask
  return ObjType + mask
}

function getLanImg (key) {
  const k = parseInt(key.substr(1))
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const cote = Math.round(baseSq * factor)
  const epa = cote / 40
  const mai = (k & 1) === 1
  const saf = (k & 2) === 2

  const cnv = document.createElement('canvas')
  cnv.style.width = cote
  cnv.style.height = cote
  cnv.setAttribute('width', cote)
  cnv.setAttribute('height', cote)
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.canvas.width = cote
  ctx.canvas.height = cote

  ctx.lineWidth = epa

  ctx.beginPath()
  ctx.moveTo(cote * 0.25, cote * 0.25)
  ctx.lineTo(cote * 0.50, cote * 0.45)
  ctx.lineTo(cote * 0.75, cote * 0.25)
  ctx.lineTo(cote * 0.50, cote * 0.75)
  ctx.lineTo(cote * 0.25, cote * 0.25)
  ctx.closePath()

  if (saf) {
    ctx.fillStyle = '#ff9900'
  } else {
    ctx.fillStyle = '#00ff00'
  }
  ctx.fill()

  ctx.strokeStyle = '#000'
  ctx.stroke()

  if (mai) {
    ctx.beginPath()
    ctx.moveTo(cote * 0.25, cote * 0.25)
    ctx.lineTo(cote * 0.50, cote * 0.55)
    ctx.lineTo(cote * 0.75, cote * 0.25)
    ctx.lineTo(cote * 0.50, cote * 0.55)
    ctx.lineTo(cote * 0.50, cote * 0.74)
    ctx.closePath()
    ctx.strokeStyle = '#000'
    ctx.lineWidth = 1
    ctx.stroke()
  }

  const img = new Image()
  img.width = cote
  img.height = cote
  img.src = cnv.toDataURL()
  return img
}

class Landing extends BaseObject {
  constructor (ojs) {
    super()
    this.ot = ObjType
    for (const key in ojs) { this[key] = ojs[key] }
    this.ik = getLanKey(ojs)
    this.draw()
  }

  update (ojs) {
    if (!ojs) {
      this.ik = getLanKey(this)
      this.refresh()
    } else {
      let changed = false
      for (const key in ojs) {
        if (this[key] !== ojs[key]) {
          changed = true
          this[key] = ojs[key]
        }
      }
      if (changed) {
        this.ik = getLanKey(ojs)
        this.refresh()
      }
    }
  }

  draw () {
    prepareLanImg(this.ik)
    const fea = new Feature({
      geometry: new Point(app.tr2map([this.g, this.a, this.t]), 'XYZ'),
      //            'raw': this,
      ot: ObjType
    })
    fea.setId(this.id)
    app.getSrcs(ObjType).addFeature(fea)
    //    fea.changed();
    return fea
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) { app.getSrcs(ObjType).removeFeature(fea) }
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  avail () { return (typeof this.av !== 'undefined' && this.av === true) }
  main () { return (typeof this.m !== 'undefined' && this.m === true) }
  safe () { return (typeof this.s !== 'undefined' && this.s === true) }
  check () {
    const sit = app.getObj('s', this.si)
    if (sit) {
      if (!sit.linked(ObjType, this.id)) sit.link(ObjType, this.id)
    } else app.getLater('s', this.si)
  }

  remove () {
    this.undraw()
    app.getObjs(ObjType)[this.id] = null
    delete app.getObjs(ObjType)[this.id]
  }

  getDisplay () { return (lanDisplay(this)) }
  getOneLiner () { return (lanOneLiner(this)) }
}

export { Landing, lanStyle, getLan, lanLst, lanIns, lanUpd, lanDel }
