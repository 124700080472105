import { app } from './globo'
import { timePast } from './libra'

import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Text from 'ol/style/Text'
import Fill from 'ol/style/Fill'
import Polyline from 'ol/format/Polyline'
import Feature from 'ol/Feature'

export function osrm (da, dg) {
  let sa, sg
  const origin = app.getv('_ori')
  const curpos = app.getv('_pos')

  if (origin !== null) {
    sg = origin[1]
    sa = origin[0]
  } else if (curpos) {
    sg = curpos[0]
    sa = curpos[1]
  } else {
    const depart = app.tr2usr(app.getMap().getView().getCenter())
    sg = depart[0]
    sa = depart[1]
  }
  app.send('ro', { sa, sg, da: da * 1.0, dg: dg * 1.0, trj: app.layerOn('_t') },
    function (err, resp) { if (!err) getOsrmResult(resp.body) })
}

export function getOsrmResult (rr) {
  if (rr.code !== 'Ok') { return }
  const dur = rr.rtes[0].duration
  const dst = rr.rtes[0].distance / 1000.0
  const ih = 'trajet: ' + dst.toFixed(3) + 'km / ' + timePast(dur * 1000)
  const e = document.getElementById('osrmdst')
  if (e) {
    e.innerHTML = ih
    e.style.display = 'inline'
    e.style.visibility = 'visible'
  }
  app.getSrcs('_t').clear()
  if (!app.layerOn('_t')) return

  const rcolors = ['#f00', '#00f', '#2f6']
  for (const rn in rr.rtes) {
    const rte = rr.rtes[rn]
    const chemin = new Polyline({
      factor: 1e5
    }).readGeometry(rte.geometry, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    })
    const sty = new Style({
      stroke: new Stroke({ color: rcolors[rn], width: 3 }),
      text: new Text({
        textAlign: 'center',
        textBaseline: 'middle',
        font: 'normal 20px arial',
        align: 'center',
        weight: 'normal',
        text: (rte.distance / 1000).toFixed(3) + ' km \n ' + timePast(rte.duration * 1000),
        fill: new Fill({ color: rcolors[rn] }),
        stroke: new Stroke({ color: '#fff', width: 5 }),
        offsetX: 6,
        offsetY: -6,
        rotation: 0
      })
    })
    const feature = new Feature({
      geometry: chemin
    })
    feature.setStyle(sty)
    app.getSrcs('_t').addFeature(feature)
  }
  app.getSrcs('_t').changed()
}
