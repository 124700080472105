import BaseObject from 'ol/Object'

import { app, glo } from './globo'
import { fd } from './libra'

const ObjType = 'v'

// v.id,
// v.tof AS ti,
// v.guy AS gi,
// date_part('epoch'::text, v.touch) AS ts,
// date_part('epoch'::text, v.day) AS d,
// v.stars AS s,
// v.comm AS c,
// pgc_lat(t.poz) AS a,
// pgc_lgt(t.poz) AS g,
// pgc_alt(t.poz) AS t
// pgc_isseen(,,) AS _s

// local

function _votEdit (vot) {
  const tof = app.getObj('t', vot.ti)
  if (!tof) { app.getLater('t', vot.ti); return '' }
  const dd = new Date(vot.d * 1000)
  const sdd = fd(dd)
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Modifier Vote (#' + vot.id + ')</span></legend><table>'
  txt += '<input type="hidden" id="ih_id" value="' + vot.id + '"/>'
  txt += '<tr><td>D&eacute;collage</td><td><u><span id="go_t_' + tof.id + '">' + tof.n + '</span></u></td></tr>'
  txt += '<tr><td>Le</td><td><input class="date" id="iD_d_' + (vot.d * 1000) + '" value="' + sdd + '" type="date" required></td></tr>'
  txt += '<tr><td>&Eacute;toiles</td><td><input class="star" id="in_s" type="number" value="' + vot.s + '" min="1" max="4"/> / 4</td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_c" cols="30" rows="3" value="' + vot.c + '" placeholder="Description...">' + ((vot.c !== null) ? vot.c : '') + '</textarea></td></tr>'
  //    txt += '<tr><td><span id="de_'+ObjType+'_'+vot.id+'"><img src="/img/cancel_16.png"/> Supprimer</span></td><td align="right"><span id="b_ok" title="OK">Envoi&nbsp;<img src="/img/ok_32.png"/></span></td></tr>';
  txt += '<tr><td><span id="de_' + ObjType + '_' + vot.id + '">' + glo.symb.trash.c + ' Supprimer</span></td><td align="right"><span id="b_ok" title="OK">Envoi&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  // TODO : changement déco
  txt += '</table></fieldset></form>'
  return txt
}

function votNew (tofid) {
  const tof = app.getObj('t', tofid)
  if (!tof) { app.getLater('t', tofid); return '' }
  // let dd = new Date( Date.now() + 86400000 );
  const dd = new Date()
  if (dd.getHours() > glo.tomlimit) dd.setDate(dd.getDate() + 1)
  const sdd = fd(dd)
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Nouveau Vote</span></legend><table>'
  txt += '<input type="hidden" id="ih_tof" value="' + tofid + '"/>'
  txt += '<tr><td>D&eacute;co.</td><td><u><span id="go_t_' + tof.id + '">' + tof.n + '</span></u></td></tr>'
  txt += '<tr><td>Le</td><td><input class="date" id="iD_d" type="date" value="' + sdd + '" required></td></tr>'
  txt += '<tr><td>&Eacute;toiles</td><td><input id="in_s" class="star" type="number" value="3" min="1" max="4"/> / 4</td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_c" cols="30" rows="3" value="" placeholder="Commentaire..."></textarea></td></tr>'
  txt += '<tr><td></td><td align="right"><span id="b_ok" title="OK">Cr&eacute;er&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function votLst (list) {
  if (list) list.forEach((ojs) => votUpd(ojs))
}

function getVot (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { votUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { votIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetVot err: ' + err) }
}

function votIns (ojs) {
  const obj = new Vote(ojs)
  const id = obj.id
  const ti = obj.ti
  const gi = obj.gi
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  if (gi && !app.getObj('g', gi)) { app.getLater('g', gi) }
  const tof = app.getObj('t', ti)
  if (ti && !tof) { app.getLater('t', ti) } else if (!tof.linked(ObjType, id)) {
    tof.link(ObjType, id)
  }
}

function votUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Vote(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  const gi = obj.gi
  if (gi && !app.getObj('g', gi)) { app.getLater('g', gi) }
  const tof = app.getObj('t', obj.ti)
  if (!tof) { app.getLater('t', obj.ti) } else {
    if (!app.getObj('s', tof.si)) { app.getLater('s', tof.si) }
    //        if( ! tof.linked(ObjType,obj.id) ) {
    tof.link(ObjType, obj.id)
    //        }
  }
}

function votDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function votMatch (ojs, i, f) {
  const now = new Date()
  now.setHours(0)
  now.setMinutes(0)
  now.setSeconds(0)
  const pasLimit = new Date(now.getTime() - (i * 3600000))
  const futLimit = new Date(now.getTime() + (f * 86400000))
  futLimit.setHours(23)
  futLimit.setMinutes(59)
  futLimit.setSeconds(59)
  const instant = ojs.d * 1000
  return (instant > pasLimit.getTime() && instant < futLimit.getTime())
}

class Vote extends BaseObject {
  constructor (ojs) {
    super()
    this.ot = ObjType
    for (const key in ojs) { this[key] = ojs[key] }
    if (typeof this._s === 'undefined') this._s = false
    const tof = app.getObj('t', this.ti)
    if (tof) {
      if (!tof.linked(ObjType, this.id)) { tof.link(ObjType, this.id) }
      tof.refresh()
    }
  }

  see (yon) {
    if (typeof this._s === 'undefined' || this._s !== yon) {
      this._s = yon
      this.refresh()
    }
  }

  seen () { return (!!((this._s && this._s === true))) }

  update (ojs) {
    let changed = false
    for (const key in ojs) {
      if (this[key] !== ojs[key]) {
        changed = true
        this[key] = ojs[key]
      }
    }
    if (typeof this._s === 'undefined') this._s = false
    if (changed) {
      const tof = app.getObj('t', this.ti)
      if (tof) {
        if (!tof.linked(ObjType, this.id)) { tof.link(ObjType, this.id) }
      }
      this.refresh()
    }
  }

  match (i, f) {
    return votMatch(this, i, f)
  }

  refresh () {
    const tof = app.getObj('t', this.ti)
    if (tof) tof.refresh()
  }

  check () {
    if (!app.getObj('g', this.gi)) { app.getLater('g', this.gi) }
    const tof = app.getObj('t', this.ti)
    if (tof) { tof.link(ObjType, this.id) } else { app.getLater('t', this.ti) }
  }

  remove () {
    const id = this.id
    const tof = app.getObj('t', this.ti)
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
    if (tof && tof.linked(ObjType, id)) { tof.unlink(ObjType, id) }
  }

  getEdit () { return (_votEdit(this)) }
}

export { Vote, getVot, votLst, votIns, votUpd, votDel, votNew, votMatch }
