import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import LineString from 'ol/geom/LineString'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Text from 'ol/style/Text'
import Fill from 'ol/style/Fill'

import { app, glo } from './globo'
import { objectViewed, mylink, fd/*, fdf */ } from './libra'

const ObjType = 'w'

// w.id,
// w.tof AS ti,
// w.guy AS gi,
// date_part('epoch'::text, w.touch) AS ts,
// date_part('epoch'::text, w.day) AS d,
// w.comm AS c,
// pgc_lat(t.poz) AS a,
// pgc_lgt(t.poz) AS g,
// pgc_alt(t.poz) AS t,
// pgc_lat(w.poz) AS wa,
// pgc_lgt(w.poz) AS wg,
// pgc_alt(w.poz) AS wt
// pgc_isseen(,,) AS _s

// local

function _wshEdit (wsh) {
  const tof = app.getObj('t', wsh.ti)
  if (!tof) { app.getLater('t', wsh.ti); return '' }
  const dd = new Date(wsh.d * 1000)
  const sdd = fd(dd)
  const curpos = app.getv('_pos')
  const origin = app.getv('_ori')
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Modifier Sortie (#' + wsh.id + ')</span></legend><table>'
  txt += '<input type="hidden" id="ih_id" value="' + wsh.id + '"/>'
  txt += '<tr><td>Le</td><td><input id="iD_d_' + (wsh.d * 1000) + '" value="' + sdd + '" type="date" class="date" required></td></tr>'
  txt += '<tr><td colspan="2"><fieldset><legend>Au d&eacute;collage <u><span id="go_t_' + tof.id + '">' + tof.n + '</span></u></legend>'
  txt += '<table><tr><td><span style="font-style:italic">changer<br/>pour</span></td><td><select id="is_ti">'
  txt += '<option selected value="' + tof.id + '">' + tof.n + '</option>'
  const selTofs = app.getSelObjsType('t')
  if (selTofs.length > 0) {
    for (const nti in selTofs) {
      const ti = selTofs[nti]
      const ltof = app.getObj('t', ti)
      if (ltof) {
        const lsit = app.getObj('s', ltof.si)
        txt += '<option value="' + ti + '"><span style="font-size:smaller">' + ltof.n
        if (lsit) { txt += ' (' + lsit.n.substring(0, 10) + ')' }
        txt += '</span></option>'
      }
    }
    txt += '</select></td></tr></table></fieldset></td></tr>'
  }

  txt += '<tr><td colspan="2"><fieldset><legend>D&eacute;part de '
  if (wsh.wa && wsh.wg) {
    txt += wsh.wa.toFixed(4) + ' ' + wsh.wg.toFixed(4)
  } else {
    txt += '<span style="font-style:italic;font-size:smaller">non pr&eacute;cis&eacute;</span>'
  }
  txt += '</legend>'

  txt += '<table><tr><td><span style="font-style:italic">changer<br/>pour</span></td><td>'
  txt += '<input type="radio" id="ir_poz_keep" name="ir_poz" value="keep" checked/>'
  txt += '<label for="ir_poz_keep">garder</label>'
  txt += '<input type="radio" id="ir_poz_none" name="ir_poz" value="none"/>'
  txt += '<label for="ir_poz_none">ne pas pr&eacute;ciser</label><br/>'
  if (curpos !== null) {
    txt += '<input type="hidden" id="ih_poa" value="' + curpos[1] + '"/>'
    txt += '<input type="hidden" id="ih_pog" value="' + curpos[0] + '"/>'
    txt += '<input type="radio" id="ir_poz_pos" name="ir_poz" value="pos"/>'
    txt += '<label for="ir_poz_pos">Position</label>'
  }
  if (origin !== null) {
    txt += '<input type="hidden" id="ih_ora" value="' + origin[0] + '"/>'
    txt += '<input type="hidden" id="ih_org" value="' + origin[1] + '"/>'
    txt += '<input type="radio" id="ir_poz_ori" name="ir_poz" value="ori"/>'
    txt += '<label for="ir_poz_ori">Origine</label>'
  }
  txt += '</td></tr></table></fieldset></td></tr>'

  txt += '<tr><td colspan="2"><textarea id="it_c" cols="30" rows="3" value="' + wsh.c + '" placeholder="Description...">' + ((wsh.c !== null) ? wsh.c : '') + '</textarea></td></tr>'
  txt += '<tr><td><span id="de_' + ObjType + '_' + wsh.id + '">' + glo.symb.trash.c + ' Supprimer</span></td><td align="right"><span id="b_ok" title="OK">Envoi&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function wshNew (tofid) {
  const tof = app.getObj('t', tofid)
  if (!tof) { app.getLater('t', tofid); return '' }
  const dd = new Date()
  if (dd.getHours() > glo.tomlimit) dd.setDate(dd.getDate() + 1)
  const sdd = fd(dd)
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Nouvelle Sortie</span></legend><table>'
  txt += '<tr><td>Le</td><td><input class="date" id="iD_d_0" type="date" value="' + sdd + '" required></td></tr>'
  txt += '<tr><td>D&eacute;collage</td><td><u><span id="go_t_' + tof.id + '">' + tof.n + '</span></u></td></tr>'
  txt += '<tr><td>D&eacute;part de</td><td>'
  const curpos = app.getv('_pos')
  const origin = app.getv('_ori')
  txt += '<input type="hidden" id="ih_tof" value="' + tofid + '"/>'
  txt += '<input type="radio" id="ir_poz_non" name="ir_poz" value="non" checked/>'
  txt += '<label for="ir_poz_non">Nulle part</label>'
  if (curpos !== null) {
    txt += '<input type="hidden" id="ih_poa" value="' + curpos[1] + '"/>'
    txt += '<input type="hidden" id="ih_pog" value="' + curpos[0] + '"/>'
    txt += '<input type="radio" id="ir_poz_pos" name="ir_poz" value="pos" checked/>'
    txt += '<label for="ir_poz_pos">Position</label>'
  }
  if (origin !== null) {
    txt += '<input type="hidden" id="ih_ora" value="' + origin[0] + '"/>'
    txt += '<input type="hidden" id="ih_org" value="' + origin[1] + '"/>'
    txt += '<input type="radio" id="ir_poz_ori" name="ir_poz" value="ori" checked />'
    txt += '<label for="ir_poz_ori">Origine</label>'
  }
  txt += '</td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_c" cols="30" rows="3" value="" placeholder="Commentaire..."></textarea></td></tr>'
  txt += '<tr><td></td><td align="right"><span id="b_ok" title="OK">Cr&eacute;er&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function wshOneLiner (oid) {
  let wsh
  if (typeof oid === 'number') {
    wsh = app.getObj(ObjType, oid)
    if (!wsh) { getWsh(oid); return '' }
  } else { wsh = oid }
  const tof = app.getObj('t', wsh.ti)
  if (!tof) { app.getLater('t', wsh.ti); return '' }
  const guy = app.getObj('g', wsh.gi)
  if (!guy) { app.getLater('g', wsh.gi); return '' }
  const wd = new Date(wsh.d * 1000)
  const od = wd.getDate() + '/' + (wd.getMonth() + 1)
  let na = guy.sn
  const titna = guy.fn + ' ' + guy.ln
  if (!na || na === '') { na = titna }
  const cnt = 'Sortie de ' + na + ' &agrave; ' + tof.n + ' le ' + od
  return cnt
}

function wshDisplay (oid) {
  let wsh
  if (typeof oid === 'number') {
    wsh = app.getObj(ObjType, oid)
    if (!wsh) { getWsh(oid); return '' }
  } else { wsh = oid }
  app.selectObj(ObjType, wsh.id)
  const tof = app.getObj('t', wsh.ti)
  if (!tof) { app.getLater('t', wsh.ti); return '' }
  const guy = app.getObj('g', wsh.gi)
  let na = '...'
  let titna = na
  if (!guy) { app.getLater('g', wsh.gi) } else {
    na = guy.sn
    titna = guy.fn + ' ' + guy.ln
    if (!na || na === '') { na = titna }
  }
  const wd = new Date(wsh.d * 1000)
  const od = wd.getDate() + '/' + (wd.getMonth() + 1)
  let txt = '<fieldset><legend><span id="b_back"></span> &nbsp; <span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Sortie (#' + wsh.id + ')</span></legend><table>'
  txt += '<tr><td>Pilote</td><td><span title="' + titna + '"'
  if ((glo.me.id && glo.me.id === wsh.gi) || glo.me.id === 1) {
    txt += ' id="ed_w_' + wsh.id + '"'
  }
  txt += '>' + na + '</span>'
  if (guy) {
    if (guy.e) {
      txt += ' &nbsp; <a style="font-style:larger" href="mailto:' + guy.e + '?subject=From OPS" target="_blank"><img src="/img/sendmail_16.png"/></a>'
    }
    if (guy.p) {
      txt += ' &nbsp; <a style="font-style:larger" href="tel://' + guy.p + '" target="_blank">' + glo.symb.tel.c + '</a>'
      txt += ' &nbsp; <a style="font-style:larger" href="sms://' + guy.p + '" target="_blank">' + glo.symb.sms.c + '</a>'
    }
  }
  txt += '</td></tr><tr><td>au d&eacute;co.</td><td><u><span id="go_t_' + tof.id + '">' + tof.n + '</span></u></td></tr>'
  txt += '<tr><td>le</td><td>' + od + '</td></tr>'
  txt += '<tr><td colspan="2"><fieldset><p>' + mylink(wsh.c).replace(/\n|\r/g, '<br/>') + '</p></fieldset></td></tr>'
  if (wsh.wa && wsh.wg) {
    txt += '<tr><td>d&eacute;part de</td><td>'
    txt += wsh.wa.toFixed(4) + ' ' + wsh.wg.toFixed(4) // + '&nbsp;<span id="b_cenwsh" style="font-size:large">'+glo.symb.navi.c+'</span>';
    txt += '</td></tr>'
  }
  txt += '<tr><td colspan="2"><span id="us_w_' + wsh.id + '" title="non-vu"><span style="font-size:large"> ' + glo.symb.nonvu.c + ' </span> voir plus tard</span></td></tr>'
  if (glo.me.id) {
    if ((glo.me.id === wsh.gi) || glo.me.id === 1) {
      txt += '<tr><td><span id="de_' + ObjType + '_' + wsh.id + '">' + glo.symb.trash.c + ' Supprimer</span></td>' // &#x2BBF;
      txt += '<td align="right"><span id="ed_' + ObjType + '_' + wsh.id + '">Modifier <span style="font-weight:bold;font-size:150%">' + glo.symb.mod.c + '</span></span></td></tr>'
    }
  }
  txt += '</table></fieldset>'
  objectViewed(ObjType, wsh.id, true)
  return txt
}

function wshLst (list) {
  if (list) list.forEach((ojs) => wshUpd(ojs))
}

function getWsh (oi) {
  const co = app.getObj(ObjType, oi)
  if (co) { wshUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oi, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { wshIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetWsh err: ' + err) }
}

function wshIns (ojs) {
  const obj = new Wish(ojs)
  const id = obj.id
  const ti = obj.ti
  const gi = obj.gi
  //    if( app.getObjs(ObjType)[id] ) { app.getObjs(ObjType)[id].remove(); }
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id] = null }
  app.getObjs(ObjType)[id] = obj
  if (gi && !app.getObj('g', gi)) { app.getLater('g', gi) }
  const tof = app.getObj('t', ti)
  //    if( ti && ! tof ) { getTof(ti); }
  //    else if( ! tof.linked(ObjType,id) ) {
  if (tof) {
    tof.link(ObjType, id)
  }
  obj.draw()
}

function wshUpd (ojs) {
// console.log('WU:'+JSON.stringify(ojs));
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Wish(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  const gi = obj.gi
  if (gi && !app.getObj('g', gi)) { app.getLater('g', gi) }
  const tof = app.getObj('t', obj.ti)
  if (!tof) { app.getLater('t', obj.ti) } else {
    //        if( ! app.getObj('s',tof.si) ) { getSit(tof.si); }
    if (!tof.linked(ObjType, obj.id)) tof.link(ObjType, obj.id)
    tof.refresh()
  }
  obj.draw()
}

function wshDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function wshStyle (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const wsh = app.getObj(ObjType, f.getId())
  if (!wsh || typeof wsh === 'undefined') return []
  let letext = ''
  const wd = new Date(wsh.d * 1000)
  const od = wd.getDate() + '/' + (wd.getMonth() + 1)
  letext += od
  const guy = app.getObj('g', wsh.gi)
  if (guy) {
    letext += '\n' + ((guy.sn) ? guy.sn : guy.fn)
  }
  const nowD = ((new Date().getTime()) / 86400000) | 0 // |0 = conv en int + rapide que parseInt
  const wshD = (wd.getTime() / 86400000) | 0
  const ddn = wshD - nowD
  const dash = ddn + 2
  let strkwidth
  let colors
  if (wsh.seen()) {
    //        colors = ['rgba(192,255,255,.95)', 'rgba(255,255,192,.95)', 'rgba(255,192,255,.95)',
    //                'rgba(255,192,192,.95)', 'rgba(192,255,192,.95)', 'rgba(192,192,255,.95)'];
    colors = ['rgba(0,255,255,.8)', 'rgba(255,255,0,.8)', 'rgba(255,0,255,.8)',
      'rgba(255,0,0,.8)', 'rgba(0,255,0,.8)', 'rgba(0,0,255,.8)']
    strkwidth = 2
  } else {
    colors = ['rgb(0,255,255)', 'rgb(255,255,0)', 'rgb(255,0,255)',
      'rgb(255,0,0)', 'rgb(0,255,0)', 'rgb(0,0,255)']
    strkwidth = 4
  }
  const ci = ddn % colors.length
  const sty = new Style({
    stroke: new Stroke({ color: colors[ci], width: 4, lineDash: [dash, dash, dash - 1, dash + 2], lineDashOffset: ddn }),
    text: new Text({
      textAlign: 'center',
      textBaseline: 'middle',
      font: 'normal 13px arial',
      align: 'center',
      weight: 'normal',
      text: letext,
      fill: new Fill({ color: colors[ci] }),
      stroke: new Stroke({ color: '#000', width: strkwidth }),
      offsetX: 3,
      offsetY: -3,
      rotation: 0
    })
  })
  return [sty]
}

function wshMatch (ojs, i, f) {
  const now = new Date()
  now.setHours(0)
  now.setMinutes(0)
  now.setSeconds(0)
  const pasLimit = new Date(now.getTime() - (i * 3600000))
  const futLimit = new Date(now.getTime() + (f * 86400000))
  futLimit.setHours(23)
  futLimit.setMinutes(59)
  futLimit.setSeconds(59)
  const instant = ojs.d * 1000
  return (instant > pasLimit.getTime() && instant < futLimit.getTime())
}

class Wish extends BaseObject {
  constructor (ojs) {
    super()
    this.ot = ObjType
    for (const key in ojs) { this[key] = ojs[key] }
    if (typeof this._s === 'undefined') this._s = false
    const tof = app.getObj('t', this.ti)
    if (tof) {
      if (!tof.linked(ObjType, this.id)) { tof.link(ObjType, this.id) }
      tof.refresh()
    }
  }

  see (yon) {
    if (typeof this._s === 'undefined' || this._s !== yon) {
      this._s = yon
      this.refresh()
    }
  }

  seen () { return (!!((this._s && this._s === true))) }

  update (ojs) {
    // console.log('Wupdate:'+JSON.stringify(ojs));
    let changed = false
    const oldTi = this.ti || null
    for (const key in ojs) {
      if (!this[key] || this[key] !== ojs[key]) {
        changed = true
        this[key] = ojs[key]
      }
    }
    if (typeof this._s === 'undefined') this._s = false
    if (changed) {
      if (this.ti !== oldTi && oldTi !== null) {
        const oldTof = app.getObj('t', oldTi)
        if (oldTof) {
          if (oldTof.linked(ObjType, this.id)) oldTof.unlink(ObjType, this.id)
          oldTof.refresh()
        }
      }
      const tof = app.getObj('t', this.ti)
      if (tof) {
        if (!tof.linked(ObjType, this.id)) tof.link(ObjType, this.id)
      }
      this.refresh()
    }
  }

  draw () {
    if (!this.a || !this.g || !this.wa || !this.wg) { return }
    if (app.wshMatchTime([this.id]) < 1) { return }
    const fea = new Feature({
      ot: ObjType,
      geometry: new LineString([app.tr2map([this.wg, this.wa]), app.tr2map([this.g, this.a])])
    })
    fea.setId(this.id)
    fea.set('ot', ObjType)
    app.getSrcs(ObjType).addFeature(fea)
    fea.changed()
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) {
      app.getSrcs(ObjType).removeFeature(fea)
      app.getSrcs(ObjType).changed() // refresh ??
    }
  }

  match (i, f) {
    return wshMatch(this, i, f)
  }

  refresh () {
    this.undraw()
    const tof = app.getObj('t', this.ti)
    if (tof) tof.refresh()
    this.draw()
  }

  check () {
    if (!app.getObj('g', this.gi)) { app.getLater('g', this.gi) }
    const tof = app.getObj('t', this.ti)
    if (tof) { tof.link(ObjType, this.id) } else { app.getLater('t', this.ti) }
  }

  remove () {
    this.undraw()
    const id = this.id
    const tof = app.getObj('t', this.ti)
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
    if (tof && tof.linked(ObjType, id)) { tof.unlink(ObjType, id) }
  }

  getEdit () { return (_wshEdit(this)) }
  getDisplay () { return (wshDisplay(this)) }
  getOneLiner () { return (wshOneLiner(this)) }
}

export { Wish, wshStyle, getWsh, wshLst, wshIns, wshUpd, wshDel, wshNew, wshMatch }
