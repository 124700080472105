import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'

import { app, glo } from './globo'

const ObjType = 'y'

// date_part('epoch'::text, chat.ts)::integer AS ts,
// pgc_lat(chat.poz) AS a,
// pgc_lgt(chat.poz) AS g,
// pgc_alt(chat.poz) AS t,
// chat.guy AS gi,
// chat.title AS c,
// chat.lang AS l,

// local
const chaImgCache = {}

function chaNew (lat, lgt) {
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Nouveau Tchat</span></legend><table>'
  txt += '<input type="hidden" id="ih_lat" value="' + lat + '"/>'
  txt += '<input type="hidden" id="ih_lgt" value="' + lgt + '"/>'
  txt += '<tr><td colspan="2"><input id="it_ti" type="text" value="" placeholder="Titre / sujet" maxlength="32"/></td></tr>'
  txt += '<tr><td></td><td align="right"><span id="b_ok" title="OK">Cr&eacute;er&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function chaOneLiner (oid) {
  let cha
  if (typeof oid === 'number') {
    cha = app.getObj(ObjType, oid)
    if (!cha) { getCha(oid); return '' }
  } else { cha = oid }
  const guy = app.getObj('g', cha.gi)
  let na = '...'
  let titna = na
  if (!guy) { app.getLater('g', cha.gi) } else {
    na = guy.sn
    titna = guy.fn + ' ' + guy.ln
    if (!na || na === '') { na = titna }
  }
  const cnt = '&#x2037;' + cha.c + '&#x2034; (' + na + ')'
  return cnt
}

function chaDisplay (oid) {
  let cha
  if (typeof oid === 'number') {
    cha = app.getObj(ObjType, oid)
    if (!cha) { getCha(oid); return '' }
  } else { cha = oid }
  app.selectObj(ObjType, cha.id)
  const guy = app.getObj('g', cha.gi)
  let na = '...'
  let titna = na
  if (!guy) { app.getLater('g', cha.gi) } else {
    na = guy.sn
    titna = guy.fn + ' ' + guy.ln
    if (!na || na === '') { na = titna }
  }
  let txt = '<fieldset><legend><span id="b_back"></span> &nbsp; <span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;&#x2037;' + cha.c + '&#x2034;</span></legend><table>'
  txt += '<tr><td>Initi&eacute;e par </td><td><span title="' + titna + '"'
  if ((glo.me.id && glo.me.id === cha.gi) || glo.me.id === 1) {
    txt += ' id="ed_y_' + cha.id + '"'
  }
  txt += '>' + na + '</span>'
  /* if( resp ) {
        $.each(resp.phs, function(np,ph) {
        let w = new Date(ph.ts*1000);
        let ow = w.getHours()+':'+w.getMinutes();
        let na = ph.sn;
        let titna = ph.fn+' '+ph.ln;
            if( na==undefined || na=='' ) { na=titna; }
            txt += '<tr><td>'+ow+'</td><td><span title="'+titna+'">'+na+'</span></td></tr><tr><td colspan="2">'+mylink(ph.tx).replace(/\n|\r/g,'<br/>')+'</td></tr>';
        });
    } */
  // if( glo.me!=null && glo.me.id === 1 ) { txt += '<tr><td colspan="2">'+__dump(y)+'</td></tr>' }
  txt += '</table></fieldset>'
  return (txt)
}

/* function annDisplay(oid) {
let ann;
    if( typeof oid === "number" ) {
        ann = app.getObj(ObjType, oid);
        if( ! ann ) { _getAnn(oid); return ''; }
    } else { ann = app.getObj(ObjType, oid.id); }
let guy = app.getObj('g', ann.gi);
    if( ! guy ) { getGuy(ann.gi); }
let so = new Date(ann.so*1000);
let d = new Date(ann.ts*1000);
let od = d.getDate()+'/'+(d.getMonth()+1);
let sd = so.getDate()+'/'+(so.getMonth()+1);
let na = guy.sn;
let titna = guy.fn + ' ' + guy.ln;
    if( ! na || na=='' ) { na=titna; }
let txt = '<fieldset><legend>Annonce n&deg;' + ann.id + '</legend><table>'
    + '<tr><td>Pilote</td><td><span title="' +titna+'">'+na+'</span></td></tr>'
    + '<tr><td>Date</td><td>' + od + '</td></tr>'
    + '<tr><td>Expire</td><td>' + sd + '</td></tr>'
    + '<tr><td>Position</td><td>' + ann.a.toFixed(5) + ' ' + ann.g.toFixed(5);
let curpos = app.getv('_pos');
    if( curpos ) {
        txt += ' <a target="_blank" href="https://www.google.com/maps/preview/dir/'+curpos[1].toFixed(6)+','+curpos[0].toFixed(6)+'/'+ann.a+','+ann.g+'/">&lt;=Nav</a>';
    }
    if( ann.tx && ann.tx.length > 0 ) {
        txt += '<tr><td colspan="2">'+mylink(ann.tx).replace(/\n|\r/g,'<br/>')+'</td></tr>';
    }
    if( glo.me.id && glo.me.id === ann.gi ) {
        txt += '<tr><td colspan="2" align="center">Modifiable</td></tr>';
    }
    txt += '</table></fieldset>';
    objectViewed(ObjType, ann.id, true);
    ann.update();
    return(txt);
} */

function chaLst (list) {
  if (list) list.forEach((ojs) => chaUpd(ojs))
}

// function getChas() {
// let jqxhr = $.ajax({
//        url:'/gb/y/'+lan.toFixed(6)+','+lax.toFixed(6)+','+lgn.toFixed(6)+','+lgx.toFixed(6),
//        async:false, timeout:1000
//    });
// let newchas = jqxhr.responseJSON;
//    if( newchas ) {
//        $.each(newchas, function(a, ojs) { prepareChaImg(getChaKey(ojs)); });
//        chaReset();
//        $.each(newchas, function(a, ojs) { chaUpd(ojs); });
//    }
// }

/*
function sndPhr (id, txt) {
  $.ajax({ url: '/u/y_/' + id + '/' + encodeURIComponent(txt), async: true })
}
*/

function chaIns (ojs) {
  const obj = new Chat(ojs)
  const id = obj.id
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  if (obj.gi && !app.getObj('g', obj.gi)) { app.getLater('g', obj.gi) }
  obj.draw()
}

function chaUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Chat(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  if (obj.gi && !app.getObj('g', obj.gi)) { app.getLater('g', obj.gi) }
  obj.draw()
}

function getCha (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { chaUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { chaIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetCha err: ' + err) }
}

function chaDel (obj) {
  // console.debug('ChaDel:' + obj.id)
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function chaStyle (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const cha = app.getObj(ObjType, f.getId())
  if (!cha || typeof cha === 'undefined') return []
  const sty = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction', // || pixels
      opacity: 1.0,
      rotation: 0.0,
      src: '/img/bulbla_80.png' // was chaunseen_64.png
      //        src: '/img/bulvid_64.png',   // was chaseen_64.png
    })
  })
  return [sty]
}

function prepareChaImg (k) {
  if (!chaImgCache[k] || typeof chaImgCache[k] === 'undefined' || chaImgCache[k] === null) {
    chaImgCache[k] = getChaImg(k)
  }
}
// function clearChaImgCache () { chaImgCache = {} }
function getChaKey (cha) {
  const mask = 0
  cha.ik = ObjType + mask
  return ObjType + mask
}
function getChaImg (key) {
  // const k = parseInt(key.substr(1))
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const cote = Math.round(baseSq * factor)

  const cnv = document.createElement('canvas')
  cnv.style.width = cote
  cnv.style.height = cote
  cnv.setAttribute('width', cote)
  cnv.setAttribute('height', cote)
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.canvas.width = cote
  ctx.canvas.height = cote

  const img = new Image()
  img.onload = function () {
    ctx.drawImage(img, cote, cote)
  }
  img.src = '/img/bulbla_80.png' // was chaunseen_64.png
  //    img.src = '/img/bulvid_64.png';  // was chaseen_64.png
  return img
}

class Chat extends BaseObject {
  constructor (ojs) {
    super()
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
    if (!this.seen) this.seen = false
    this.ik = getChaKey(ojs)
  }

  see (yon) {
    if (!this.seen || this.seen !== yon) {
      this.seen = yon
      this.refresh()
    }
  }

  seen () { return (!!((this.seen && this.seen === true))) }

  update (ojs) {
    if (!ojs) {
      this.ik = getChaKey(this)
      this.refresh()
    } else {
      let changed = false
      for (const key in ojs) {
        if (this[key] !== ojs[key]) {
          changed = true
          this[key] = ojs[key]
        }
      }
      if (changed) {
        this.ik = getChaKey(ojs)
        this.refresh()
      }
    }
  }

  draw () {
    prepareChaImg(this.ik)
    const fea = new Feature({
      geometry: new Point(app.tr2map([this.g, this.a])),
      ot: ObjType
    })
    fea.setId(this.id)
    app.getSrcs(ObjType).addFeature(fea)
    fea.changed()
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) app.getSrcs(ObjType).removeFeature(fea)
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  check () {
    if (!app.getObj('g', this.gi)) app.getLater('g', this.gi)
  }

  remove () {
    const id = this.id
    this.undraw()
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
  }

  getDisplay () { return (chaDisplay(this)) }
  getOneLiner () { return (chaOneLiner(this)) }
}

export { Chat, chaStyle, getCha, chaLst, chaIns, chaUpd, chaDel, chaNew }
