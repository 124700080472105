import BaseObject from 'ol/Object'

import { app, glo } from './globo'
import { fd } from './libra'

const ObjType = 'e'

// e.id,
// e.site AS si,
// e.guy AS gi,
// date_part('epoch'::text, e.ts)::integer AS ts,
// date_part('epoch'::text, e.sta)::integer AS sa,
// date_part('epoch'::text, e.sto)::integer AS so,
// e.txt AS tx,
// e.ena AS e,
// e.tit AS ti,
// pgc_lat(s.poz) AS a,
// pgc_lgt(s.poz) AS g,
// pgc_alt(s.poz) AS t,
// pgc_isseen(,,) AS _s

// local
function _evtEdit (evt) {
  const sit = app.getObj('s', evt.si)
  if (!sit) { app.getLater('s', evt.si); return '' }
  const dsa = new Date(evt.sa * 1000)
  const dso = new Date(evt.so * 1000)
  const sdsa = fd(dsa)
  const sdso = fd(dso)
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Modifier &Eacute;v&egrave;nement (#' + evt.id + ')</span></legend><table>'
  txt += '<input type="hidden" id="ih_id" value="' + evt.id + '"/>'
  txt += '<tr><td>Site</td><td><u><span id="go_s_' + sit.id + '">' + sit.n + '</span></u></td></tr>'
  txt += '<tr><td>Du</td><td><input id="iD_sa_' + (evt.sa * 1000) + '" value="' + sdsa + '" type="date" class="date"></td></tr>'
  txt += '<tr><td>Au</td><td><input id="iD_so_' + (evt.so * 1000) + '" value="' + sdso + '" type="date" class="date"></td></tr>'
  txt += '<tr><td>Titre</td><td><input type="text" id="it_ti" value="' + evt.ti + '"/></td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_tx" cols="30" rows="3" value="' + evt.tx + '" placeholder="Description...">' + ((evt.tx !== null) ? evt.tx : '') + '</textarea></td></tr>'
  txt += '<tr><td>Visible</td><td><input id="ic_e" name="ic_e" type="checkbox" value="ena"'
  if (evt.e) { txt += ' checked' }
  txt += '/></td></tr>'
  txt += '<tr><td><span id="de_' + ObjType + '_' + evt.id + '">' + glo.symb.trash.c + ' Supprimer</span></td><td align="right"><span id="b_ok" title="OK">Envoi&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function evtNew (sitid) {
  const sit = app.getObj('s', sitid)
  if (!sit) { app.getLater('s', sitid); return '' }
  const dsa = new Date(Date.now() + (10 * 86400000))
  const dso = new Date(Date.now() + (12 * 86400000))
  const sdsa = fd(dsa)
  const sdso = fd(dso)
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Nouvel &Eacute;v&egrave;nement</span></legend><table>'
  txt += '<input type="hidden" id="ih_sit" value="' + sitid + '"/>'
  txt += '<tr><td>Site</td><td><u><span id="go_s_' + sit.id + '">' + sit.n + '</span></u></td></tr>'
  txt += '<tr><td>Du</td><td><input id="iD_sa_10" type="date" class="date" value="' + sdsa + '"></td></tr>'
  txt += '<tr><td>Au</td><td><input id="iD_so_12" type="date" class="date" value="' + sdso + '"></td></tr>'
  txt += '<tr><td>Titre</td><td><input type="text" id="it_ti"></td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_tx" cols="30" rows="3" id="nw_c" value="" placeholder="Description..."></textarea></td></tr>'
  txt += '<tr><td>Visible</td><td><input id="ic_e" name="ic_e" type="checkbox" value="ena" checked/></td></tr>'
  txt += '<tr><td></td><td align="right"><span id="b_ok" title="OK">Cr&eacute;er&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function evtLst (list) {
  if (list) list.forEach((ojs) => evtUpd(ojs))
}

function getEvt (oi) {
  const co = app.getObj(ObjType, oi)
  if (co) { evtUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oi, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { evtIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetEvt err: ' + err) }
}

function evtIns (ojs) {
  const obj = new Evt(ojs)
  const id = obj.id
  const si = obj.si
  if (si && !app.getObj('s', si)) { app.getLater('s', si) }
  const gi = obj.gi
  if (gi && !app.getObj('g', gi)) { app.getLater('g', gi) }
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  const sit = app.getObj('s', si)
  if (sit) {
    sit.link(ObjType, id)
  }
}

function evtUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Evt(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  const gi = obj.gi
  if (gi && !app.getObj('g', gi)) app.getLater('g', gi)
  const si = obj.si
  if (si && !app.getObj('s', si)) app.getLater('s', si)
  const sit = app.getObj('s', obj.si)
  if (sit) sit.link(ObjType, obj.id)
}

function evtDel (obj) {
  // console.debug('EvtDel:' + obj.id)
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function evtMatch (ojs, i, f) {
  const now = new Date()
  now.setHours(0)
  now.setMinutes(0)
  now.setSeconds(0)
  const pasLimit = new Date(now.getTime() - (i * 3600000))
  const futLimit = new Date(now.getTime() + (f * 86400000))
  futLimit.setHours(23)
  futLimit.setMinutes(59)
  futLimit.setSeconds(59)
  // const start = ojs.sa * 1000
  const stop = ojs.so * 1000
  return (ojs.e && stop > pasLimit.getTime())
}

class Evt extends BaseObject {
  constructor (ojs) {
    super()
    this.ot = ObjType
    for (const key in ojs) { this[key] = ojs[key] }
    if (typeof this._s === 'undefined') this._s = false
    const sit = app.getObj('s', this.si)
    if (sit) {
      if (!sit.linked(ObjType, this.id)) sit.link(ObjType, this.id)
      sit.refresh()
    }
  }

  see (yon) {
    if (typeof this._s === 'undefined' || this._s !== yon) {
      this._s = yon
      this.refresh()
    }
  }

  seen () { return (!!((this._s && this._s === true))) }

  update (ojs) {
    let changed = false
    for (const key in ojs) {
      if (this[key] !== ojs[key]) {
        changed = true
        this[key] = ojs[key]
      }
    }
    if (typeof this._s === 'undefined') this._s = false
    if (changed) {
      const sit = app.getObj('s', this.si)
      if (sit) {
        if (!sit.linked(ObjType, this.id)) sit.link(ObjType, this.id)
      }
      this.refresh()
    }
  }

  match (i, f) {
    return evtMatch(this, i, f)
  }

  refresh () {
    const sit = app.getObj('s', this.si)
    if (sit) sit.refresh()
  }

  check () {
    if (!app.getObj('g', this.gi)) app.getLater('g', this.gi)
    const sit = app.getObj('s', this.si)
    if (sit) sit.link(ObjType, this.id); else app.getLater('s', this.si)
  }

  remove () {
    const id = this.id
    const sit = app.getObj('s', this.si)
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
    if (sit && sit.linked(ObjType, id)) sit.unlink(ObjType, id)
  }

  getEdit () { return (_evtEdit(this)) }
}

export { Evt, getEvt, evtLst, evtIns, evtUpd, evtDel, evtNew, evtMatch }
