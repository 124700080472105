import BaseObject from 'ol/Object'

import { app, glo } from './globo'

const ObjType = 'd'

// d.id,
// date_part('epoch'::text, d.touch)::integer AS ts,
// d.site AS si,
// d.guy AS gi,
// pgc_lat(s.poz) AS a,
// pgc_lgt(s.poz) AS g,
// pgc_alt(s.poz) AS t,
// d.mt AS m,
// d.txt AS tx,
// d.url AS u,
// d.vrs AS v,
// pgc_isseen(,,) AS _s

// local
function _detEdit (det) {
  const sit = app.getObj('s', det.si)
  if (!sit) { app.getLater('s', det.si); return '' }
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Modifier D&eacute;tail (#' + det.id + ')</span></legend><table>'
  txt += '<input type="hidden" id="ih_id" value="' + det.id + '"/>'
  txt += '<tr><td>Site</td><td><u><span id="go_s_' + sit.id + '">' + sit.n + '</span></u></td></tr>'

  txt += '<tr><td>Media type</td><td><input type="text" id="it_m" value="' + det.m + '" size="1" maxlength="1"/></td></tr>'
  txt += '<tr><td>URL</td><td><input type="text" id="it_u" value="' + det.u + '" size="30" maxlength="128"/></td></tr>'

  txt += '<tr><td colspan="2"><textarea id="it_t" maxlength="512" cols="42" rows="4" value="' + det.tx + '" placeholder="Texte...">' + ((det.tx !== null) ? det.tx : '') + '</textarea></td></tr>'
  txt += '<tr><td><span id="de_' + ObjType + '_' + det.id + '">' + glo.symb.trash.c + ' Supprimer</span></td><td align="right"><span id="b_ok" title="OK">Envoi&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function detNew (sitid) {
  const sit = app.getObj('s', sitid)
  if (!sit) { app.getLater('s', sitid); return '' }
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Nouveau D&eacute;tail</span></legend><table>'
  txt += '<input type="hidden" id="ih_sit" value="' + sitid + '"/>'
  txt += '<tr><td>Site</td><td><u><span id="go_s_' + sit.id + '">' + sit.n + '</span></u></td></tr>'
  txt += '<tr><td colspan="2"><textarea id="it_t" cols="20" rows="3" value="" placeholder="Description..."></textarea></td></tr>'
  txt += '<tr><td></td><td align="right"><span id="b_ok" title="OK">Cr&eacute;er&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function detLst (list) {
  if (list) list.forEach((ojs) => detUpd(ojs))
}

function getDet (oi) {
  const co = app.getObj(ObjType, oi)
  if (co) { detUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oi, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { detIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetDet err: ' + err) }
}

function detIns (ojs) {
  const obj = new Det(ojs)
  const id = obj.id
  const si = obj.si
  const gi = obj.gi
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  if (gi && !app.getObj('g', gi)) { app.getLater('g', gi) }
  const sit = app.getObj('s', si)
  if (sit) {
    sit.link(ObjType, id)
  }
}

function detUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj && typeof obj !== 'undefined') {
    obj.update(ojs)
  } else {
    obj = new Det(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  const gi = obj.gi
  if (gi && typeof gi !== 'undefined' && !app.getObj('g', gi)) app.getLater('g', gi)
  const sit = app.getObj('s', obj.si)
  if (!sit) app.getLater('s', obj.si); else
    if (!sit.linked(ObjType, obj.id)) sit.link(ObjType, obj.id)
}

function detDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

class Det extends BaseObject {
  constructor (ojs) {
    super()
    this.ot = ObjType
    for (const key in ojs) { this[key] = ojs[key] }
    if (this._s && typeof this._s === 'undefined') this._s = false
    const sit = app.getObj('s', this.si)
    if (sit && typeof sit !== 'undefined') {
      if (!sit.linked(ObjType, this.id)) sit.link(ObjType, this.id)
      sit.refresh()
    }
  }

  see (yon) {
    if (this._s && (typeof this._s === 'undefined' || this._s !== yon)) {
      this._s = yon
      this.refresh()
    }
  }

  seen () { return (!!((typeof this._s !== 'undefined' && this._s === true))) }

  update (ojs) {
    let changed = false
    for (const key in ojs) {
      if (this[key] !== ojs[key]) {
        changed = true
        this[key] = ojs[key]
      }
    }
    if (typeof this._s === 'undefined') this._s = false
    if (changed) {
      const sit = app.getObj('s', this.si)
      if (sit && typeof sit !== 'undefined') {
        if (!sit.linked(ObjType, this.id)) sit.link(ObjType, this.id)
      }
      this.refresh()
    }
  }

  check () {
    if (!app.getObj('g', this.gi)) app.getLater('g', this.gi)
    const sit = app.getObj('s', this.si)
    if (sit && typeof sit !== 'undefined') sit.link(ObjType, this.id); else app.getLater('s', this.si)
  }

  refresh () {
    //    let fea = app.getSrcs(ObjType).getFeatureById(this.id);
    //        if( fea ) fea.changed();
    const sit = app.getObj('s', this.si)
    if (sit && typeof sit !== 'undefined') sit.refresh()
  }

  remove () {
    const id = this.id
    const sit = app.getObj('s', this.si)
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
    if (sit && typeof sit !== 'undefined' && sit.linked(ObjType, id)) sit.unlink(ObjType, id)
  }

  getEdit () { return (_detEdit(this)) }
}

export { Det, getDet, detLst, detIns, detUpd, detDel, detNew }
