import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'

import { app, glo } from './globo'
import { mylink, /* fd, fdf, */ timePast } from './libra'

const ObjType = 'h'

const helps = { // Voir C.java
  80: 'G&eacute;n&eacute;rale',
  81: 'Bless&eacute;.e',
  82: 'Branch&eacute;.e',
  83: 'Perdu.e',
  84: 'R&eacute;cup&apos;'
} // => hmg.hc (.h)
function hmgGetCodes () { return helps }

// local
const hlpImgCache = {}

// hmsg.id,
// date_part('epoch'::text, hmsg.sent)::integer AS ts,
// hmsg.src AS gi,
// pgc_lat(hmsg.poz) AS a,
// pgc_lgt(hmsg.poz) AS g,
// pgc_alt(hmsg.poz) AS t,
// hmsg.hc AS h,
// hmsg.txt AS tx,
// hmsg.lp AS l, // loc provider
// hmsg.np AS n, // net provider
// hmsg.poz AS pz

function _hmgEdit (hmg) {
  // const guy = app.getObj('g', hmg.gi)
  const curpos = app.getv('_pos')
  const origin = app.getv('_ori')
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Modifier S.O.S. (#' + hmg.id + ')</span></legend><table>'
  txt += '<input type="hidden" id="ih_id" value="' + hmg.id + '"/>'
  txt += '<input type="hidden" id="ih_hc" value="' + hmg.h + '"/>'

  txt += '<tr><td>Type</td><td><select id="is_hc" name="is_hc">'
  for (const hn in helps) {
    txt += '<option value="' + hn + '"'
    if (hmg.h === parseInt(hn)) { txt += ' selected' }
    txt += '>' + helps[hn] + '</option>'
  }
  txt += '</select></td></tr>'

  txt += '<tr><td><table><tr><td><span style="font-style:italic">changer<br/>endroit<br/>pour</span></td><td>'
  txt += '<input type="radio" id="ir_poz_keep" name="ir_poz" value="keep" checked/>'
  txt += '<label for="ir_poz_keep">garder</label><br/>'

  if (curpos !== null) {
    txt += '<input type="hidden" id="ih_poa" value="' + curpos[1] + '"/>'
    txt += '<input type="hidden" id="ih_pog" value="' + curpos[0] + '"/>'
    txt += '<input type="radio" id="ir_poz_pos" name="ir_poz" value="pos"/>'
    txt += '<label for="ir_poz_pos">Position</label>'
  }
  if (origin !== null) {
    txt += '<input type="hidden" id="ih_ora" value="' + origin[0] + '"/>'
    txt += '<input type="hidden" id="ih_org" value="' + origin[1] + '"/>'
    txt += '<input type="radio" id="ir_poz_ori" name="ir_poz" value="ori"/>'
    txt += '<label for="ir_poz_ori">Origine</label>'
  }
  txt += '</td></tr></table></fieldset></td></tr>'

  txt += '<tr><td colspan="2"><textarea id="it_tx" cols="30" rows="3" value="' + hmg.tx + '" placeholder="détails / explications">' + ((hmg.tx !== null) ? hmg.tx : '') + '</textarea></td></tr>'
  txt += '<tr><td><span id="de_' + ObjType + '_' + hmg.id + '">' + glo.symb.trash.c + ' Supprimer</span></td><td align="right"><span id="b_ok" title="OK">Envoi&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function hmgDisplay (oid) {
  let hmg
  if (typeof oid === 'number') {
    hmg = app.getObj(ObjType, oid)
    if (!hmg) { getHmg(oid); return '' }
  } else { hmg = oid }
  app.selectObj(ObjType, hmg.id)
  const d = new Date(hmg.ts * 1000)
  const od = (d.getDate() < 10 ? '0' : '') + d.getDate() +
    '/' + (d.getMonth() < 9 ? '0' : '') + (d.getMonth() + 1) +
    ' ' + (d.getHours() < 10 ? '0' : '') + d.getHours() +
    ':' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
  const elapsed = timePast(Math.round(Date.now() - d))
  const guy = app.getObj('g', hmg.gi)
  let na = guy.sn
  const titna = guy.fn + ' ' + guy.ln
  if (!na || na === '') { na = titna }
  let txt = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;S.O.S. n&deg;' + hmg.id + '</span></legend><table>'
  txt += '<tr><td>Pilote</td><td><span title="' + titna + '">' + na + '</span></td></tr>'
  txt += '<tr><td colspan="2" align="center" style="font-weight:bold">' + helps[hmg.h] + '</td></tr>'
  txt += '<tr><td>Date</td><td>' + od + ' ( <span style="font-weight:bold">' + elapsed + '</span> )</td></tr>'
  txt += '<tr><td>Téléphone</td><td><a style="font-style:larger" href="tel://' + guy.p + '" target="_blank">' + glo.symb.tel.c + '</a> &nbsp; <a style="font-style:larger" href="sms://' + guy.p + '" target="_blank">' + glo.symb.sms.c + '</a> &nbsp; ' + guy.p + '</td></tr>'
  txt += '<tr><td>Email</td><td>' + mylink(guy.e) + '</td></tr>'
  txt += '<tr><td>Position</td><td>' + hmg.a.toFixed(6) + ' ' + hmg.g.toFixed(6)
  const curpos = app.getv('_pos')
  if (curpos) {
    txt += ' <a target="_blank" href="https://www.google.com/maps/preview/dir/' + curpos[1].toFixed(6) + ',' + curpos[0].toFixed(6) + '/' + hmg.a + ',' + hmg.g + '/">&lt;=Nav</a>'
  }
  if (hmg.tx && hmg.tx.length > 0) {
    txt += '<tr><td colspan="2" align="center" style="font-weight:bold">' + mylink(hmg.tx).replace(/\n|\r/g, '<br/>') + '</td></tr>'
  }
  if (glo.me.id && (glo.me.id === hmg.gi)) {
    txt += '<tr><td><span id="de_' + ObjType + '_' + hmg.id + '">' + glo.symb.trash.c + ' Supprimer</span></td>' // &#x2BBF;
    txt += '<td align="right"><span id="ed_' + ObjType + '_' + hmg.id + '">Modifier <span style="font-weight:bold;font-size:150%">' + glo.symb.mod.c + '</span></span></td></tr>'
  }
  txt += '</table></fieldset>'
  return (txt)
}

function hmgOneLiner (oid) {
  let hmg
  if (typeof oid === 'number') {
    hmg = app.getObj(ObjType, oid)
    if (!hmg) { getHmg(oid); return '' }
  } else hmg = app.getObj(ObjType, oid.id)
  let guy = null
  if (hmg.gi) {
    guy = app.getObj('g', hmg.gi)
    if (!guy) app.getLater('g', hmg.gi)
  }
  const d = new Date(hmg.ts * 1000)
  const elapsed = timePast(Math.round(Date.now() - d))
  let na = '...'
  let titna = na
  if (guy) {
    na = guy.sn
    titna = guy.fn + ' ' + guy.ln
  }
  if (!na || na === '') { na = titna }
  const cnt = 'S.O.S. "' + helps[hmg.h] + '" de ' + na + ' (' + elapsed + ')'
  return cnt
}

// function hmgDrawAll () { $.each(app.getObjs(ObjType), function (h, obj) { obj.draw() }) }

function hmgLst (list) {
  if (list) list.forEach((ojs) => hmgUpd(ojs))
}

function hmgIns (ojs) {
  const obj = new Help(ojs)
  _prepareHmgImg(obj.ik)
  app.getObjs(ObjType)[obj.id] = obj
  if (obj.gi && !app.getObj('g', obj.gi)) { app.getLater('g', obj.gi) }
  obj.draw()
}

function hmgUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Help(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  _prepareHmgImg(obj.ik)
  app.getObjs(ObjType)[obj.id] = obj
  if (obj.gi && !app.getObj('g', obj.gi)) { app.getLater('g', obj.gi) }
  obj.draw()
}

function hmgDel (ojs) {
  const obj = app.getObj('h', ojs.id)
  if (obj) {
    obj.remove()
  }
}

function getHmg (oid) {
  const hmg = app.getObj(ObjType, oid)
  if (hmg) { hmgUpd(hmg); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { hmgIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetHmg err: ' + err) }
}

function hmgStyle (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const hmg = app.getObj(ObjType, f.getId())
  if (!hmg || typeof hmg === 'undefined') return []
  // const k = hmg.ik

  let ait = app.getv('it')
  if (ait < 4) ait = 4
  const itmillis = ait * 3600000
  const dcts = new Date(hmg.ts * 1000)
  const agemillis = Date.now() - dcts.getTime()
  if (agemillis > itmillis) { return null }
  const pct = 1.03 - (agemillis / itmillis)

  return [new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction', // || pixels
      opacity: pct, // 1.,
      rotation: 0.0,
      /* src: _getHmgImg(k) */
      src: '/img/bugred.png'
    })
  })]
}

function _prepareHmgImg (k) {
  if (!hlpImgCache[k] || typeof hlpImgCache[k] === 'undefined' || hlpImgCache[k] === null) {
    hlpImgCache[k] = _getHmgImg(k)
  }
}
// function _clearHmgImgCache () { hlpImgCache = {} }

function _getHmgImg (key) {
  // const k = parseInt(key.substr(1))
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const cote = Math.round(baseSq * factor)

  const cnv = document.createElement('canvas')
  cnv.style.width = cote
  cnv.style.height = cote
  cnv.setAttribute('width', cote)
  cnv.setAttribute('height', cote)
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.canvas.width = cote
  ctx.canvas.height = cote

  const img = new Image()
  img.onload = function () { ctx.drawImage(img, cote, cote) }
  img.src = '/img/bugred.png'
  return img
}

class Help extends BaseObject {
  constructor (ojs) {
    super()
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
    this.ik = 'h0'
    this.draw()
  }

  update (ojs) {
    let changed = false
    if (!ojs) {
      this.ik = 'h0'
      changed = true
    } else {
      for (const key in ojs) {
        if (typeof this[key] === 'undefined' || this[key] !== ojs[key]) {
          changed = true
          this[key] = ojs[key]
        }
      }
    }
    if (changed === true) {
      this.refresh()
    }
  }

  draw () {
    _prepareHmgImg(this.ik)
    const fea = new Feature({
      geometry: new Point(app.tr2map([this.g, this.a, this.t]), 'XYZ'),
      ot: ObjType
    })
    fea.setId(this.id)
    fea.set('ot', ObjType)
    app.getSrcs(ObjType).addFeature(fea)
    fea.changed()
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) app.getSrcs(ObjType).removeFeature(fea)
  }

  refresh () {
    this.undraw()
    this.draw()
  }

  check () {
    if (this.gi && !app.getObj('g', this.gi)) app.getLater('g', this.gi)
  }

  remove () {
    const id = this.id
    this.undraw()
    app.getObjs(ObjType)[id] = null
    delete app.getObjs(ObjType)[id]
  }

  getEdit () { return (_hmgEdit(this)) }
  getDisplay () { return (hmgDisplay(this)) }
  getOneLiner () { return (hmgOneLiner(this)) }
}

export { Help, hmgStyle, getHmg, hmgLst, hmgIns, hmgUpd, hmgDel, hmgGetCodes }
