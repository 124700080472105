import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'

import { app, glo } from './globo'
import { fdf } from './libra'

const ObjType = 'c'

// thermic.id,
// thermic.name AS n,
// pgc_lat(thermic.poz) AS a,
// pgc_lgt(thermic.poz) AS g,
// pgc_alt(thermic.poz) AS t,
// date_part('epoch'::text, thermic.touch)::integer AS ts,
// thermic.kk7 AS k,

// local
const thmImgCache = {}

function thmOneLiner (oid) {
  let thm
  if (typeof oid === 'number') {
    thm = app.getObj(ObjType, oid)
    if (!thm) { getThm(oid); return '' }
  } else { thm = oid }
  let na = thm.n
  if (!na) { na = '' }
  const cnt = 'Thermique ' + na
  return cnt
}

function thmDisplay (oid) {
  let thm
  if (typeof oid === 'number') {
    thm = app.getObj(ObjType, oid)
    if (!thm) { getThm(oid); return '' }
  } else { thm = oid }
  app.selectObj(ObjType, thm.id)
  let na = thm.n
  if (!na) { na = '' }
  const d = new Date(thm.ts * 1000)
  const od = fdf(d)
  let txt = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Thermique ' + na + ' (#' + thm.id + ')</span></legend><table>' +
    '<tr><td>Position</td><td>' + thm.a.toFixed(5) + ' ' + thm.g.toFixed(5)
  const curpos = app.getv('_pos')
  if (curpos) {
    txt += ' <a target="_blank" href="https://www.google.com/maps/preview/dir/' + curpos[1].toFixed(6) + ',' + curpos[0].toFixed(6) + '/' + thm.a + ',' + thm.g + '/">&lt;=Nav</a>'
  }
  txt += '<tr><td>M&agrave;J</td><td>' + od + '</td></tr></table></fieldset>'
  return (txt)
}

function thmLst (list) {
  if (list) list.forEach((ojs) => thmUpd(ojs))
}

function thmIns (ojs) {
  const obj = new Thermic(ojs)
  const id = obj.id
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  obj.draw()
}

function thmUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Thermic(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  obj.draw()
}

function getThm (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { thmUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { thmIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetThm err: ' + err) }
}

function thmDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function thmStyle (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const thm = app.getObj(ObjType, f.getId())
  if (!thm || typeof thm === 'undefined') return []
  // let raw = f.get('raw');
  const sty = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction', // || pixels
      opacity: 1.0,
      rotation: 0.0,
      src: '/img/sk_therm_64.png'
    })
  })
  return [sty]
}

function prepareThmImg (k) {
  if (!thmImgCache[k] || typeof thmImgCache[k] === 'undefined' || thmImgCache[k] === null) {
    thmImgCache[k] = getThmImg(k)
  }
}
// function clearThmImgCache () { thmImgCache = {} }
function getThmKey (thm) {
  const mask = 0
  thm.ik = ObjType + mask
  return ObjType + mask
}
function getThmImg (key) {
// TODO: UTILISER UN CACHE (Problème avec src vs. img dans le style)
  // const k = parseInt(key.substr(1))
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const cote = Math.round(baseSq * factor)

  const cnv = document.createElement('canvas')
  cnv.style.width = cote
  cnv.style.height = cote
  cnv.setAttribute('width', cote)
  cnv.setAttribute('height', cote)
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.canvas.width = cote
  ctx.canvas.height = cote

  const img = new Image()
  img.onload = function () { ctx.drawImage(img, cote, cote) }
  img.src = '/img/sk_therm_64.png'
  return img
}

class Thermic extends BaseObject {
  constructor (ojs) {
    super()
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
    this.ik = getThmKey(ojs)
  }

  update (ojs) {
    if (!ojs) {
      this.ik = getThmKey(this)
      this.refresh()
    } else {
      let changed = false
      for (const key in ojs) {
        if (this[key] !== ojs[key]) {
          changed = true
          this[key] = ojs[key]
        }
      }
      if (changed) {
        this.ik = getThmKey(ojs)
        this.refresh()
      }
    }
  }

  draw () {
    prepareThmImg(this.ik)
    const fea = new Feature({
      geometry: new Point(app.tr2map([this.g, this.a, this.t]), 'XYZ'),
      //        'raw': this,
      ot: ObjType
    })
    fea.setId(this.id)
    app.getSrcs(ObjType).addFeature(fea)
    fea.changed()
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea !== null) app.getSrcs(ObjType).removeFeature(fea)
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  remove () {
    this.undraw()
    app.getObjs(ObjType)[this.id] = null
    delete app.getObjs(ObjType)[this.id]
  }

  getDisplay () { return (thmDisplay(this)) }
  getOneLiner () { return (thmOneLiner(this)) }
}

export { Thermic, thmStyle, getThm, thmLst, thmIns, thmUpd, thmDel }
