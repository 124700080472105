import LineString from 'ol/geom/LineString'
import MultiLineString from 'ol/geom/MultiLineString'

import { glo, app } from './globo'
import { geojformat, setDnD, getMapSources, drawIsolated } from './carte'
import { nominatim, findObjectsByText, timePast, setFriend, fdf, objectViewed, allObjectViewed } from './libra'

import { hmgGetCodes } from './hmg'
import { detNew } from './det'
import { evtNew } from './evt'
import { pmgNew } from './pmg'
import { infNew } from './inf'
import { votNew } from './vot'
import { wshNew } from './wsh'

// to store current timeouts
const _tmout = {}

/* function _dobPrint() {
    glo.dispObjStack.forEach(function(x,i) {
console.info('||DOB#'+i+' '+x.ot+'#'+x.oi);
    });
} */
function _dobPush (ot, oi) {
  glo.dispObjStack.push({ ot, oi })
}
function _dobPop () {
  return glo.dispObjStack.pop()
}
function _dobNext () {
  return glo.dispObjStack.length > 0
}
function _dobPeek () {
  return glo.dispObjStack[glo.dispObjStack.length - 1]
}

function _whSymbol () {
  let ih = '<table style="font-size:150%;">'
  for (const sy in glo.symb) {
    // let code = ''
    // if (glo.symb[sy].c.startsWith('&')) code = glo.symb[sy].c.replace(/&|#|x|;/ig, '')
    ih += '<tr><td>' + glo.symb[sy].c + '&nbsp;</td>' // <td style="font-size:smaller;">'+code+'</td>';
    ih += '<td>&nbsp;' + glo.symb[sy].l + '</td></tr>'
  }
  ih += '</table>'
  return ih
}

function _whGlossa () {
  let ih = '<table style="font-size:130%;"><tr><td colspan="2">les objets suivis d&apos;un ' + glo.symb.add.c + ' peuvent &ecirc;tre cr&eacute;&eacute;s par le pilote</td></tr>'
  for (const te in glo.term) {
    ih += '<tr><td><span style="font-size:120%;font-weight:bold">' + glo.term[te].t + '</span> : ' + glo.term[te].p + '</td><td>'
    if (glo.term[te].c === true) ih += glo.symb.add.c
    ih += '</td></tr>'
  }
  ih += '</table>'
  return ih
}

function _whLegendS () {
  let ih = '<table style="font-size:150%;">'
  for (const le in glo.legs) {
    ih += '<tr><td><img src="' + glo.legs[le].u + '" width="42"/></td><td>' + glo.legs[le].l + '</td></tr>'
  }
  ih += '</table>'
  return ih
}

function _whLegendTl () {
  let ih = '<table style="font-size:150%;">'
  for (const le in glo.legtl) {
    ih += '<tr><td><img src="' + glo.legtl[le].u + '" width="42"/></td><td>' + glo.legtl[le].l + '</td></tr>'
  }
  ih += '</table>'
  return ih
}

function _whLegendC () {
  let ih = '<table style="font-size:150%;">'
  for (const le in glo.legc) {
    ih += '<tr><td><img src="' + glo.legc[le].u + '" width="42"/></td><td>' + glo.legc[le].l + '</td></tr>'
  }
  ih += '</table>'
  return ih
}

const helpPages = [
  { t: 'Symboles', f: _whSymbol },
  { t: 'Sites', f: _whLegendS },
  { t: 'D&eacute;co/Atterro', f: _whLegendTl },
  { t: 'Pilotes', f: _whLegendC },
  { t: 'Glossaire', f: _whGlossa }
]

function _displayAide (np) {
  const e = document.getElementById('popup')
  if (!e) return
  if (!np || np < 0) np = 0
  if (np >= helpPages.length) np = (helpPages.length) - 1
  let ih = '<fieldset><legend><span id="b_cl" style="font-size:130%">' + glo.symb.close.c + ' &nbsp; </span>'
  ih += '<span style="font-size:130%">' + helpPages[np].t + '</span>'
  if (np > 0) { ih += ' &nbsp; <span id="b_pa_' + (np - 1) + '" style="font-size:160%">' + glo.symb.ppage.c + '</span>' }
  ih += ' &nbsp; <span>' + (np + 1) + ' / ' + helpPages.length + '</span>'
  if (np < (helpPages.length - 1)) { ih += ' &nbsp; <span id="b_pa_' + (np + 1) + '" style="font-size:160%">' + glo.symb.npage.c + '</span>' }
  ih += '</legend>'
  ih += helpPages[np].f()
  ih += '</fieldset>'
  e.innerHTML = ih
  e.style.display = 'block'
  e.style.visibility = 'visible'
  dspDiv('menu', false)
  dspDiv('popup', true, 60000)
  e.querySelectorAll('span').forEach(function (sp) {
    if (sp.id === 'b_cl') sp.onclick = function () { dspDiv('popup', false) }
    if (sp.id.startsWith('b_pa')) {
      const chunks = sp.id.split('_')
      sp.onclick = function () { _displayAide(parseInt(chunks[2])) }
    }
  })
}

export function dspDiv (en, yon, timeoutms) {
  const e = document.getElementById(en)
  if (!e) { console.error('dspDiv: no "' + en + '" element'); return }
  if (_tmout[en]) { clearTimeout(_tmout[en]); delete _tmout[en] }
  if (yon) {
    e.style.visibility = 'visible'
    e.style.display = 'block'
    if (timeoutms && timeoutms > 0) {
      _tmout[en] = setTimeout(function () { dspDiv(en, false) }, timeoutms)
    }
  } else {
    e.style.visibility = 'hidden'
    e.style.display = 'none'
  }
}

export function layToggle (cbv) {
  app.setLayer(cbv, !app.layerOn(cbv))
}

// export function setFactor(elName) {
// let el = document.getElementById(elName);
//    if( el ) {
//                                    console.info("setFactor:"+elName+" = "+el.value);
//        glo.factor = el.value;              // parsing ?
//    }
// }

function _setVar (elName, varName) {
  const el = document.getElementById(elName)
  if (el) {
    app.setv(varName, el.value)
    const casu = app.getv('asu')
    const casl = app.getv('asl')
    switch (varName) {
      case 'it' :
        try { app.send('ss', { it: parseInt(el.value) }, null) } catch (err) { console.error('SV BUS err: ' + err) }
        app.refreshTW()
        execConnguys('cb_conns')
        break
      case 'fut' :
        try { app.send('ss', { fut: parseInt(el.value) }, null) } catch (err) { console.error('SV BUS err: ' + err) }
        app.refreshTW()
        break
      case 'asu' :
        try { app.send('ss', { asu: parseInt(el.value) }, null) } catch (err) { console.error('SV BUS err: ' + err) }
        app.refreshALT()
        if (casl > parseInt(el.value)) { app.setv('asl', el.value); app.refreshALT() }
        break
      case 'asl' :
        try { app.send('ss', { asl: parseInt(el.value) }, null) } catch (err) { console.error('SV BUS err: ' + err) }
        app.refreshALT()
        if (casu < parseInt(el.value)) { app.setv('asu', el.value); app.refreshALT() }
        break
    }
  } else {
    console.error('SV no elem for ' + elName + ' => ' + varName)
  }
}

function _cbClicked (elName) {
  const el = document.getElementById(elName)
  if (el) {
    app.setOption(elName.substring(3), el.checked)
  }
}
// function _rangeChange(enI, enO) {
// let eI = document.getElementById(enI);
// let eO = document.getElementById(enO);
// }

export function dspLogin () {
  _loginIn('logon')
}
function _loginIn (elName) {
  let e = document.getElementById(elName)
  if (!e) { console.error('_loginIn no "' + elName + '" element'); return }
  if (e.style.visibility === 'visible') { dspDiv(elName, false) }
  let ih = '<fieldset><legend><span id="i_close"><span class="pc-cl">' + glo.symb.close.c + '</span> Authentification</span></legend><table><tr><td>Identit&eacute;</td><td>'
  ih += '<input type="text" id="i_login" autofocus placeholder="login ou email"/></td></tr>'
  //    ih += '<tr><td>Mot de passe</td><td><input type="password" id="i_pwd" size="6"/> <span id="i_send" title="Go !"><img src="/img/ok_16.png"/></span></td></tr>';
  ih += '<tr><td>Mot de passe</td><td><input type="password" id="i_pwd" size="6"/> <span id="i_send" style="font-size:large" title="Go !">&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  ih += '<tr><td colspan="2"><span id="i_lost"><u>&nbsp; ... &nbsp; oubli&eacute;</u></span></td></tr>'
  ih += '<tr><td colspan="2"><span id="i_new"><u>Demander un nouveau compte</u></span></td></tr>'
  ih += '</table></fieldset>'
  e.style.top = '20px'
  e.style.left = '60px'
  e.innerHTML = ih
  dspDiv(elName, true, 120000) // Logon
  dspDiv('menu', false)
  dspDiv('topmenu', false)
  e = document.getElementById('i_pwd')
  if (e) { e.onkeyup = function (ev) { _goAuth(ev) } }
  e = document.getElementById('i_login')
  if (e) { e.onkeyup = function (ev) { _goAuth(ev) } }
  e = document.getElementById('i_send')
  if (e) { e.onclick = function (ev) { _sendAuth() } }
  e = document.getElementById('i_close')
  if (e) { e.onclick = function (ev) { dspDiv(elName, false) } }
  e = document.getElementById('i_lost')
  if (e) { e.onclick = function (ev) { _displayGuyLostMsg() } }
  e = document.getElementById('i_new')
  if (e) { e.onclick = function (ev) { _displayGuyNewMsg() } }
}

function _displayGuyLostMsg () {
  let ih = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Aide r&eacute;cup&eacute;ration compte</span></legend><table>'
  ih += '<tr><td colspan="2"><pre>'
  ih += 'Envoie un SMS &agrave; <a href="sms://+33632883904" target="_blank">0632883904</a>\n'
  ih += 'avec login ou nom ou email\n'
  ih += 'pour r&eacute;cupérer le login/passwd</pre></td></tr>'
  ih += '<tr><td></td><td align="right"><span id="b_ok" title="OK">' + glo.symb.valid.c + '</span></td></tr>'
  ih += '</fieldset>'
  _displayGuyMsg(ih)
}

function _displayGuyNewMsg () {
  let ih = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Aide nouveau compte</span></legend><table>'
  ih += '<tr><td colspan="2"><pre>'
  ih += 'Envoie un SMS &agrave; <a href="sms://+33632883904" target="_blank">0632883904</a>\n'
  ih += 'avec nom, pr&eacute;nom, @email (surnom)\n'
  ih += 'pour demander un nouveau compte</pre></td></tr>'
  ih += '<tr><td></td><td align="right"><span id="b_ok" title="OK">' + glo.symb.valid.c + '</span></td></tr>'
  ih += '</fieldset>'
  _displayGuyMsg(ih)
}

function _displayGuyMsg (ih) {
  let e = document.getElementById('logon')
  if (!e) { return }
  e.innerHTML = ih
  dspDiv('logon', true)
  e = document.getElementById('b_cl')
  if (e) { e.onclick = function (ev) { dspDiv('logon', false) } }
  e = document.getElementById('b_ok')
  if (e) { e.onclick = function (ev) { dspDiv('logon', false) } }
}

function _locaReload () {
  location.reload(true)
}

function _sendAuth () {
  let e = document.getElementById('i_pwd')
  const pwd = e.value
  e = document.getElementById('i_login')
  const log = e.value.toLowerCase()
  const lost = null
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('au', { l: log, p: pwd, e: lost }, { sid: '' + sid, timeout: 5000 },
      function (err, resp) {
        if (err) console.error(JSON.stringify(err))
        else {
          if (resp.body.typ === 'logon') {
            glo.me = resp.body.obj
            app.retObj('g', glo.me.id)
            setDnD(true)
            // setTimeout('location.reload(true)', 1000)
            setTimeout(_locaReload, 1000)
          }
        }
      }
    )
  } catch (err) { console.error('SA BUS err: ' + err) }
  dspDiv('logon', false)
  dspDiv('topmenu', false)
  e = document.getElementById('btmenu')
  if (e) {
    e.onclick = function (e) { topMenu() }
  }
}

function _goAuth (ev) {
  if (ev.keyCode === 13) {
    _sendAuth()
  }
}

/*
function _unAuth (elName) {
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('au', { unauth: glo.me.id }, { sid: '' + sid, timeout: 5000 },
      function (err, resp) {
        if (err) { console.error(err) } else {
          glo.me = {}
          setDnD(false)
          location.reload(true)
        }
        dspDiv(elName, false)
        const e = document.getElementById('btmenu')
        if (e) {
          e.onclick = function (e) { topMenu() }
        }
      }
    )
  } catch (err) { console.error('SA BUS err: ' + err) }
}
*/

function _isolated () {
  try {
    app.send('gti', {}, function (err, resp) {
      if (err) { console.error('SendGetTofIsol' + JSON.stringify(err)) } else {
        drawIsolated(resp.body)
      }
    })
  } catch (ex) {
    console.error('GTI BUS err: ' + ex)
  }
}

function _fmtDate (dd) {
  return (dd.getDate() < 10 ? '0' : '') + dd.getDate() +
    '/' + (dd.getMonth() < 9 ? '0' : '') + (dd.getMonth() + 1) +
    ' ' + (dd.getHours() < 10 ? '0' : '') + dd.getHours() +
    ':' + (dd.getMinutes() < 10 ? '0' : '') + dd.getMinutes()
}

function _displayConns () {
  const e = document.getElementById('popup')
  if (!e) return
  const nbr = glo.connecteds.length
  let ih = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span> &nbsp; ' + nbr + ' connexions depuis ' + app.getv('it') + ' heures</legend><table>'
  for (const c of glo.connecteds) {
    // console.log("C:"+JSON.stringify(c));
    ih += '<tr><td><span title="#' + c.sid + '">'
    if (c.gs !== null && c.gs.length > 0) {
      ih += '<strong>' + c.gs + '</strong> ( ' + c.gf + ' ' + c.gl + ' )'
    } else {
      ih += '<strong>' + c.gf + ' ' + c.gl + '</strong>'
    }
    ih += '</span></td><td><strong>'
    const d = new Date(c.ts * 1000)
    ih += '<span title="' + _fmtDate(d) + '">' + timePast(Math.round(Date.now() - d))
    ih += '</span></strong></td><td>'
    if (c.a && c.g) ih += '<span id="mc_' + c.a + '_' + c.g + '" style="font-size:130%">' + glo.symb.navi.c + '</span>'
    ih += '</td></tr>'
  }
  ih += '</table></fieldset>'
  e.innerHTML = ih
  dspDiv('popup', true, 30000)
  e.querySelectorAll('span').forEach(function (sp) {
    if (sp.id === 'b_cl') sp.onclick = function () { dspDiv('popup', false) }
    if (sp.id.startsWith('mc_')) { // map center to poz
      const chunks = sp.id.split('_') // mc_lat_lgt
      sp.onclick = function () { app.setCenterAG(chunks[1], chunks[2]) }
    }
  })
}

function _inputPlainte (ack) {
  const e = document.getElementById('popup')
  if (e) {
    let to = 120000
    let ih = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span> &nbsp; Question / Plainte</legend>'
    if (ack && ack.length > 1) {
      ih += ack
      to = 10000
    } else {
      ih += '<textarea id="it_plainte" cols="40" rows="5" value="" placeholder="décrire son problème...."></textarea><br/>'
      ih += ' &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span id="b_plainte">Envoi &nbsp; ' + glo.symb.valid.c + '</span>'
    }
    ih += '</fieldset>'
    e.innerHTML = ih
    dspDiv('popup', true, to)
    e.querySelectorAll('span').forEach(function (sp) {
      if (sp.id === 'b_cl') sp.onclick = function () { dspDiv('popup', false) }
      if (sp.id === 'b_plainte') {
        sp.onclick = function () {
          const et = document.getElementById('it_plainte')
          if (et) _sendPlainte(et.value)
          dspDiv('popup', false)
        }
      }
    })
  }
}

function _sendPlainte (txt) {
  try {
    app.send('qp', { txt }, function (err, resp) {
      if (err) {
        console.error('Plainte' + JSON.stringify(err))
        _inputPlainte('erreur: <b>' + err.message + '</b>')
      } else {
        _inputPlainte('ok')
      }
    })
  } catch (ex) {
    console.error('QP BUS err: ' + ex)
  }
}

function _dumpSes () {
  try {
    const sid = app.getv('sid')
    app.send('sesdmp', { sid: '' + sid })
  } catch (ex) {
    console.error('dump Session err: ' + ex)
  }
}

function _nomiInput (ev) {
  if (ev.keyCode === 13) {
    const e = document.getElementById('nomi_txt')
    if (e && e.value.length > 2) {
      glo.ls = e.value
      nominatim('popup', e.value)
    }
  }
}

function _findInput (key, ev) {
//    if( ev.keyCode === 13 || ev.keyCode === '?' ) {
  if (key === 13 || key === '?') {
    const etx = document.getElementById('find_txt')
    if (etx && etx.value.length > 1) {
      let val = etx.value
      if (val.endsWith('?')) val = val.slice(-1)
      const ety = document.getElementById('find_typ')
      if (ety) { findObjectsByText(val, ety.value, 'find_res') }
    }
  }
}

export function topMenu () {
  const elName = 'topmenu'
  const elBtName = 'btmenu'
  const e = document.getElementById(elName)
  const eb = document.getElementById(elBtName)
  if (!e) { console.error('topMenu no "' + elName + '" element'); return }
  if (e.style.visibility === 'visible') {
    if (eb) { eb.innerHTML = glo.symb.deplig.c }
    dspDiv(elName, false)
    return
  }
  if (eb) { eb.innerHTML = glo.symb.plig.c }

  let ih = '<fieldset><table><tr><td valign="top">'
  if (glo.me.id) {
    const myprof = app.getObj('g', glo.me.id)
    ih += '<span id="b_ua" title="id:'
    ih += glo.me.lo ? glo.me.lo : glo.me.id
    ih += ' changer d\'utilisateur"><img src="/img/logout.png" width="24"/></span>'
    if (myprof) {
      let tag = myprof.sn
      const sid = app.getv('sid')
      if (!tag) { tag = myprof.fn }
      if (!tag) { tag = myprof.ln }
      if (!tag) { tag = '...' }
      ih += ' <span title="ses:' + sid + '" id="b_prof" style="font-size:smaller">' + tag.substring(0, 14) + '</span>'
    }
    ih += '<br/>'
  } else {
    ih += '<span id="b_au" title="s&apos;identifier"><img src="/img/login.png" width="28"/></span> <span style="font-size:smaller">Anonyme</span><br/>'
  }
  ih += '<label><input type="checkbox" id="cb_b" value="b"'; if (app.layerOn('b')) { ih += ' checked' }
  ih += '>Balises</label><br/>'

  ih += '<label><input type="checkbox" id="cb_s" value="s"'; if (app.layerOn('s')) { ih += ' checked' }
  ih += '>Sites info</label><br/>'

  ih += '<label><input type="checkbox" id="cb_sw" value="sw"'; if (app.layerOn('sw')) { ih += ' checked' }
  ih += '>Sites <strike>info</strike></label><br/>'

  ih += '<label><input type="checkbox" id="cb_t" value="t"'; if (app.layerOn('t')) { ih += ' checked' }
  ih += '>D&eacute;cos</label><br/>'

  ih += '<label><input type="checkbox" id="cb_l" value="l"'; if (app.layerOn('l')) { ih += ' checked' }
  ih += '>Atterros</label><br/>'

  ih += '<label><input type="checkbox" id="cb_w" value="w"'; if (app.layerOn('w')) { ih += ' checked' }
  ih += '>Sorties</label><br/>'

  ih += '<label><input type="checkbox" id="cb_0" value="0"'
  if (app.layerOn('0')) { ih += ' checked' }
  ih += '>Connexions</label>(<span id="cb_conns"></span>)<br/>'

  ih += '<label><input type="checkbox" id="cb_x" value="x"'; if (app.layerOn('x')) { ih += ' checked' }
  ih += '>Chemins</label><br/>'

  ih += '<label><input type="checkbox" id="cb_c" value="c"'; if (app.layerOn('c')) { ih += ' checked' }
  ih += '>Thermiques</label><br/>'

  ih += '<label><input type="checkbox" id="cb_o" value="o"'; if (app.layerOn('o')) { ih += ' checked' }
  ih += '>Zones a&eacute;ro</label><br/>'

  ih += '<label><input type="checkbox" id="cb_r" value="r"'; if (app.layerOn('r')) { ih += ' checked' }
  ih += '>Vols</label><br/>'

  ih += '<label><input type="checkbox" id="cb_k" value="k"'; if (app.layerOn('k')) { ih += ' checked' }
  ih += '>Pts d&apos;Int&ecirc;ret</label><br/>'

  //    ih += '<label><input type="checkbox" id="cb__s" value="_s"'; if( app.layerOn('_s') ) { ih += ' checked'; }
  //    ih += '>Sessions</label><br/>';

  ih += '<input type="checkbox" id="cb_a" value="a"'; if (app.layerOn('a')) { ih += ' checked' }
  ih += '><label for="cb_a">Annonces A/V</label><br/>'

  //    ih += '<label><input type="checkbox" id="cb_y" value="y"'; if( app.layerOn('y') ) { ih += ' checked'; }
  //    ih += '>Tchats</label><br/>';

  ih += '<br/><span id="cb_aov"><span style="font-size=140%;font-weight:bold;"> &nbsp;' + glo.symb.nonvu.c + '</span> tout non-vu</span>'
  ih += '<br/><span id="cb_reconn"><span style="font-weight:bold;font-size:large"> ' + glo.symb.resync.c + ' </span>Resync</span>'
  if (glo.me.id && glo.me.id === 1) {
    ih += '<br/><span id="cb_dump"><span style="font-weight:bold;font-size:large"> ' + glo.symb.dump.c + ' </span>Dump</span>'
  }
  // if (glo.me.id && (glo.me.id === 1 || glo.me.id === 9 || glo.me.id === 22 || glo.me.id === 277 || glo.me.id === 336)) {
  if (glo.me.id && (glo.me.id === 1)) {
    ih += '<br/><a href="/t.apk">Apk Suivi</a> &nbsp; <a href="opsuivi://setting?k=key&v=' + glo.me.id + '"> &lt;= clé</a>'
    // + (0xBEEFCAFE ^ glo.me.id).toString(16)
  }

  ih += '</td><td valign="top">'
  // Options
  const infotime = app.getv('it')
  const future = app.getv('fut')
  const bigzoom = app.getv('bz')
  const factor = parseFloat(app.getv('df'))
  const windtype = app.getv('wt')
  const decori = app.getv('dec')
  const asu = app.getv('asu')
  const asl = app.getv('asl')

  ih += '<table>'
  ih += '<tr><td><label for="i_it">Passé (heure)</label></td><td align="right">'
  ih += '<input class="opt" id="i_it" type="number" value="' + infotime + '" min="1" max="24" step="1" required/></tr>'

  ih += '<tr><td><label for="i_fut">Futur (jour)</label></td><td align="right">'
  ih += '<input class="opt" id="i_fut" type="number" value="' + future + '" min="0" max="7" step="1" required/></tr>'

  ih += '<tr><td><label for="i_bz">Zoom <span style="font-size:x-small">' + app.getMap().getView().getZoom().toFixed(1) + '</span> d&eacute;tail</label></td><td align="right">'
  ih += '<input class="opt" id="i_bz" type="number" value="' + bigzoom + '" min="12" max="17" step="1" required/></tr>'

  ih += '<tr><td><label for="i_df">Taille ic&ocirc;nes</label></td><td align="right">'
  ih += '<input class="opt" id="i_df" type="number" value="' + factor + '" min="0.5" max="2.0" step="0.25" required/></tr>'
  ih += '</table>'

  const mss = getMapSources()
  if (mss.length > 1) {
    const cms = app.getCurrentMapSourceId()
    ih += 'Carte <select id="sel_map_src">'
    for (const ms of mss) {
      if (ms.ena === true) {
        ih += '<option value="' + ms.code + '"'
        if (ms.code === cms) { ih += ' selected' }
        ih += '>' + ms.name + '</option>'
      }
    }
    ih += '</select><br/>'
  }

  ih += '<input id="cb_wt" type="checkbox" value="windtype" disabled'
  if (windtype) { ih += ' checked' }
  ih += '/>Balises convention.<br/>'

  ih += '<input id="cb_dec" type="checkbox" value="decori"'
  if (decori) { ih += ' checked' }
  ih += '/>Orientations sites<br/>'

  ih += '<label><input type="checkbox" id="cb__p" value="_p"'; if (app.getTrack() > 0) { ih += ' checked' }
  ih += '>Position</label>'

  if (app.getv('_pos')) {
    ih += '&nbsp;&nbsp;<span id="b_cenpos" style="font-size:large">' + glo.symb.navi.c + '</span>'
  }

  ih += '<label><input type="checkbox" id="cb__t" value="_t"'; if (app.layerOn('_t')) { ih += ' checked' }
  ih += '>Trajet</label><br/>'

  ih += '<label for="i_asu">Plafond &nbsp; </label>'
  ih += '<input             id="i_asu" type="number" value="' + asu + '" min="100" max="9000" step="100" size="5" maxlength="4" required/> m<br/>'

  ih += '<label for="i_asl">Plancher </label>'
  ih += '<input             id="i_asl" type="number" value="' + asl + '" min="0" max="9000" step="100" size="5" maxlength="4" required/> m<br/>'

  ih += '<label><input type="checkbox" id="cb__d" value="_d"'; if (app.layerOn('_d')) { ih += ' checked' }
  ih += '>Dessin</label><br/>'

  ih += '<input id="nomi_txt" type="text" size="14" maxlength="32" value="' + (glo.ls ? glo.ls : '') + '" placeholder="Cherche un lieu...">'
  ih += '<div id="nomi_res" style="visibility:hidden;display:none;"></div><br/>'

  ih += '<input id="find_txt" type="text" size="14" maxlength="32" value="" placeholder="Cherche un texte dans">'
  ih += '<br/><select id="find_typ">' // multiple
  const searchables = [
    { v: 's', r: false, l: 'Site' },
    { v: 't', r: false, l: 'D&eacute;co' },
    { v: 'l', r: false, l: 'Atterro' },
    { v: 'b', r: false, l: 'Balise' },
    { v: 'e', r: true, l: '&Eacute;v&egrave;nement' },
    { v: 'p', r: true, l: 'Post&apos;It' },
    { v: 'd', r: false, l: 'D&eacute;tail' },
    { v: 'k', r: true, l: 'P.I.' },
    { v: 'v', r: true, l: 'Vote' },
    { v: 'w', r: true, l: 'Sortie' },
    { v: 'i', r: true, l: 'Info' },
    { v: 'a', r: true, l: 'Annonce' },
    { v: 'g', r: true, l: 'Pilote' },
    { v: 'x', r: true, l: 'Chemin' },
    { v: 'o', r: true, l: 'Zones' },
    { v: 'r', r: true, l: 'Vol' }
  ]

  for (const ot of searchables) {
    ih += '<option'
    if (ot.r && !glo.me.id) { ih += ' disabled' }
    ih += ' value="' + ot.v + '">' + ot.l + '</option>'
  }
  ih += '</select> &nbsp; <span id="find_go"> ' + glo.symb.gofind.c + '</span><br/>'
  ih += '<div id="find_res" style="visibility:hidden;display:none;"></div>'
  if (glo.me && (glo.me.id === 1 || glo.me.id === 2)) {
    ih += '<br/><span id="cb_isol" style="font-size=60%">[vols sauvages]</span>'
  }
  ih += '<br/><br/><br/><span id="cb_help"><span style="font-size=140%;font-weight:bold;"> &nbsp;' + glo.symb.help.c + '</span> Aide</span>'
  ih += '<br/><span id="cb_plainte"><span style="font-size=140%;font-weight:bold;"> &nbsp;' + glo.symb.plainte.c + '</span> Question/Plainte</span>'

  ih += '</td></tr></table>'

  e.innerHTML = ih
  dspDiv(elName, true, 60000) // topMenu

  execConnguys('cb_conns')

  // set callbacks
  let el = document.getElementById('cb_isol')
  if (el) el.onclick = function (e) { _isolated() }
  el = document.getElementById('cb_aov')
  if (el) el.onclick = function (e) { allObjectViewed(false) }
  el = document.getElementById('cb_help')
  if (el) el.onclick = function (e) { _displayAide(0) }
  el = document.getElementById('cb_plainte')
  if (el) el.onclick = function (e) { _inputPlainte() }
  el = document.getElementById('cb_conns')
  if (el) el.onclick = function (e) { _displayConns() }
  el = document.getElementById('cb_reconn')
  if (el) el.onclick = function (e) { location.reload(true) }
  el = document.getElementById('cb_dump')
  if (el) el.onclick = function (e) { _dumpSes() }
  el = document.getElementById('nomi_txt')
  if (el) el.onkeyup = function (e) { _nomiInput(e) }
  el = document.getElementById('find_go')
  if (el) el.onclick = function (e) { _findInput(13, null) }

  el = document.getElementById('find_txt')
  if (el) {
    el.addEventListener('keyup', (ev) => {
      if (event.defaultPrevented) return // Should do nothing if the default action has been cancelled
      let handled = false
      let key = null
      if (typeof ev.key !== 'undefined') {
        handled = true // Handle the event with KeyboardEvent.key
        key = ev.key
      } else if (typeof ev.keyCode !== 'undefined') {
        handled = true // Handle the event with KeyboardEvent.keyCode
        key = key.keyCode
      }
      if (handled) ev.preventDefault()
      if (key !== null) _findInput(key, ev)
    }, true)
  }
  el = document.getElementById('sel_map_src')
  if (el) {
    el.onchange = function (ev) {
      app.setMapSource(ev.target.value)
    }
  }
  el = document.getElementById('b_au')
  if (el) {
    el.onclick = function () {
      _loginIn('logon')
    }
  }
  el = document.getElementById('b_prof')
  if (el) {
    el.onclick = function () {
      objectDisplay('g', glo.me.id)
    }
  }
  el = document.getElementById('b_ua')
  if (el) {
    el.onclick = function () {
      // _unAuth(elName)
      _loginIn('logon')
    }
  }

  el = document.getElementById('b_cenpos')
  if (el) {
    el.onclick = function () {
      const curpos = app.getv('_pos')
      if (curpos) { app.setCenterAG(curpos[1], curpos[0]) }
    }
  }
  // codé
  for (const cbv of ['b', 's', 'sw', 't', 'l', 'w', 'x', 'c', 'o', 'r', 'k', 'a', 'y', '_d', '_t', '0']) {
    el = document.getElementById('cb_' + cbv)
    if (el) {
      el.onclick = function () {
        layToggle(cbv)
      }
    }
  }
  el = document.getElementById('cb__p')
  if (el) {
    el.onclick = function () {
      const cb = document.getElementById('cb__p')
      app.setTrack(cb.checked ? 2 : 0)
    }
  }

  el = document.getElementById('i_it')
  if (el) {
    el.oninput = function () {
      _setVar('i_it', 'it')
    }
  }

  el = document.getElementById('i_fut')
  if (el) {
    el.oninput = function () {
      _setVar('i_fut', 'fut')
    }
  }

  el = document.getElementById('i_asu')
  if (el) {
    el.oninput = function () {
      _setVar('i_asu', 'asu')
      const asle = document.getElementById('i_asl')
      asle.value = app.getv('asl')
    }
  }

  el = document.getElementById('i_asl')
  if (el) {
    el.oninput = function () {
      _setVar('i_asl', 'asl')
      const asue = document.getElementById('i_asu')
      asue.value = app.getv('asu')
    }
  }

  el = document.getElementById('i_bz')
  if (el) {
    el.oninput = function () {
      _setVar('i_bz', 'bz')
    }
  }

  el = document.getElementById('i_df')
  if (el) {
    el.oninput = function () {
      _setVar('i_df', 'df')
    }
  }

  el = document.getElementById('cb_wt')
  if (el) {
    el.onclick = function () {
      _cbClicked('cb_wt')
    }
  }
  el = document.getElementById('cb_dec')
  if (el) {
    el.onclick = function () {
      _cbClicked('cb_dec')
    }
  }
}

export function featureOver (feature) {
  const ot = feature.get('ot')

  if (app.getObjs(ot)) {
    const oid = feature.getId()
    objectOver(ot, oid)
  } else {
    otherOver(ot, feature)
  }
}

// ot, oid | feature
export function featureClicked (a, b) {
// console.info('Click on A|' + typeof a + ' B|' + typeof b)
  let ot, oid
  if (b) {
    ot = a; oid = b
  } else {
    oid = a.getId()
    ot = a.get('ot')
  }

  if (app.getObjs(ot)) {
    objectDisplay(ot, oid)
  } else {
    otherClicked(ot, a)
  }
}

function otherOver (ot, feature) {
  const raw = feature.get('raw') || feature.getId()
  let ih = ''
  dspDiv('comment', false)
  switch (ot) {
    case 'pos' :
      ih = 'Position: ' + raw[1].toFixed(8) + ' ' + raw[0].toFixed(8) + ((app.getv('_alt') !== null) ? (' ' + app.getv('_alt') + 'm') : '')
      //            ih = 'Position: ' + raw[1].toFixed(8) + ' ' + raw[0].toFixed(8);
      break
    case 'ori' :
      ih = 'Origine ' + raw[1].toFixed(8) + ' ' + raw[0].toFixed(8)
      break
    case 'ptr' :
      ih = 'Rep&egrave;re ' + raw[1].toFixed(8) + ' ' + raw[0].toFixed(8)
      break
    case '_d' :
      ih = 'Dessin ' +
                feature.get('subty') +
                ' ' + feature.get('subna') +
                ' (#' + feature.getId() + ')'
      break
    case '_i' : { const od = new Date(feature.get('t') * 1000)
      ih = 'Vol '
      switch (feature.get('s')) {
        case 'f' : ih += 'cfd '; break
        case 's' : ih += 'syride '; break
      }
      ih += '(#' + feature.getId() + ') ' +
                feature.get('gn') +
                ' ' + fdf(od) +
                ' [' + timePast(feature.get('d') * 1000) + ']'
    } break
  }
  if (ih === '') { return }

  const e = document.getElementById('comment')
  e.innerHTML = ih
  dspDiv('comment', true, 20000)
}

function objectOver (ot, oid) {
  const obj = app.getObj(ot, oid)

  if (obj && obj.getOneLiner) {
    const e = document.getElementById('comment')
    e.innerHTML = obj.getOneLiner()
    e.onclick = function () { app.send('srvdmp', {}) } // TODO : testage
    dspDiv('comment', true, 20000)
  }
}

export function otherClicked (ot, feature) {
  const raw = feature.get('raw')
  let ih = ''
  dspDiv('popup', false)
  switch (ot) {
    case 'pos' :
      ih += '<fieldset><legend>Position</legend>' + raw[1] + ' ' + raw[0] + ((app.getv('_alt') !== null) ? (' ' + app.getv('_alt') + 'm') : '')
      ih += '</fieldset>'
      raw.lat = raw[1]
      raw.lgt = raw[0]
      raw.alt = app.getv('_alt')
      break
    case 'ori' :
      ih = '<fieldset><legend>Origine</legend>' + raw[1] + ' ' + raw[0] + '</fieldset>'
      break
    case 'ptr' :
      ih = '<fieldset><legend>Rep&egrave;re</legend>' + raw[1] + ' ' + raw[0] + '</fieldset>'
      break
    case '_d' :
      ih = '<fieldset><legend>Dessin</legend>' +
                'Id ' + feature.getId() + '<br/>' +
                'Type ' + feature.getGeometry().getType() + '<br/>' +
                'Layout ' + feature.getGeometry().getLayout() + '<br/>' +
                'Subtype ' + feature.get('subty') + '<br/>' +
                'Nom ' + feature.get('subna') + '<br/>'
      if (glo.me.id && (
        feature.get('subty') === 'gpx' ||
                feature.get('subty') === 'igc' ||
                feature.get('subty') === 'kml'
      )) {
        ih += '<span id="b_sndr">envoyer comme Vol</span><br/>'
        //                ih += '<span id="b_sndx">envoyer comme Chemin</span>';
      }
      ih += '</fieldset>'
      break
  }
  if (ih === '') { return }

  let e = document.getElementById('popup')
  e.innerHTML = ih
  e = document.getElementById('b_sndr')
  if (e) { e.onclick = function () { _sendFea(feature.getId(), 'r') } }
  e = document.getElementById('b_sndx')
  if (e) { e.onclick = function () { _sendFea(feature.getId(), 'x') } }
  dspDiv('popup', true, 20000) // otherClicked
}

export function displayBonjour (msg) {
  let e = document.getElementById('popup')
  if (e) {
    let bon = 'jour'
    const now = new Date()
    if (now.getHours() > 16) { bon = 'soir' }
    let ih = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Bon' + bon + '</span></legend>'
    if (typeof msg === 'object') {
      msg.forEach(function (m, idx) {
        ih += '<pre><span style="font-style:italic;font-size:150%;color:blue;" title="'
        ih += m.id + '">- ' + m.txt + '</span></pre>'
      })
    } else {
      ih += '<pre><span style="font-style:italic;font-size:150%;color:blue;">'
      ih += msg + '</span></pre>'
    }
    ih += '</fieldset>'
    e.innerHTML = ih
    dspDiv('popup', true, 30000)
    e = document.getElementById('b_cl')
    if (e) e.onclick = function () { dspDiv('popup', false) }
  }
}

export function objectDisplay (ot, oid) {
  const obj = app.getObj(ot, oid)

  if (obj.select) obj.select(true)

  if (obj && obj.getDisplay) {
    const e = document.getElementById('popup')
    e.innerHTML = obj.getDisplay()
    dspDiv('popup', true, 120000) // objectDisplay
    dspDiv('menu', false)
    e.querySelectorAll('div').forEach(function (dv) {
      if (dv.id.startsWith('exe_')) {
        const chunks = dv.id.split('_')
        switch (chunks[1]) {
          case 'friends': execFriends(dv.id)
        }
      }
    })
    e.querySelectorAll('span').forEach(function (sp) {
      if (sp.id === 'b_back') {
        if (_dobNext()) {
          const last = _dobPeek()
          sp.innerHTML = '<span id="b_back" title="go ' + last.ot + '#' + last.oi + '" style="font-weight:bold">' + glo.symb.back.c + ' &nbsp;</span>'
          sp.onclick = function () {
            _dobPop() // this one
            _dobPop() // the real precedent
            objectDisplay(last.ot, last.oi)
          }
        }
        _dobPush(ot, oid)
      }
      if (sp.id === 'b_sm') {
        sp.onclick = function () {
          const smc = document.getElementById('sm_cnx')
          const smt = document.getElementById('sm_txt')
          if (smc && smt) {
            _sendMsgDirect(smc.value, smt.value)
          }
          dspDiv('popup', false)
        }
      }
      if (sp.id === 'b_cl') { // close
        sp.onclick = function () { dspDiv('popup', false) }
      }
      if (sp.id.startsWith('go_')) { // go to object
        const chunks = sp.id.split('_') // go_ot_oId
        sp.onclick = function () { objectDisplay(chunks[1], chunks[2]) }
      }
      if (sp.id.startsWith('mc_')) { // map center to object
        const chunks = sp.id.split('_') // mc_ot_oId
        sp.onclick = function () { centerMapOnObj(chunks[1], chunks[2]) }
      }
      if (sp.id.startsWith('ed_')) { // edit
        const chunks = sp.id.split('_') // ed_ot_oId
        sp.onclick = function () { objectEdit(chunks[1], chunks[2]) }
      }
      if (sp.id.startsWith('de_')) { // delete
        const chunks = sp.id.split('_') // de_ot_oId
        sp.onclick = function () { _sendDelObject(chunks[1], chunks[2]) }
      }
      if (sp.id.startsWith('cn_')) { // create new
        const chunks = sp.id.split('_') // cn_ot_parId
        sp.onclick = function () { childCreate(chunks[1], chunks[2]) }
      }
      if (sp.id.startsWith('oc_')) { // open/close div
        const chunks = sp.id.split('_') /// oc_P_C_l
        sp.onclick = function () { _openCloseDiv(chunks[1], chunks[2], chunks[3]) }
      }
      if (sp.id.startsWith('sf_')) { // set friend
        const chunks = sp.id.split('_') /// sf_gId
        sp.onclick = function () { _sendSetfriend(parseInt(chunks[1])) }
      }
      if (sp.id.startsWith('uf_')) { // unset friend
        const chunks = sp.id.split('_') /// uf_gId
        sp.onclick = function () { _sendUnfriend(null, parseInt(chunks[1])) }
      }
      if (sp.id.startsWith('us_')) { // unSee object
        const chunks = sp.id.split('_') /// us_ot_oId
        sp.onclick = function () { objectViewed(chunks[1], parseInt(chunks[2]), false) }
      }
      if (sp.id.startsWith('oa_')) { // takeoff/site avail
        const chunks = sp.id.split('_') /// oa_[ts]_[yn]_oid
        sp.onclick = function () { _sendObjAvail(chunks[1], parseInt(chunks[3]), chunks[2]) }
      }
    })
  }
}

function centerMapOnObj (ot, oid) {
  const obj = app.getObj(ot, oid)
  if (obj) {
    const lat = obj.a; const lgt = obj.g
    if (lat && lgt) {
      app.setCenterAG(lat, lgt)
      const bz = app.getv('bz')
      const zl = app.getv('zl')
      if (zl < bz && (ot === 't' || ot === 'l')) {
        app.setLayer(ot, true)
        if (ot === 't') { app.setLayer('tw', true) }
        app.setZoom(bz)
      }
    }
  }
}

// droite ouvrir 25E3
// droite fermer 25E5
// gauche ouvrir 25E2
// gauche fermer 25E4

function _openCloseDiv (p, c, gauche) {
  let sbid = 'oc_' + p + '_' + c
  if (gauche) { sbid += '_' + gauche }
  const b = document.getElementById(sbid)
  if (!b) { return }
  const d = document.getElementById('d_' + p + '_' + c)
  if (!d) { return }
  if (d.style.visibility === 'visible') {
    d.style.visibility = 'hidden'
    d.style.display = 'none'
    b.innerHTML = (gauche) ? glo.symb.deplig.c : glo.symb.deplid.c
  } else {
    d.style.visibility = 'visible'
    d.style.display = 'block'
    b.innerHTML = (gauche) ? glo.symb.plig.c : glo.symb.plid.c
  }
}

export function objectEdit (ot, oid) {
  const obj = app.getObj(ot, oid)

  if (!glo.me.id) { dspLogin() }

  if (obj && obj.getEdit) {
    const e = document.getElementById('popup')
    e.innerHTML = obj.getEdit()
    dspDiv('popup', true, 120000) // objectEdit
    e.querySelectorAll('span').forEach(function (sp) {
      if (sp.id === 'b_cl') {
        sp.onclick = function () { dspDiv('popup', false) }
      }
      if (sp.id === 'b_ok') {
        sp.onclick = function () { modifObject(ot) }
      }
      if (sp.id.startsWith('go_')) {
        const chunks = sp.id.split('_')
        sp.onclick = function () { objectDisplay(chunks[1], chunks[2]) }
      }
      if (sp.id.startsWith('ed_')) {
        const chunks = sp.id.split('_')
        sp.onclick = function () { objectEdit(chunks[1], chunks[2]) }
      }
      if (sp.id.startsWith('de_')) {
        const chunks = sp.id.split('_')
        sp.onclick = function () { _sendDelObject(chunks[1], chunks[2]) }
      }
      if (sp.id.startsWith('cn_')) {
        const chunks = sp.id.split('_')
        sp.onclick = function () { childCreate(chunks[1], chunks[2]) }
      }
      if (sp.id.startsWith('b_rp_')) {
        const chunks = sp.id.split('_')
        sp.onclick = function () { _resetPasswd(chunks[2]); dspDiv('popup', false) }
      }
    })
  }
}

const creators = {
  d: detNew,
  e: evtNew,
  p: pmgNew,
  i: infNew,
  v: votNew,
  w: wshNew
}
// const dpicker = null
export function childCreate (ot, parid) {
  if (!glo.me.id) { dspLogin() }
  if (creators[ot]) {
    const e = document.getElementById('popup')
    e.innerHTML = creators[ot](parid)
    dspDiv('popup', true, 120000) // childCreate
    e.querySelectorAll('span').forEach(function (sp) {
      if (sp.id === 'b_ok') {
        sp.onclick = function () { inputObject(ot) }
      }
      if (sp.id === 'b_cl') {
        sp.onclick = function () { dspDiv('popup', false) }
      }
      if (sp.id.startsWith('go_')) {
        const chunks = sp.id.split('_')
        sp.onclick = function () { objectDisplay(chunks[1], chunks[2]) }
      }
      if (sp.id.startsWith('ed_')) {
        const chunks = sp.id.split('_')
        sp.onclick = function () { objectEdit(chunks[1], chunks[2]) }
      }
      if (sp.id.startsWith('cn_')) {
        const chunks = sp.id.split('_')
        sp.onclick = function () { childCreate(chunks[1], chunks[2]) }
      }
    })
  }
}

function _resetPasswd (guyid) {
  _sendResetPw(guyid)
}

// from popup and xxxEdit
export function modifObject (ot) {
  const frm = document.getElementById('frm_' + ot)
  const els = frm.elements
  const obj = {}
  for (let i = 0; i < els.length; i++) {
    const el = els[i]
    const id = el.id
    const chunks = id.split('_')
    const key = chunks[1]
    const na = el.name
    //    let nn = el.nodeName;
    const va = el.value
    // console.debug('ModObj: Id='+id+' Na='+na+' Nn='+nn+' Va='+va);
    if (id.startsWith('ir_')) { // radio
      obj[key] = frm[na].value
    } else if (id.startsWith('ih_')) { // hidden
      obj[key] = va
    } else if (id.startsWith('is_')) { // select
      obj[key] = va
    } else if (id.startsWith('it_')) { // text
      obj[key] = va
    } else if (id.startsWith('in_')) { // number
      obj[key] = va
    } else if (id.startsWith('iD_')) { // date (new)
      const d = new Date(va)
      if (key === 'ao') { // pmg
        const a = new Date(d)
        const o = new Date(d)
        a.setHours(0, 0, 1)
        obj.a = a
        o.setHours(23, 59, 59)
        obj.o = o
      } else if (key === 'sa') { // evt, pmg
        d.setHours(0, 0, 1)
        obj[key] = d
      } else if (key === 'so') { // evt, pmg, ann
        d.setHours(23, 59, 59)
        obj[key] = d
      } else { // wsh, vot
        d.setHours(12, 0, 0)
        obj[key] = d
      }
    } else if (id.startsWith('ic_')) { // checkbox
      if (el.checked) {
        obj[key] = true
      } else {
        obj[key] = false
      }
    }
  }
  _sendUpdObject(ot, obj)
  dspDiv('popup', false)
}

// from popup and xxxNew
export function inputObject (ot) {
  const frm = document.getElementById('frm_' + ot)
  const els = frm.elements
  const obj = {}
  for (let i = 0; i < els.length; i++) {
    const el = els[i]
    const id = el.id
    const chunks = id.split('_')
    const key = chunks[1]
    const na = el.name
    //    let nn = el.nodeName;
    const va = el.value
    if (id.startsWith('ir_')) { // radio
      obj[key] = frm[na].value
    } else if (id.startsWith('ih_')) { // hidden
      obj[key] = va
    } else if (id.startsWith('is_')) { // select
      obj[key] = va
    } else if (id.startsWith('it_')) { // text
      obj[key] = va
    } else if (id.startsWith('in_')) { // number
      obj[key] = va
    } else if (id.startsWith('iD_')) { // date (new)
      const d = new Date(va)
      if (key === 'ao') { // pmg
        const a = new Date(d)
        const o = new Date(d)
        a.setHours(0, 0, 1)
        obj.a = a
        o.setHours(23, 59, 59)
        obj.o = o
      } else if (key === 'sa') { // evt,pmg
        d.setHours(0, 0, 1)
        obj[key] = d
      } else if (key === 'so') { // evt,pmg, ann
        d.setHours(23, 59, 59)
        obj[key] = d
      } else { // wsh, vot
        d.setHours(12, 0, 0)
        obj[key] = d
      }
    } else if (id.startsWith('ic_')) { // checkbox
      if (el.checked) {
        obj[key] = true
      } else {
        obj[key] = false
      }
    }
  }
  _sendNewObject(ot, obj)
  app.setLayer(ot, true)
  dspDiv('popup', false)
}

function dropDrawFeature (fid) {
  const fea = app.getSrcs('_d').getFeatureById(fid)
  if (fea) app.getSrcs('_d').removeFeature(fea)
}

function execConnguys (divid) {
  try {
    app.send('cg', {}, function (err, resp) {
      if (err) { console.error('SendConnGuys()' + JSON.stringify(err)); return }
      glo.connecteds = []
      for (const o of resp.body) {
        // console.log('CG:' + JSON.stringify(o))
        glo.connecteds.push(o)
      }
      const el = document.getElementById(divid)
      if (el) el.innerHTML = '<span style="text-decoration:underline">' + resp.body.length + '</span>'
    })
  } catch (ex) {
    console.error('SCG BUS err: ' + ex)
  }
}

function execFriends (divid) {
  try {
    app.send('gf', {}, function (err, resp) {
      if (err) { console.error('SendGetFriend()' + JSON.stringify(err)); return }
      const el = document.getElementById(divid)
      if (el) {
        let ih = '<div style="max-height:50%;max-width:95%"><table><tr><th colspan="4" align="center">Amis ' + glo.symb.frds.c + '</th></tr>'
        glo.me.frds = []
        for (const o of resp.body) {
          glo.me.frds.push(o.fi)
          ih += '<tr><td><span id="uf_' + o.fi + '">' + glo.symb.unfrd.c + '</span></td>'
          ih += '<td>' + ((o.gs && o.gs.length > 0) ? o.gs : '') + '</td>'
          ih += '<td>' + o.gf + '</td>'
          ih += '<td>' + o.gl + '</td>'
          ih += '</tr>'
        }
        ih += '</table></div>'
        el.innerHTML = ih
        dspDiv(divid, true)
        el.querySelectorAll('span').forEach(function (sp) {
          if (sp.id.startsWith('uf_')) {
            const chunks = sp.id.split('_')
            sp.onclick = function () { _sendUnfriend(divid, parseInt(chunks[1])) }
          }
        })
        app.getSrcs('0').changed()
      }
    })
  } catch (ex) {
    console.error('SGF BUS err: ' + ex)
  }
}

function _sendSetfriend (gid) {
  try {
    app.send('sf', { f: [gid] }, function (err, resp) {
      if (err) { console.error('SendSetfriend()' + JSON.stringify(err)) } else { setFriend(true, gid) }
    })
  } catch (ex) {
    console.error('SSF BUS err: ' + ex)
  }
}

function _sendUnfriend (dvid, fid) {
  try {
    app.send('sf', { u: [fid] }, function (err, resp) {
      if (err) { console.error('SendUnfriend()' + JSON.stringify(err)) } else {
        setFriend(false, fid)
        if (dvid !== null) execFriends(dvid)
      }
    })
  } catch (ex) {
    console.error('SUF BUS err: ' + ex)
  }
}

function _sendDelObject (ot, oid) {
  const msg = {
    ot,
    id: parseInt(oid)
  }
  try {
    app.send('do', msg, function (err, resp) {
      if (err) { console.error('SendDelObject()' + JSON.stringify(err)) }
    })
  } catch (ex) {
    console.error('SDO BUS err: ' + ex)
  }
  dspDiv('popup', false)
}

function _sendObjAvail (ot, oid, yon) {
  const msg = {
    ot,
    oi: parseInt(oid),
    y: yon === 'y'
  }
  try {
    app.send('oa', msg, function (err, resp) {
      if (err) { console.error('SendObjAvail()' + JSON.stringify(err)) } else if (ot === 't') { app.getObj('t', oid).av = (yon === 'y') }
    })
  } catch (ex) {
    console.error('SOA BUS err: ' + ex)
  }
  dspDiv('popup', false)
}

function _sendUpdObject (ot, obj) {
  const msg = {
    ot,
    obj
  }
  try {
    app.send('uo', msg, function (err, resp) {
      if (err) { console.error('SendUpdObject()' + JSON.stringify(err)) }
    })
  } catch (ex) {
    console.error('SUO BUS err: ' + ex)
  }
}

function _sendResetPw (oid) {
  const msg = {
    gid: parseInt(oid)
  }
  try {
    app.send('rp', msg, function (err, resp) {
      if (err) { console.error('SendRstPw()' + JSON.stringify(err)) }
    })
  } catch (ex) {
    console.error('SRP BUS err: ' + ex)
  }
}

function _sendNewObject (ot, obj) {
  const msg = {
    ot,
    obj
  }
  try {
    app.send('no', msg, function (err, resp) {
      if (err) { console.error('SendNewObject()' + JSON.stringify(err)) }
    })
  } catch (ex) {
    console.error('SNO BUS err: ' + ex)
  }
}

// {'c': , 't': , 'g': , 'a': } code text lgt lat
function sendSos () {
  const e = document.getElementById('h_txt')
  if (!e) return
  const obj = { t: e.value };
  ['p', 'r', 'o'].forEach(function (q) {
    const e = document.getElementById('ih_s_' + q)
    if (e && e.checked === true) {
      let pe = document.getElementById('ih_s_' + q + '_a')
      if (pe) obj.a = pe.value
      pe = document.getElementById('ih_s_' + q + '_g')
      if (pe) obj.g = pe.value
    }
  })
  const soscodes = hmgGetCodes()
  for (const q in soscodes) {
    const e = document.getElementById('ih_c_' + q)
    if (e && e.checked === true) obj.c = q
  }
  // app.send('no', { ot: 'h', obj }, function (err, resp) { })
  app.send('no', { ot: 'h', obj })
}

function detectSosStart (ev) {
  let cs = false;
  ['o', 'p', 'r'].forEach(function (q) {
    const e = document.getElementById('ih_s_' + q)
    if (e && e.checked === true) cs = true
  })
  const soscodes = hmgGetCodes()
  let cc = false
  for (const q in soscodes) {
    const e = document.getElementById('ih_c_' + q)
    if (e && e.checked === true) cc = true
  }
  if (cs === true && cc === true) dspDiv('sosConfirm', true, 15000)
}

export function chooseSos (ori, pos, rep) {
  dspDiv('topmenu', false)
  dspDiv('menu', false)
  dspDiv('popup', false)
  const e = document.getElementById('Hdiv')
  if (!e) return
  let ih = '<form id="frm_h"><fieldset><legend><span id="unSos" class="pc-cl">' + glo.symb.close.c + '&nbsp;Demande d&apos;Aide</span></legend><table>'
  if (ori) {
    ih += '<input type="hidden" id="ih_s_o_a" value="' + ori[0].toFixed(6) + '"/>'
    ih += '<input type="hidden" id="ih_s_o_g" value="' + ori[1].toFixed(6) + '"/>'
    ih += '<tr><td><input type="radio" name="h_src" id="ih_s_o" value="o"/><label for="ih_s_o"><span style="font-weight:bold;">Origine: ' + ori[0].toFixed(4) + ' ' + ori[1].toFixed(4) + '</span></label></td></tr>'
  }
  if (pos) {
    ih += '<input type="hidden" id="ih_s_p_a" value="' + pos[1].toFixed(6) + '"/>'
    ih += '<input type="hidden" id="ih_s_p_g" value="' + pos[0].toFixed(6) + '"/>'
    ih += '<tr><td><input type="radio" name="h_src" id="ih_s_p" value="p"/><label for="ih_s_p"><span style="font-weight:bold;">Position: ' + pos[1].toFixed(4) + ' ' + pos[0].toFixed(4) + '</span></label></td></tr>'
  }
  if (rep) {
    ih += '<input type="hidden" id="ih_s_r_a" value="' + rep[1].toFixed(6) + '"/>'
    ih += '<input type="hidden" id="ih_s_r_g" value="' + rep[0].toFixed(6) + '"/>'
    ih += '<tr><td><input type="radio" name="h_src" id="ih_s_r" value="r"/><label for="ih_s_r"><span style="font-weight:bold;">Rep&egrave;re: ' + rep[1].toFixed(4) + ' ' + rep[0].toFixed(4) + '</span></label></td></tr>'
  }
  ih += '<tr><td><hr/></td></tr>'
  const soscodes = hmgGetCodes()
  for (const code in soscodes) {
    ih += '<tr><td><input type="radio" name="h_code" id="ih_c_' + code + '" value="' + code + '"/>'
    ih += '<label for="ih_c_' + code + '"><span style="font-weight:bold;">' + soscodes[code] + '</span></label></td></tr>'
  }
  ih += '<tr><td><textarea id="h_txt" rows="4" cols="30" placeholder="détails / explications" maxlength=256"></textarea></td></tr>'
  ih += '<tr><td align="center"><div id="sosConfirm" style="visibility:hidden;display:none;"><span id="gosos" style="align:center;vertical-align:baseline;"><img src="/img/sos_64.png" width="48"/></span></div></td></tr>'
  ih += '</table></fieldset></form>'
  e.style.zIndex = 5555
  e.style.position = 'absolute'
  e.style.top = '2%'
  e.style.left = '1.5%'
  e.style.color = '#FFF'
  e.style.backgroundColor = 'red'
  e.innerHTML = ih
  dspDiv('Hdiv', true, 120000)
  let be = document.getElementById('unSos')
  be.onclick = function () { dspDiv('Hdiv', false) }
  be = document.getElementById('gosos')
  be.onclick = function () {
    sendSos()
    dspDiv('Hdiv', false)
  }
  for (const code in soscodes) {
    be = document.getElementById('ih_c_' + code)
    if (be) be.onchange = function (ev) { detectSosStart(ev) }
  }
  be = document.getElementById('ih_s_o')
  if (be) be.onchange = function (ev) { detectSosStart(ev) }
  be = document.getElementById('ih_s_p')
  if (be) be.onchange = function (ev) { detectSosStart(ev) }
  be = document.getElementById('ih_s_r')
  if (be) be.onchange = function (ev) { detectSosStart(ev) }
}

export function display3D (onoff, clat, clgt) {
  const topel = document.getElementById('Zdiv')
  if (!topel) { return }
  if (onoff && typeof Cesium === 'undefined') { return }
  if (!onoff) {
    app.set3d(false)
    glo.m3t = false
    dspDiv('Zdiv', false)
    return
  }

  if (!document.getElementById('m3div')) {
    let ih = ''
    ih += '<div id="m3opt" style="display:block;visibility:visible;width:100%;height:3%;top:0px;left:0px;z-index:140">'
    ih += '<span id="m3close" style="font-size:large">' + glo.symb.close.c + '</span>'
    ih += '&nbsp; <span id="m3alt" style="font-size:large"></span>'
    ih += '&nbsp; <span id="m3dst" style="font-size:large"></span>'
    ih += '&nbsp; <span id="m3hea" style="font-size:large"></span>'
    ih += '&nbsp; <span id="m3tlt" style="font-size:large"></span>'
    ih += '</div>'
    ih += '<div id="m3div" style="display:block;visibility:visible;width:100%;height:100%;top:0px;left:0px:z-index:130"></div>'
    topel.innerHTML = ih
  }

  dspDiv('Zdiv', true)
  const e = document.getElementById('m3close'); e.onclick = function () { display3D(false) }
  app.set3d(true, 'm3div', clat, clgt)
}

export function displayWindy (lat, lgt) {
  const e = document.getElementById('Wdiv')
  const wih = parseInt(window.innerHeight * 0.96)
  const wiw = parseInt(window.innerWidth * 0.96)

  if (!e) return
  let ih = '<fieldset><legend><span id="unWindy" class="pc-cl">' + glo.symb.close.c + '&nbsp;Windy'
  ih += ' &agrave; ' + lat + ' ' + lgt + '</span></legend>'
  ih += '<iframe width="' + wiw + '" height="' + wih + '" src="https://embed.windy.com/embed2.html?lat='
  ih += lat + '&lon=' + lgt + '&detailLat=' + lat + '&detailLon=' + lgt
  ih += '&width=' + wiw + '&height=' + wih + '&zoom=11'
  ih += '&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=true'
  ih += '&calendar=12&pressure=true&type=map&location=coordinates&detail='
  ih += '&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1" frameborder="0"></iframe>'
  ih += '</fieldset>'
  e.style.zIndex = 4444
  // el.style.align='center';
  // el.style.valign='top';
  e.style.position = 'absolute'
  e.style.top = '2%'
  e.style.left = '2%'
  e.style.color = '#000'
  e.style.backgroundColor = 'white'
  e.style.height = wih
  e.style.width = wiw
  //    e.style.maxHeight = '82%';
  //    e.style.maxWidth = '90%';
  e.innerHTML = ih
  dspDiv('Wdiv', true, 120000)

  const elem = document.getElementById('unWindy')
  elem.onclick = function () { dspDiv('Wdiv', false) }
}

export function displayMsgDirect (mb) {
  let e = document.getElementById('msgdir')
  if (!e) { console.error('MD NO msgdir'); return }
  let na = mb.frm.sn
  const titna = mb.frm.fn + ' ' + mb.frm.ln
  if (!na || na === '') { na = titna }
  let ih = '<fieldset><legend><span id="md_cl">' + glo.symb.close.c + '&nbsp;Message de ' + na + '</legend><table>'
  ih += '<tr><td><textarea style="font-size:larger;font_weight:bolder" cols="30" rows="2" wrap="hard" disabled>' + mb.txt + '</textarea><input id="md_cnx" type="hidden" value="' + mb.sid + '"/></td></tr>'
  ih += '<tr><td><textarea style="font-size:larger;font_weight:bolder" id="md_txt" cols="30" rows="2" maxlength="64" wrap="hard" placeholder="r&eacute;pondre ..."></textarea></td></tr>'
  ih += '<tr><td align="right">Envoi&nbsp;<span id="md_sm">' + glo.symb.valid.c + '</span></td></tr></table></fieldset>'
  e.innerHTML = ih
  dspDiv('msgdir', true, 300000) // displayMsgDirect
  e = document.getElementById('md_cl')
  if (e) { e.onclick = function () { dspDiv('msgdir', false) } }
  e = document.getElementById('md_sm')
  if (e) {
    e.onclick = function () {
      const smc = document.getElementById('md_cnx')
      const smt = document.getElementById('md_txt')
      if (smc && smt) {
        _sendMsgDirect(smc.value, smt.value)
      }
      dspDiv('msgdir', false)
    }
  }
}

export function updRecBtn (_trking) {
  if (!_trking || _trking.l < 3) {
    dspDiv('record', false)
  } else {
    dspDiv('record', true)
    const el = document.getElementById('b_rec')
    if (el) { el.onclick = function () { app.setTrack(2) } }
  }
}

export function displayError (te) {
  let e = document.getElementById('errpop')
  if (!e) { console.error('DM NO errpop'); return }
  let ih = '<fieldset><legend><span id="b_cle"><span class="pc-cl">' + glo.symb.close.c + '</span></span>&nbsp;<span style="font-style:italic;font-weight:bold;font-size:150%;color:red">Erreur</span></legend>'
  ih += '<span style="font-style:italic;font-weight:bold;font-size:120%;color:red"><pre>'
  ih += te + '</pre></span></fieldset>'
  e.innerHTML = ih
  dspDiv('errpop', true, 180000) // displayError
  e = document.getElementById('b_cle')
  if (e) { e.onclick = function () { dspDiv('errpop', false) } }
  e = document.getElementById('cb__p')
  if (e) { e.checked = false }
}

function _sendMsgDirect (cnx, txt) {
  const msg = { cnx: parseInt(cnx), txt, frm: glo.me }
  try {
    app.send('md', msg, function (err, resp) {
      if (err) { console.error('SendMessageDirect()' + JSON.stringify(err)) }
    })
  } catch (ex) {
    console.error('SMD BUS err: ' + ex)
  }
}

function _sendFea (fid, ot) {
  const fea = app.getSrcs('_d').getFeatureById(fid)
  if (!fea) { return }

  // Run: line => multi
  if (ot === 'r' && fea.getGeometry().getType() === 'LineString') {
    const mls = new MultiLineString([fea.getGeometry()])
    fea.setGeometry(mls)
  }
  // Path: multi => line[0]
  if (ot === 'x' && fea.getGeometry().getType() === 'MultiLineString') {
    const ls = new LineString(fea.getGeometry().getLineString(0), 'XY')
    fea.setGeometry(ls)
    // transform2D
  }

  const usrgeom = fea.getGeometry().clone()
  usrgeom.transform(app.getMap().getView().getProjection(), 'EPSG:4326')
  const msg = {
    ot,
    obj: {
      n: fea.get('subty') + ' ' + fea.get('subna'),
      j: geojformat.writeGeometry(usrgeom)
    }
  }

  try {
    app.send('no', msg, function (err, resp) {
      if (err) { console.error('SendFeature()' + JSON.stringify(err)) } else { dropDrawFeature(fid) }
    })
  } catch (ex) {
    console.error('SF BUS err: ' + ex + '\n' + JSON.stringify(app))
  }
}
